export default {
  env: "sandbox",
  iframe: "https://wallet-sdk-v3-sandbox.mycheckapp.com",
  recaptcha_v2_key: "6LdRBVQpAAAAAKJPtkMfbFVbImswlwS6OQ2uRQML",
  recaptcha_v3_key_enterprise: "6LditokpAAAAAIGU7NEyO_bQeZoYkzJ_tgRtd3Z-",
  data_dome_key: "7160F1660D94CEA29EF2EBFDCB7E04",
  data_dome_tags_js: "https://js.datadome.co/tags.js",
  assets: "https://wallet-sdk-v3-sandbox.mycheckapp.com",
  sps_domain: "https://cdn.shijipayment.dev/sts/sts.proxy.v2.1.js",
  sps_alternatives_domain: "https://cdn.shijipayment.com/libraries/shijipayments.latest.min.js",
  api_domain: "https://the-sandbox.mycheckapp.com",
  masterpass: "https://sandbox.src.mastercard.com/srci/integration/merchant.js",
  google_pay: "https://pay.google.com/gp/p/js/pay.js",
  google_analytics: "https://www.google-analytics.com/analytics.js",
  paypal: "https://www.paypalobjects.com/api/checkout.js",
  paypal_client: "https://js.braintreegateway.com/web/3.44.2/js/client.min.js",
  paypal_checkout: "https://js.braintreegateway.com/web/3.44.2/js/paypal-checkout.min.js",
  paypal_collector: "https://js.braintreegateway.com/web/3.44.2/js/data-collector.min.js",
  firebaseSettings: {
    apiKey: "AIzaSyBlnOsJKdAAQRd4F3ezpcQg-Jszug-ohcw",
    authDomain: "microservices-sandbox-233112.firebaseapp.com",
    databaseURL: "https://microservices-sandbox-233112.firebaseio.com",
    projectId: "microservices-sandbox-233112",
    storageBucket: "microservices-sandbox-233112.appspot.com",
    messagingSenderId: "968169069971",
    appId: "1:968169069971:web:48142e92a62bdf0b"
  },
  sentry: {
    dsn: "https://73a21eb1f02adf02cb99892fc7add451@o210774.ingest.us.sentry.io/4508177283350528",
    env: "sandbox"
  }
};
