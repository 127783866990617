import * as Sentry from "@sentry/react";
import { Configuration } from "./configs/Configuration";
import { Integration } from '@sentry/types'

Sentry.init({
  release: __RELEASE__VERSION__,
  dsn: Configuration.sentry.dsn,
  environment: Configuration.sentry.env,
  integrations: [],

  // Turn off by default and set off based on business config
  enabled: false,

  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions

  // Session Replay
  replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

export const addIntegrationIfNotExist = (integrationName: 'Replay' | 'ReplayCanvas' | 'BrowserTracing' | 'Debug', integration: () => Integration) => {
  const client = Sentry.getClient();
  const integrationExist = client?.getIntegrationByName(integrationName);

  if(!integrationExist) {
    client?.addIntegration(integration());
  }
}
