import { Configuration } from "../configs/Configuration";
import { create_UUID } from "../helpers/CreateUUID";
import { MerchantsList, getMerchantConfig } from "../helpers/helpers";
import { createTimestampValues, parseTimestamp6 } from "../helpers/parseTimestamp6";
import { store } from "../store";
import { setReadySpsApm, setSPSTransactionComplete, setSpsApmError } from "../store/payment/PaymentActions";
import { ApiClient, ApiUrls } from "./ApiClient";
import { firebaseService } from "./FirebaseService";
import { MyCheckWalletService } from "./MyCheckWalletService/WalletManager";

type TransactionObjectType = {
  status: string,
  transaction_id: string,
}
type AccessTokenPayload = {
  amount: string | number,
  currency: string,
  fingerprint: string,
  language: string,
  alternative_payment_id: string,
  merchant_id: string | number,
  business_id: string | number,
  orderId?: string,
}

enum ErrorCode {
  CHECKOUT_OBJECT_ALREADY_EXISTS = "00001",
  ERROR_HOST = "00002",
  UNKNOWN_HOST = "00003",
  MISSING_CONF = "00004",
  MISSING_ACCESS_TOKEN = "00005",
  ADYEN_MODULE_INIT = "01000",
  ADYEN_MODULE_PROCESSING = "01001",
}

let spsID: string;
let timestamp: string = "";
let refAlternativeListener: any;
let savedAccessToken: string = "";

const addSPSLogInformation = (information: any): void => {
  if (savedAccessToken) {
    const defaultDatabase = firebaseService.getDatabase();
    defaultDatabase.ref(`alternative-payments/${spsID}/logs`).push(information);
  }
}

const readFirstTimestamp = (): Promise<string | null> => new Promise(async resolve => {
  const defaultDatabase = firebaseService.getDatabase();
  const refTimestamp = defaultDatabase.ref(`alternative-payments/${spsID}/timestamp`);

  refTimestamp.once("value", function (snapshot: any) {
    const timestampValues = snapshot.val();
    if (timestampValues) {
      resolve(timestampValues.created_at);
    } else {
      resolve(null);
    }
  })
})

const recordTimestamp = async () => {
  const defaultDatabase = firebaseService.getDatabase();
  timestamp = parseTimestamp6();

  const createdTimestamp = await readFirstTimestamp();
  const timestampSpsApm = createTimestampValues(createdTimestamp, timestamp);

  if (savedAccessToken) {
    defaultDatabase.ref(`alternative-payments/${spsID}/timestamp`).set(timestampSpsApm);
  }
}

const genereteUniqueID = () => {
  const uniqueNumber = create_UUID();
  return spsID = `SPS_APM-${uniqueNumber}`;
}

export const generateAccessTokenSPS = async () => {
  const sps_apm = true;
  const merchantConfigurations = store.getState().configuration.walletConfiguration.merchantConfigurations;
  const customConfig = store.getState().configuration.customWalletConfiguration;
  const businessId = store.getState().configuration.businessDetails?.id;
  const fingerprint = await window.mycheck.wallet.get3DSecureSignature(sps_apm);
  const receivedMerchantId = getMerchantConfig(MerchantsList.SPS_ECOMMERCE, merchantConfigurations)?.id;

  savedAccessToken = "";

  const data: AccessTokenPayload = {
    amount: MyCheckWalletService.getPaymentInfo().amount,
    currency: MyCheckWalletService.getPaymentInfo().currency,
    fingerprint: JSON.stringify(fingerprint),
    language: customConfig.locale,
    alternative_payment_id: genereteUniqueID(),
    merchant_id: customConfig.merchant_id || receivedMerchantId,
    business_id: customConfig.businessId || businessId,
  }

  if (!!MyCheckWalletService.getPaymentInfo().orderId) {
    data.orderId = MyCheckWalletService.getPaymentInfo().orderId
  }

  await ApiClient(ApiUrls.getAPMAccessToken, {
    method: "POST",
    domain: Configuration.api_domain,
    body: data,
  });
}

export const getAccessTokenFromFB = () => new Promise(async resolve => {
  const defaultDatabase = firebaseService.getDatabase();
  const walletSdkSessionId: string = MyCheckWalletService.getWalletId();

  if (refAlternativeListener) {
    savedAccessToken = "";
    refAlternativeListener.off();
  }

  refAlternativeListener= defaultDatabase.ref(`alternative-payments/${spsID}`);

  refAlternativeListener.once("value", function(snapshot: any) {
    const dataValue = snapshot.val();
    if (!dataValue) {
      getAccessTokenFromFB();
    }
    if (dataValue) {
      const dataObject = Object.values(dataValue)[0] as any;
      savedAccessToken = dataObject.access_token;

      recordTimestamp();
      addSPSLogInformation(`start listening to FB with walletSdkSessionId: ${walletSdkSessionId} at: ${timestamp}`);

      recordTimestamp();
      addSPSLogInformation(`got access_token from FB with walletSdkSessionId: ${walletSdkSessionId} at: ${timestamp}`);

      initSPSAlternatives(savedAccessToken);

      recordTimestamp();
      addSPSLogInformation(`init SPS alternatives with walletSdkSessionId: ${walletSdkSessionId} at: ${timestamp}`);
    }
    resolve(null);
  });
})

export const completeTransactionSPS = () => new Promise(async resolve => {
  const defaultDatabase = firebaseService.getDatabase();
  const walletSdkSessionId: string = MyCheckWalletService.getWalletId();
  const refTransactionListener= defaultDatabase.ref(`alternative-payments/${spsID}/transaction`);

  refTransactionListener.on("value", function(snapshot: any) {
    const dataValue = snapshot.val();
    if (dataValue) {
      const transactionObject = Object.values(dataValue)[0] as TransactionObjectType;
      MyCheckWalletService.events.paymentCompleted(transactionObject);
    }
    resolve(null);
  });
  recordTimestamp();
  addSPSLogInformation(`fired event of paymentCompleted with walletSdkSessionId: ${walletSdkSessionId} at: ${timestamp}`);
})

const initSPSAlternatives = (token: string) => {
  const walletSdkSessionId: string = MyCheckWalletService.getWalletId();

  const onLoadingCallback = (res: any) => {
    console.log("show your loading overlay");
    console.log('onLoadingCallback :>> ', res);
  } 

  const onReadyCallback = (res: any) => {
    console.log("ready for processing");
    console.log('onReadyCallback :>> ', res);
    store.dispatch(setReadySpsApm(true));

    recordTimestamp();
    addSPSLogInformation(`ready for processing with walletSdkSessionId: ${walletSdkSessionId} at: ${timestamp}`);
  } 

  const onResizeCallback = (res: any) => {
    console.log('onResizeCallback :>> ', res);
  } 

  const onResultCallback = (res: any) => {
    console.log("payment done. Redirected to wallet");
    console.log('onResultCallback :>> ', res);
    store.dispatch(setSPSTransactionComplete(true));

    recordTimestamp();
    addSPSLogInformation(`payment done with walletSdkSessionId: ${walletSdkSessionId}. Redirected to wallet at: ${timestamp}`);
  } 

  const onSomethingWentWrongCallback = (code: ErrorCode, errorMessage: string) => {
    if (Object.values(ErrorCode).includes(code)) {
      const errorCodeKey = Object.keys(ErrorCode).find(key => ErrorCode[key as keyof typeof ErrorCode] === code);
      MyCheckWalletService.events.error({code, errorCodeKey, errorMessage});
    }
    console.log("something went horibly wrong cannot proceed");
    store.dispatch(setSpsApmError(true));

    recordTimestamp();
    addSPSLogInformation(`something went horibly wrong cannot proceed, walletSdkSessionId: ${walletSdkSessionId} at: ${timestamp}`);
  } 

  const onPaymentSelectedCallback = (res: any) => {
    console.log("payment process started");
    console.log('onPaymentSelectedCallback :>> ', res);

    recordTimestamp();
    addSPSLogInformation(`payment process started with walletSdkSessionId: ${walletSdkSessionId} at: ${timestamp}`);
  }

  const config = {
    environment: "test",
    accessToken: token,
    paymentMethodsExclude: [],
    layout: {
      "columns": 1,
      "rows": 0,
      "ccBlockPosition": "top",
    },
    language: "en",
    onResult: onResultCallback,
    onError: onSomethingWentWrongCallback,
    onResize: onResizeCallback,
    onReady: onReadyCallback,
    onLoaded: onLoadingCallback,
    onLoading: onLoadingCallback,
    onPaymentSelected: onPaymentSelectedCallback
  }

  //@ts-ignore
  const paymentContainer = new Shiji.Checkout(config);
  if (paymentContainer) {
    paymentContainer.mount('#apmRoot');
  }
}

export const injectScriptSPS = () => {
  const script = document.createElement("script");
  script.src = Configuration.sps_alternatives_domain;
  script.charset = "UTF-8";
  document.head.appendChild(script);
}