/** @jsx jsx */

import * as React from 'react'
import { useSelector } from 'react-redux'
import dayjs from 'dayjs'
import { jsx } from '@emotion/core'
import classNames from 'classnames'

import { MyText } from '../../components/MyText/MyText'
import { Modal } from '../../components/Modal/Modal'
import { Divider } from '../../components/Divider/Divider'
import { MyButton } from '../../components/MyButton/MyButton'

import { useIsRTL } from '../../hooks/useIsRTL'
import { useTranslation } from '../../hooks/useTranslation'

import { Tracking } from '../../services/AnalyticsService/GoogleAnalyticsService'

import styles from './GiftCardsBenefitsModal.module.scss'
import { checkGiftCardSection } from './GiftCardsSection'

type GiftCardsBenefitsModalType = {}

export const GiftCardsBenefitsModal: React.FunctionComponent<GiftCardsBenefitsModalType> = () => {
  const isRTL = useIsRTL()
  const [isOpen, setOpen] = React.useState(false)
  const benefits = useSelector((state: GlobalState) => state.giftCard.benefits)
  const bids = useSelector((state: GlobalState) => state.giftCard.bids)
  const giftCardSection = useSelector((state: GlobalState) => state.configuration.walletConfiguration.sections.giftcards);

  const noGiftCardsLabel = useTranslation('BALANCE_NO_CARDS');
  const balanceDetailsButton = useTranslation('BALANCE_DETAILS_BUTTON');
  const balanceDetailsTitle = useTranslation('BALANCE_DETAILS_TITLE');
  const balanceExpiered = useTranslation('BALANCE_EXPIRED');
  const balanceProperty = useTranslation('BALANCE_PROPERTY');
  const balanceAmount = useTranslation('BALANCE_AMOUNT');
  const balance = useTranslation('BALANCE_BALANCE');

  const checkBackground = () => {
    if (checkGiftCardSection(giftCardSection)) {
      return giftCardSection.balance_modal.background.color;
    }
    return "";
  };

  const checkHeaderBackground = () => {
    if (checkGiftCardSection(giftCardSection)) {
      return giftCardSection.balance_modal.header.background.color;
    }
    return "";
  };

  const onClickHandler = React.useCallback(() => {
    Tracking.trackEvent(Tracking.EventAction.GiftCardDetails, 'benefits details')
    setOpen(!isOpen)
  }, [isOpen])

  const columnContainerStyles = classNames(styles.columnContainer, { [styles.columnContainerRtl]: isRTL })

  return (
    <div data-test-id="gift-cards-benefits-modal">
      <MyButton
        onClick={onClickHandler} type="unstyled" label={balanceDetailsButton}
        id="balance-modal"
      />
      {isOpen &&
        <Modal isOpen={isOpen} isAdaptive={false} onRequestClose={onClickHandler}
          contentLabel={balanceDetailsTitle}
        >
          <div css={{ backgroundColor: checkHeaderBackground() }} className={styles.header}>
            <MyText type="one">
              {balanceDetailsTitle}
            </MyText>
          </div>
          <div css={{ backgroundColor: checkBackground() }} className={styles.content}>
            <div className={columnContainerStyles}>
              <MyText type="two" className={styles.column}>{balanceProperty}</MyText>
              <MyText type="two" className={styles.column}>{balanceAmount}</MyText>
              <MyText type="two" className={styles.column}>{balance}</MyText>
              <MyText type="two" className={styles.column}>{balanceExpiered}</MyText>
            </div>
            <Divider label="" />
            {benefits.length > 0 ? benefits.map((benefit: Benefit) => (
              <React.Fragment key={benefit.business_id}>
                <div className={columnContainerStyles}>
                  <MyText type="three" className={styles.column}>
                    {bids[benefit.business_id] || ''}
                  </MyText>
                  <MyText type="three" className={styles.column}>
                    {benefit.price}
                  </MyText>
                  <MyText type="three" className={styles.column}>
                    {benefit.balance}
                  </MyText>
                  <MyText type="three" className={styles.column}>
                    {dayjs(benefit.expiration_date).format('MM/YYYY')}
                  </MyText>
                </div>
                <Divider label="" />
              </React.Fragment>
            )) : (
              <div className={styles.noCards}>
                <MyText type="four">{noGiftCardsLabel}</MyText>
              </div>
            )}
          </div>
        </Modal>
      }
    </div>
  )
}
