import { getRecaptchaConfigs } from "./getRecaptchaConfigs"


export const resetRecaptchaV2 = () => {
  const recaptchaConfig = getRecaptchaConfigs();
  if (window.wallet?.grecaptchaV2 &&
    recaptchaConfig.isRecaptchaEnabled && 
    recaptchaConfig.isRecaptchaV2 && 
    !recaptchaConfig.isEnterprise && 
    !recaptchaConfig.isRecaptchaV3) 
  {
    window.wallet.grecaptchaV2.reset();
  }
}