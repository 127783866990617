import React from "react";
import { IconButton } from "@material-ui/core";
import ArrowDownwardIcon from "@material-ui/icons/KeyboardArrowRight";

import styles from "./DemoClose.module.scss";
import { DemoRootId } from "../index";

type DemoCloseType = {
}

export const DemoClose: React.FunctionComponent<DemoCloseType> = () => {
  const onClick = () => {
    const root = document.getElementById(DemoRootId);
    root!.classList.contains("hidden") ? root!.classList.remove("hidden") : root!.classList.add("hidden");
  };

  return (
    <IconButton aria-label="close" size="medium" className={styles.root} color="primary" onClick={onClick}>
      <ArrowDownwardIcon fontSize="inherit" />
    </IconButton>
  );
};
