import * as React from "react";

import { alternativeMethodsName } from "./AlternativeMethodsName";
import { ApplePayService } from "../../services/ApplePayService/ApplePayService";
import { GooglePayService } from "../../services/GooglePayService";
import { BraintreeService } from "../../services/BraintreeService/BraintreeService";

import styles from "./AlternativeWalletWrapper.module.scss";
import classNames from "classnames";
import { MasterpassService } from "../../services/MasterpassService";
type AlternativeWalletsDisplayType = {
  alternatives: string[]
}

export const AlternativeWalletsDisplay: React.FunctionComponent<AlternativeWalletsDisplayType> = ({ alternatives }) => {

  // TODO: RETURN Alternative PM
  //  Tracking.trackEvent(Tracking.EventAction.AlternativePMClick, "Google Pay");

  const renderWallet = (element: keyof typeof alternativeMethodsName, i: number) => {
    switch (element.toLowerCase()) {
      case "applepay":
        return <ApplePayService key={i} />

      case "googlepay":
        return <GooglePayService key={i} />;

      case "braintree":
        return <BraintreeService key={i} />;

      case "masterpass":
        return <MasterpassService key={i} />;

      default:
        return null;
    }
  };

  const rootStyle = classNames(styles.wrapper);
  return (
    <div className={rootStyle}>
      {alternatives?.map((element: any, key: number) => renderWallet(element, key))}
    </div>
  );
};
