import { Configuration } from "../configs/Configuration";
import firebase from "firebase/app";
import "firebase/database";

type FirebaseConfiguration = {
  apiKey: string,
  authDomain: string,
  databaseURL: string,
  projectId: string,
  storageBucket: string,
  messagingSenderId: string,
  appId: string,
}

class FirebaseService {
  private isFirebaseAvailable: boolean = true;
  private firebaseConfiguration: FirebaseConfiguration;
  private firebase: any = {};
  private database: any = {};

  constructor() {
    this.firebaseConfiguration = {
      apiKey: Configuration.firebaseSettings.apiKey,
      authDomain: Configuration.firebaseSettings.authDomain,
      databaseURL: Configuration.firebaseSettings.databaseURL,
      projectId: Configuration.firebaseSettings.projectId,
      storageBucket: Configuration.firebaseSettings.storageBucket,
      messagingSenderId: Configuration.firebaseSettings.messagingSenderId,
      appId: Configuration.firebaseSettings.appId
    }
    this.attachFirebase();
  }

  private attachFirebase = async() => {
    if (this.isFirebaseAvailable) {
      this.firebase = firebase;
      this.database = firebase.database;
    }
  }

  public initFirebase = async() => {
    this.firebase.initializeApp(this.firebaseConfiguration);
  }

  public getFirebaseAvailable = () => {
    return this.isFirebaseAvailable;
  }

  public getFirebase = () => {
    return this.firebase;
  }

  public getDatabase = () => {
    return this.database();
  }
}

const instanceFirebaseService = new FirebaseService();
export const firebaseService = instanceFirebaseService;