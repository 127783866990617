export default [
  require("./ac_amazonpay.png"),
  require("./ac_amex.png"),
  require("./ac_amex.png"),
  require("./ac_applepay.png"),
  require("./ac_cirrus.png"),
  require("./ac_diners.png"),
  require("./ac_diners.png"),
  require("./ac_discover.png"),
  require("./ac_elo.png"),
  require("./ac_jcb.png"),
  require("./ac_maestro.png"),
  require("./ac_mastercard.png"),
  require("./ac_paypal.png"),
  require("./ac_solo.png"),
  require("./ac_switch.png"),
  require("./ac_unionpay.png"),
  require("./ac_unknown.png"),
  require("./ac_visa.png"),
  require("./ac_tr.png"),
];
