import * as React from 'react'
import { Configuration } from '../../configs/Configuration'
import { useTranslation } from '../../hooks/useTranslation'
import styles from './AuthFail.module.css'

export const AuthFail = () => {
  const authErrorText = useTranslation('AUTH_ERROR')
  return (
    <div className={styles.container} data-test-id="auth-error-container">
      <img src={`${Configuration.assets}/static/media/error.png`} alt="error" className={styles.image} />
      <span className={styles.label}>{authErrorText}</span>
    </div>
  )
}
