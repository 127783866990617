import React from "react";

import { AppLoader } from "./containers/AppLoader";
import { InstantPaymentModal } from "./containers/InstantPayments/InstantPaymentModal";
import { Iframe3DSecurityModal } from "./services/3DSService/Iframe3DSecurityModal";
import { Checkout } from "./containers/Checkout/Checkout";
import { AppLogger } from "./components/AppLogger";
import { MyCheckWalletAuth } from "./services/MyCheckWalletService/WalletManager";
import { MyCheckAuthConnect } from "./services/MyCheckWalletService/WalletServiceTypes";

type AppType = {}

const App: React.FunctionComponent<AppType> = () => {
  const initialParams = MyCheckWalletAuth.getInitialParams() as MyCheckAuthConnect;

  return (
    <AppLoader
      refreshToken={initialParams?.refreshToken}
      accessToken={initialParams?.accessToken}
      publishableKey={initialParams?.publishableKey!}
    >
      <AppLogger>
        <div className="mywallet-app" data-test-id="mywallet-app">
          <Checkout />
        </div>
        <InstantPaymentModal />
        <Iframe3DSecurityModal />
      </AppLogger>
    </AppLoader>
  );
};

export default App;
