/** @jsx jsx */

import * as React from "react";
import { jsx } from "@emotion/core";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";

import { calculatePaddingHorizontal } from "../../helpers/CalculateBorderRadiusPadding";
import {
  BootstrapBtn,
  composeInputStyles,
  composeTextStyle, FLOATING_INPUT_SIZE_MULTIPLIER,
  INPUT_SIZE_MULTIPLIER,
} from "../../helpers/CreateStyleFromConfig";
import { Configuration } from "../../configs/Configuration";
import { acceptedCardsName, cardTypes } from "../../containers/AcceptedCards/AcceptedCardNames";
import { RemoveCreditCard } from "../../containers/ManageCreditCards/RemoveCreditCard";

import { useIsRTL } from "../../hooks/useIsRTL";
import { useTranslation } from "../../hooks/useTranslation";

import styles from "./MyDisplayCardInput.module.scss";
import {PlaceholderType} from "../MyInput/constants";
import { deletePayment } from "../../store/payment/PaymentActions";

type MyInputType = {
  id: string,
  value: string,
  issuer: keyof typeof acceptedCardsName,
  isSingleUse?: number;
  isRemoveAvailable?: boolean,
  className?: string,
  source?: string,
}

export const MyDisplayCardInput = ({ id, value, issuer, className, isSingleUse = 0, isRemoveAvailable = false, source }: MyInputType) => {
  const isRTL = useIsRTL();
  const dispatch = useDispatch();
  const [removeButtons, setRemoveButtons] = React.useState(false);
  const { general } = useSelector((state: GlobalState) => state.configuration.walletConfiguration);

  const cards = useSelector((state: GlobalState) => state.payment.cards);
  const isGuest = useSelector((state: GlobalState) => state.account.isGuest);

  React.useEffect(() => {
    if(isGuest && cards[1]) {
      setRemoveButtons(false);
      dispatch(deletePayment(cards[1].id))
    }
  }, [cards]);

  const textStyles = composeTextStyle(general, "three");
  const temporaryCardStyles = composeTextStyle(general, "six");
  const { borderColor, borderWidth, borderRadius, backgroundColor, height, borderStyle } = composeInputStyles(general);
  const temporaryCardText = useTranslation("CHECKOUT_TEMPORARY_CARD");
  const areYouSureLabel = useTranslation("ARE_YOU_SURE");
  const placeholderType = general.input.placeholder_animation;
  const isPlaceholderFloating = placeholderType === PlaceholderType.floating;
  const inputHeight = isPlaceholderFloating
    ? parseInt(general.text.size.medium, 10) * FLOATING_INPUT_SIZE_MULTIPLIER
    : parseInt(general.text.size.medium, 10) * INPUT_SIZE_MULTIPLIER;

  const fontFamily = textStyles.fontFamily;
  const styledBorder = {
    borderColor,
    borderRadius,
    borderWidth,
    borderStyle,
  };

  const padding = `0 ${calculatePaddingHorizontal({ height, borderRadius, borderWidth }).paddingHorizontal}`;

  const rootStyle = classNames(styles.container, styles.root, className && { [className]: true }, { [styles.containerRtl]: isRTL });
  const containerStyle = classNames(styles.container, { [styles.containerRtl]: isRTL });
  const inputStyle = classNames(styles.input, {
    [styles.inputRtl]: isRTL,
    [styles.labelRtl]: isRTL,
    [styles.label]: !isRTL,
    [styles.isRemoveAvailable]: isRemoveAvailable
  });

  let endingText = useTranslation("ENDING_IN");
  if (issuer === 'paypal') {
    endingText = '';
  }

  if (source === 'APPLE_PAY') {
    endingText = 'Apple Pay';
  }

  const cardImageSrc = () => {
    if(source === 'APPLE_PAY') {
      return `${Configuration.assets}/static/media/${acceptedCardsName.applepay}`
    }
    if(issuer === 'visa') {
      return `${Configuration.assets}/static/media/${cardTypes.visa}`
    }
    // @ts-ignore
    if(issuer === 'discover card') {
      return `${Configuration.assets}/static/media/${acceptedCardsName.discover}`
    }
    return `${Configuration.assets}/static/media/${acceptedCardsName[issuer]}`
  }

  return (
    <div
      data-test-id={`${issuer}-card-input`}
      className={rootStyle} css={{ ...styledBorder, backgroundColor, padding, height: inputHeight }}
    >
      <div className={containerStyle}>
        <img
          key={id}
          alt={value}
          className={styles.img}
          src={cardImageSrc()}
        />
        <div className={inputStyle} css={{ height, ...textStyles as any, fontFamily }}>
          {removeButtons ? areYouSureLabel : `${endingText} ${value || ''}`}
        </div>
      </div>
      {/* {(isSingleUse) && (source !== 'APPLE_PAY') && (
        <div css={{ ...temporaryCardStyles as any, fontFamily, color: BootstrapBtn.error.color }}>
          {temporaryCardText}
        </div>
      )} */}
      {isRemoveAvailable && (
        <RemoveCreditCard id={id} removeButtons={removeButtons} setRemoveButtons={setRemoveButtons} />
      )}
    </div>
  );
};
