import Platform from "platform";
import * as Sentry from "@sentry/react";

import { Configuration } from "../configs/Configuration";
import { store } from "../store";
import { refreshUser } from "../store/account/AccountActions";
import { MyCheckWalletService } from "./MyCheckWalletService/WalletManager";

type ApiConfig = {
  domain: string,
  method?: "GET" | "POST" | "PATCH" | "DELETE",
  body?: object,
  noHeaders?: boolean,
  accessToken?: string
}

const BasicHeaders = {
  "Mc-Device": Platform.description,
  "Mc-Wallet-Version": `${__RELEASE__VERSION__}-web`
} as any;

export const fetchAPIInstance = {
  token: null,
  retry: false,
  fetchAPI: (url: string, config: ApiConfig) => fetchAPI(url, config),
}

const fetchAPI = async (url: string, config: ApiConfig) => {
  const token = fetchAPIInstance.token || config.accessToken || store.getState().account.accessToken;
  const sessionId = window.mycheck.auth.getSessionId();
  const credentialsType: RequestCredentials = url === ApiUrls.addCreditCardPCI ? 'include' : 'same-origin';

  if(!token && url.includes('/payment-methods/api/v1/payment-methods')) {
    Sentry.captureMessage('ApiClient error', { 
      level: 'error', 
      extra: {
        url,
        configNoHeaders: config.noHeaders || 'empty',
        fetchAPIInstanceToken: fetchAPIInstance.token || 'empty',
        configAccessToken: config.accessToken || 'empty',
        storeAccountAccessToken: store.getState().account.accessToken || 'empty'
      },
    });
  }

  return await fetch(`${config.domain}${url}`, {
    credentials: credentialsType,
    method: config.method,
    ...config.noHeaders ? {} : {
      headers: {
        ...BasicHeaders,
        Accept: "application/json",
        "Content-Type": "application/json",
        ...token ? { Authorization: token } : {},
        ...sessionId ? { "Session-Id": sessionId } : {}
      }
    },
    body: JSON.stringify(config.body)
  });
};

export const ApiClient = async (url: string, config?: ApiConfig, isRetry: boolean = false): Promise<any> => {
  try {
    if (fetchAPIInstance.retry) {
      return new Promise((res, rej) => setTimeout(async () => res(await ApiClient(url, config, true)), 1000));
    }

    const response = await fetchAPIInstance.fetchAPI(url, config || { domain: Configuration.api_domain, method: "GET" });
    if (response.status.toString().startsWith("5")) {
      if (!isRetry) {
        return await ApiClient(url, config, true);
      }

      MyCheckWalletService.events.error('Response status 5xx', response);
      throw response;
    }

    if (url.includes(".json")) {
      return await response.text().catch((err) =>
        Sentry.captureException(`Response text error: ${err}`)
      );
    }

    if (response.status === 204) {
      return {};
    }

    const responseBody = await response.json().catch((err) => {
      throw new Error(JSON.stringify(responseBody), { cause: `Throw from await response json err: ${err}` });
    });

    if (responseBody.code == 121) {
      const publishableKey = store.getState().configuration.publishableKey;
      const refreshToken = store.getState().account.refreshToken;
      store.dispatch(refreshUser(refreshToken!, publishableKey));

      fetchAPIInstance.retry = true;
      MyCheckWalletService.events.error('Refresh token error', responseBody);
      return ApiClient(url, config, true);
    }

    if (responseBody.code == 2085) {
      throw responseBody;
    }

    if (!response.ok || responseBody.status === "ERROR" || responseBody.errorId) {
      MyCheckWalletService.events.error('Response error', responseBody);
      throw responseBody;
    }

    return responseBody;
  } catch (err) {
      Sentry.captureException(err);
      throw err;
  }
};

export const ApiUrls = {
  fetchMerchantConfiguration: (publishableKey: string, merchantId?: string) => `/wallet/api/v1/merchants/configurations?publishable_key=${publishableKey}${merchantId ? `&merchant_id=${merchantId}` : ""}`,
  fetchSPSMerchantConfiguration: (publishableKey: string, configId: string) => `/wallet/api/v1/merchants/${configId}/configurations?publishable_key=${publishableKey}`,
  fetchThemedConfiguration: (publishableKey: string) => `/users/api/v2/business/configurations?public_only=1&keys=wallet_v3,captcha_wallet_v3_enabled,sentry_enabled,captcha_provider,data_dome_captcha_enabled&publishableKey=${publishableKey}`,
  fetchConfiguration: (publishableKey: string) => `/users/api/v2/business/configurations?public_only=1&keys=walletV3&publishableKey=${publishableKey}`,
  masterpassConfiguration: (token: string) => `/wallet/api/v1/external-payment/masterpass/configuration?accessToken=${token}`,
  getPayPalToken: (accessToken: string) => `/wallet/api/v1/external-payment/vzero/token?accessToken=${accessToken}`,
  getApplePaySession: (url: string) => `/wallet/api/v1/external-payment/applepay/token?validation_url=${url}`,
  getPayments: (withGiftCards: boolean, provider: string) => `/payment-methods/api/v1/payment-methods${withGiftCards ? "?isWithGiftCards=1" : "?isWithGiftCards=0"}&isDeleted=false&isExpired=false&source=SPS_ECOMMERCE`,
  getGiftCards: "/giftcards/api/v1/payment_method",
  addCreditCardPCI: "/api/v1/payment_method/creditcard",
  addCreditCard: "/payment-methods/api/v1/payment-methods",
  deletePayment: (paymentMethodId: string) => `/payment-methods/api/v1/payment-methods/${paymentMethodId}?retrieveAllPaymentMethods=false`,
  setDefaultPayment: (defaultPaymentId: string) => `/payment-methods/api/v1/payment-methods/${defaultPaymentId}`,
  refreshUser: "/users/api/v1/login",
  cancel3DS: (transactionId: string) => `/wallet/api/v1/3ds-transactions/${transactionId}/cancel`,
  getAPMAccessToken: "/wallet/api/v1/alternative-payments/access-tokens",
  get3dsSignature: () => `/wallet/api/v1/3ds-transactions/signature`,
  log: () => '/messaging/api/v1/requests/log'
};
