export default {
  "american-express": require("./ct_amex.png"),
  amex: require("./ct_amex.png"),
  cirrus: require("./ct_cirrus.png"),
  "diners-club": require("./ct_diners.png"),
  diners: require("./ct_diners.png"),
  discover: require("./ct_discover.png"),
  jcb: require("./ct_jcb.png"),
  maestro: require("./ct_maestro.png"),
  mastercard: require("./ct_mastercard.png"),
  solo: require("./ct_solo.png"),
  switch: require("./ct_switch.png"),
  unionpay: require("./ct_unionpay.png"),
  unknown: require("./ct_unknown.png"),
  visa: require("./ct_visa.png"),
  tr: require("./ct_tr.png"),
};
