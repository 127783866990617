export const REFRESH_USER = "REFRESH_USER";
export const SET_USER_TOKENS = "SET_USER_TOKENS";
export const SET_AUTH_STATUS = "SET_AUTH_STATUS";
export const SET_ACCESS_TOKEN = "SET_ACCESS_TOKEN";

type RefreshUserAction = { type: typeof REFRESH_USER };
type SetUserTokens = {
  type: typeof SET_USER_TOKENS;
  accessToken: string;
  refreshToken: string;
  isGuest: boolean;
};
type SetAuthStatus = {
  type: typeof SET_AUTH_STATUS;
  status: boolean;
};
type SetAccessTokenType = {
  type: typeof SET_ACCESS_TOKEN;
  accessToken: string;
};

export type AccountActionTypes =
  | RefreshUserAction
  | SetUserTokens
  | SetAuthStatus
  | SetAccessTokenType
