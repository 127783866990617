import {
  GET_GIFTCARDS,
  GiftCardActionTypes,
  ON_APPLY_BUCKS,
  SET_AMOUNT_TO_APPLY,
  DROP_APPLY_BUCKS,
} from "./GiftCardTypes";

const initialState: GiftCardState = {
  amountToApply: 0,
  isApplyBucks: false,
  balance: 0,
  benefits: [],
  bids: {},
};

export function giftCardReducer(
  state = initialState,
  action: GiftCardActionTypes
): GiftCardState {
  switch (action.type) {
    case GET_GIFTCARDS:
      return {
        ...state,
        balance: action.balance,
        benefits: action.benefits,
        bids: action.bids,
      };

    case ON_APPLY_BUCKS:
      return {
        ...state,
        amountToApply: action.value,
        isApplyBucks: !state.isApplyBucks,
      };

    case SET_AMOUNT_TO_APPLY:
      return {
        ...state,
        amountToApply: action.value,
      };

    case DROP_APPLY_BUCKS:
      return {
        ...state,
        isApplyBucks: false
      }

    default:
      return state;
  }
}
