import { Dispatch } from "redux";

import {
  APP_IS_READY,
  ConfigurationActionTypes,
  FETCH_CONFIGURATION,
  FETCH_TRANSLATION,
  SET_BUSINESS_DETAILS,
  SET_CREDIT_CARDS,
  SET_CUSTOM_CONFIGURATION,
  SET_PAYMENT_DETAILS,
  SET_IFRAME_SIZE,
  SET_MERCHANT_CONFIGURATION,
  SET_PUBLISHABLE_KEY,
  SET_REMOVED_ALTERNATIVE_METHOD,
} from "./ConfigurationTypes";
import { ApiClient, ApiUrls } from "../../services/ApiClient";

import confg from "../../conf.json";
import { Tracking } from "../../services/AnalyticsService/GoogleAnalyticsService";
import { setAuthStatus } from "../account/AccountActions";
import { checkMerchant, getMerchantConfig, MerchantsList } from "../../helpers/helpers";
import { PaymentDetails, Settings } from "../../services/MyCheckWalletService/WalletServiceTypes";
import { MyCheckWalletService } from "../../services/MyCheckWalletService/WalletManager";
import * as Sentry from "@sentry/react";
import { InitializeSentry } from "../../instrument";

const fetchMerchantConfigsFromServer = async (publishableKey: string, merchantId?: string) => {
  const response = await ApiClient(ApiUrls.fetchMerchantConfiguration(publishableKey, merchantId));

  return response.data;
}

const fetchConfigurationFromServer = async (publishableKey: string) => {
  const response = await ApiClient(ApiUrls.fetchThemedConfiguration(publishableKey));
  await Tracking.trackEvent(Tracking.EventAction.BID, response.business_details.id);
  return response;
}

export const fetchTranslationFromServer: any = async (publishableKey: string, lang: string) => {
  const getTranslations = async (language: string) => {
    const url = `https://translations-v1.mycheckapp.com/wallet/v3/${language}.json`;
    const response = await ApiClient(url, { domain: "", noHeaders: true });
    return JSON.parse(response);
  }
  try {
    const json = await getTranslations(lang);
    await Tracking.trackEvent(Tracking.EventAction.Language, lang || confg.languages.default);
    await Tracking.trackEvent(Tracking.EventAction.Direction, json.metadata.isRTL);
    return json;
  } catch (e){
    MyCheckWalletService.events.error(`'${lang}' language is not supported`);
    return getTranslations('en');
  }
};

export const getSPSMerchantId = async (publishableKey: string, providerName: string) => {
  if (providerName === 'sps') {
    const merchantConfigFromServer: any = await fetchMerchantConfigsFromServer(publishableKey);
    const merchantConfig = getMerchantConfig(MerchantsList.SPS_ECOMMERCE, merchantConfigFromServer);
    if (!merchantConfig) {
      MyCheckWalletService.events.error("there is no sps merchant");
      return;
    }
    return merchantConfig.id
  }
}

export const fetchConfiguration = (publishableKey: string) => async (dispatch: Dispatch, getState: () => GlobalState): Promise<void> => {
  try {
    const configurationFromServer: any = await fetchConfigurationFromServer(publishableKey);
    const client = Sentry.getClient();
    if(!!configurationFromServer.config.sentry_enabled && !client) {
      InitializeSentry(publishableKey);
    }
    const appConfig = configurationFromServer.config.wallet_v3;
    const businessDetails = configurationFromServer.business_details;

    appConfig.sections.recaptcha = {
      enabled: !!configurationFromServer.config.captcha_wallet_v3_enabled,
      provider: configurationFromServer.config.captcha_provider,
    };

    appConfig.sections.sentry = {
      enabled: !!configurationFromServer.config.sentry_enabled,
    };

    appConfig.sections.dataDome = {
      enabled: !!configurationFromServer.config.data_dome_captcha_enabled,
    };

    if (appConfig.general?.creditcard_provider?.name === "mycheck") {
      throw new Error('Credit card provider mycheck is not supported');
    }

    dispatch(setAppConfiguration(appConfig));
    dispatch(setBusinessDetails(businessDetails));

    const providerName = appConfig.general.creditcard_provider.name;

    const customConfig = getState().configuration.customWalletConfiguration;
    const lang = customConfig.locale || 'en';
    const merchantId = customConfig.merchant_id || await getSPSMerchantId(publishableKey, providerName);
    const { metadata: { isRTL }, translations } = await fetchTranslationFromServer(publishableKey, lang);
    dispatch(setTranslation(isRTL, translations));

    const merchantConfig: any = await fetchMerchantConfigsFromServer(publishableKey, merchantId);
    dispatch(setMerchantConfiguration(merchantConfig));

    const checkedMerchantId = checkMerchant(merchantConfig, merchantId, providerName);
    if (!checkedMerchantId) {
      dispatch(setAuthStatus(true));
      MyCheckWalletService.events.error('merchantId provided is not supported for the pci provider configured');
      return;
    }
  } catch (err) {
    console.error(err);
    dispatch(setAuthStatus(true))
  }
};

export const setAppConfiguration = (configuration: object): ConfigurationActionTypes => ({
  type: FETCH_CONFIGURATION,
  configuration
});

export const setBusinessDetails = (details: any): ConfigurationActionTypes => ({
  type: SET_BUSINESS_DETAILS,
  details,
})

export const setMerchantConfiguration = (configurations: any): ConfigurationActionTypes => ({
  type: SET_MERCHANT_CONFIGURATION,
  configurations,
});

export const setTranslation = (isRTL: boolean, translation: object): ConfigurationActionTypes => ({
  type: FETCH_TRANSLATION,
  isRTL,
  translation
});

export const setAcceptedCards = (creditCards: string[]): ConfigurationActionTypes => ({
  type: SET_CREDIT_CARDS,
  creditCards: {
    sections: {
      "credit-cards": {
        "cards-accepted": {
          list: creditCards
        }
      }
    }
  }
});

export const setPublishableKey = (publishableKey: string): ConfigurationActionTypes => ({
  type: SET_PUBLISHABLE_KEY,
  publishableKey
})

export const onSetIframeSize = (size: number): ConfigurationActionTypes => ({ type: SET_IFRAME_SIZE, size });
export const appIsReady = (): ConfigurationActionTypes => ({ type: APP_IS_READY });

export const setCustomConfiguration = (config: Settings): ConfigurationActionTypes => ({type: SET_CUSTOM_CONFIGURATION, config})
export const setPaymentDetails = (details: PaymentDetails): ConfigurationActionTypes => ({type: SET_PAYMENT_DETAILS, details})
export const removeAlternativeMethod = (method: string) => ({ type: SET_REMOVED_ALTERNATIVE_METHOD, method })
