/** @jsx jsx */

import * as React from "react";
import { useSelector } from "react-redux";
import { composeTextStyle, composeTooltipStyle } from "../../helpers/CreateStyleFromConfig";
import { jsx } from "@emotion/core";

type MyTooltipTextType = {
  text: string
}

export const MyTooltipText: React.FunctionComponent<MyTooltipTextType> = ({ text }) => {
  const { general } = useSelector((state: GlobalState) => state.configuration.walletConfiguration);
  const toolTipTextStyle = composeTooltipStyle(general);
  const globalTextStyle = composeTextStyle(general, "six");
  return (
    <div css={{
      ...globalTextStyle,
      color: toolTipTextStyle.color,
      } as any}>
      {text}
    </div>
  );
};
