export default {
  "general": {
    "sections": {
      "background": {
        "color": "rgba(172,172,172,0.2)"
      },
      "border": {
        "color": "#ffffff",
        "width": "0",
        "radius": "10px"
      }
    },
    "input": {
      "height": "40px",
      "border": {
        "color": "#000",
        "width": "1px",
        "radius": "20px",
        "shadow": true,
        "side": "bottom"
      },
      "background": {
        "color": "#fff"
      },
      "title": "static",
      "placeholder": {
        "display": "asTitleOutside",
        "color" : "#eee"
      },
      "error": {
        "border": {
          "color": "#EE204D"
        }
      }
    },
    "checkbox": {
      "size": "18px",
      "border": {
        "color": "#000",
        "width": "1px",
        "radius": "4px"
      },
      "background": {
        "color": "#0f0f0f0"
      },
      "selected": {
        "size": "16px",
        "color": "#000"
      }
    },
    "radio": {
      "background": {
        "color": "#eee"
      },
      "border": {
        "color": "#000"
      },
      "selected": {
        "color": "#FBB035"
      }
    },
    "link": {
      "font": "Arial",
      "color": "#eee",
      "text-decoration": "true"
    },
    "dividers": {
      "color": "#ccc",
      "width": "1px"
    },
    "button": {
      "default": {
        "text": {
          "color": "#FBB035",
          "size": "12px",
          "weight": "500"
        }
      },
      "primary": {
        "background": {
          "color": "#000"
        },
        "border": {
          "color": "#fff",
          "width": "0px",
          "radius": "20px"
        },
        "text": {
          "color": "#FBB035",
          "font": "Arial",
          "size": "14px",
          "weight": "500"
        }
      },
      "secondary": {
        "background": {
          "color": "transparent"
        },
        "border": {
          "color": "#000",
          "width": "1px",
          "radius": "20px"
        },
        "text": {
          "color": "#000",
          "font": "Arial",
          "size": "14px",
          "weight": "500"
        }
      },
      "yes": {
        "text": {
          "color": "#000",
          "font": "Arial",
          "size": "12px"
        }
      },
      "no": {
        "text": {
          "color": "red",
          "font": "Arial",
          "size": "12px"
        }
      },
      "remove": {
        "text": {
          "color": "red",
          "font": "Arial",
          "size": "12px",
          "weight": "500"
        }
      },
      "balance": {
        "text": {
          "color": "#000",
          "font": "Arial",
          "size": "12px",
          "weight": "500"
        }
      }
    },
    "tooltip": {
      "border": {
        "color": "#ffffff",
        "width": "0",
        "radius": "10px"
      },
      "background": {
        "color": "#0f0f0f"
      },
      "text": {
        "color": "#fff",
        "size": "12px"
      }
    },
    "error": {
      "text": {
        "color": "#EE204D"
      }
    },
    "icons": {
      "lock": "http://mycheckapp.com/icons/lock.svg",
      "info": "http://mycheckapp.com/icons/info.svg",
      "cc": "http://mycheckapp.com/icons/cc.svg",
      "scan-card": "http://mycheckapp.com/icons/scan-card.svg",
      "arrow-farward": "http://mycheckapp.com/icons/arrow-farward.svg",
      "arrow-backward": "http://mycheckapp.com/icons/arrow-back.svg"
    },
    "text": {
      "general": {
        "font": "Arial"
      },
      "text1": {
        "size": "20px",
        "color": "#000",
        "font": "Arial",
        "weight": 500
      },
      "text11": {
        "size": "14px",
        "color": "#000",
        "font": null,
        "weight": 700
      },
      "text12": {
        "size": "17px",
        "color": "#000",
        "font": null,
        "weight": null
      },
      "text2": {
        "size": "14px",
        "color": "#000",
        "font": null,
        "weight": null
      },
      "text21": {
        "size": "13px",
        "color": "#000",
        "font": null,
        "weight": null
      },
      "text3": {
        "size": "12px",
        "color": "#000",
        "font": null,
        "weight": null
      },
      "text31": {
        "size": "11px",
        "color": "#000",
        "font": null,
        "weight": null
      },
      "text32": {
        "size": "11px",
        "color": "#000",
        "font": null,
        "weight": null
      },
      "text4": {
        "size": "10px",
        "color": "#000",
        "font": null,
        "weight": null
      }
    },
    "rtl" : {
      "supported": true
    }
  },
  "sections": {
    "title": {
      "visible": true
    },
    "credit-cards": {
      "visible": true,
      "do-not-store": {
        "visible": true
      },
      "cards-accepted": {
        "visible": true,
        "list": ["visa","mastercard","diners"]
      },
      "zip-code": {
        "visible": true
      },
      "icons": {
        "cc": {
          "visible": true,
          "color": "#fff",
          "size": "12px"
        },
        "lock": {
          "visible": true,
          "color": "#fff",
          "size": "12px"
        },
        "info": {
          "visible": true,
          "color": "#fff",
          "size": "12px"
        },
        "scan-card": {
          "visible": true,
          "color": "#fff",
          "size": "12px"
        }
      }
    },
    "alternatives": {
      "enabled": {
        "masterpass": {a: 'a'},
        "vco": {},
        "paypal": {a: 'a'},
        "gpay": {},
      }
    },
    "instant-payments": {
      "general:": {
        "selected": {
          "shadow": {
            "color": "#fff"
          }
        }
      },
      "enabled": {
        "applepay": {
          set: "true"
        },
        "alipay": {
          set: "true"
        },
        "wechat": {
          set: "true"
        }
      },
      "icons": {
        "arrow-farward": {
          "color": "#fff",
          "size" : "12px"
        },
        "arrow-backward": {
          "color": "#fff",
          "size" : "12px"
        }
      }
    },
    "giftcards": {
      "visible": true,
      "balance-details-modal": {
        "background": {
          "color": "#fff"
        },
        "header": {
          "background": {
            "color": "#fff"
          }
        },
        "radius": "12px"
      },
      "explanatory-text": {
        "color": "#0f0f0f0"
      }
    }
  },
  "languages": {
    "default": "en",
    "metadata": {
      "version" : "1"
    },
    "translations": {
      "en": {
        "DO_NOT_STORE": "Do not Store"
      }
    }

  }
}

