interface IMemoryStorage {
    [key:string] : string
}

interface IStorageFactory {
  getItem: (name: string) => string | null,
  setItem: (name:string, value: string) => any,
  key: (index: number) => string | null,
  removeItem: (name: string) => void,
  clear: () => void,
}

type GetStorage = () => Storage;
type StorageFactory = (getStorage: GetStorage) => IStorageFactory;

const storageFactory: StorageFactory = (getStorage) => {
    let inMemoryStorage: IMemoryStorage = {};

    const _isSupported = () => {
        try {
            const testKey = "__some_random_key_you_are_not_going_to_use__";
            getStorage().setItem(testKey, testKey);
            getStorage().removeItem(testKey);
            return true;
        } catch (e) {
            return false;
        }
    }

    const clear = () => {
        if (_isSupported()) {
            getStorage().clear();
        } else {
            inMemoryStorage = {};
        }
    }

    const getItem = (name: string) => {
        if (_isSupported()) {
            return getStorage().getItem(name);
        }
        if (inMemoryStorage.hasOwnProperty(name)) {
            return inMemoryStorage[name];
        }
        return null;
    }

    const key = (index: number) => {
        if (_isSupported()) {
            return getStorage().key(index);
        } else {
            return Object.keys(inMemoryStorage)[index] || null;
        }
    }

    const removeItem = (name: string) => {
        if (_isSupported()) {
            getStorage().removeItem(name);
        } else {
            delete inMemoryStorage[name];
        }
    }

    const setItem = (name:string, value: string) => {
        if (_isSupported()) {
            getStorage().setItem(name, value);
        } else {
            inMemoryStorage[name] = String(value);
        }
    }

    const length = () => {
        if (_isSupported()) {
            return getStorage().length;
        } else {
            return Object.keys(inMemoryStorage).length;
        }
    }

    return {
        getItem,
        setItem,
        removeItem,
        clear,
        key,
        get length() {
            return length();
        },
    };
}

export const local = storageFactory(() => localStorage);
export const session = storageFactory(() => sessionStorage);
