import { 
  AccountActionTypes,
  SET_ACCESS_TOKEN,
  SET_AUTH_STATUS, 
  SET_USER_TOKENS
} from "./AccountTypes";

const initialState: AccountState = {
  accessToken: null,
  refreshToken: null,
  isGuest: true,
  authIsFailed: false,
};

export function accountReducer(state = initialState, action: AccountActionTypes): AccountState {
  switch (action.type) {
    case SET_USER_TOKENS:
      return {
        ...state,
        accessToken: action.accessToken,
        refreshToken: action.refreshToken,
        isGuest: action.isGuest
      };

    case SET_AUTH_STATUS:
      return {
        ...state,
        authIsFailed: action.status
      }

    case SET_ACCESS_TOKEN:
      return {
        ...state,
        accessToken: action.accessToken
      }

    default:
      return state;
  }
}
