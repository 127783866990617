/** @jsx jsx */

import * as React from "react";
import { useSelector } from "react-redux";
import { jsx } from "@emotion/core";

import styles from "./Divider.module.scss";
import classNames from "classnames";
import { MyText } from "../MyText/MyText";
import { composeDividerStyles } from "../../helpers/CreateStyleFromConfig";

type DividerType = {
  label?: string | React.ReactNode,
  className?: string
}

export const Divider: React.FunctionComponent<DividerType> = ({ label, className }) => {
  const { general } = useSelector((state: GlobalState) => state.configuration.walletConfiguration);
  const { width, backgroundColor } = composeDividerStyles(general);
  const currentClassName = classNames(styles.label, className && { [className]: true });
  return (
    <div
      className={currentClassName}
      css={{ ":before, :after": { height: width, backgroundColor, } }}
    >
      {label && (
        <MyText type="one" className={styles.content}>{label}</MyText>
      )}
    </div>
  );
};
