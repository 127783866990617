import {
  FETCH_PAYMENTS,
  HANDLE_MANAGEMENT_MODAL,
  ON_3DS_AUTHORIZE,
  ON_3DS_AUTHORIZE_COMPLETED,
  ON_ADD_CARD_PRESS,
  ON_CANCEL_ADD,
  ON_PAYCONIQ,
  ON_PAYCONIQ_COMPLETED,
  ON_SPS_3DS_AUTHORIZE,
  ON_WE_CHAT,
  ON_WE_CHAT_COMPLETED,
  PaymentActionTypes,
  SET_3DS_STATUS,
  SET_CURRENT_CVV,
  SET_CURRENT_PAYMENT,
  SET_CURRENT_PAYMENT_OPTION,
  SET_DEFAULT_PAYMENT,
  SET_ERROR_MESSAGE,
  SET_LOADER,
  SET_MANAGE_PAYMENTS,
  SET_PAYMENT_METHOD_SELECTED,
  SET_PENDING_ALTERNATIVE_PAYMENT,
  SET_USER_ID,
  SET_MEMORIZED_CARD,
  SET_SUCCESS_CARD_POPUP,
  SPS_TRANSACTION_COMPLETE,
  SET_SPS_APM_ERROR,
  SET_READY_SPS_APM,
  SET_ADD_CARD_FIELDS,
  SET_FAILURE_ADDING_CARD_ID,
  SET_DISPLAY_ALTERNATIVE_DIVIDER,
} from "./PaymentTypes";

const defaultPayment = {
  expMonth: "",
  expYear2: "",
  type: "",
  token: "",
  ccToken: "",
  id: "",
  issuerFull: "",
  isSingleUse: 0,
  name: "",
  source: "",
  value: "",
  last4Digits: "",
  isDefault: false,
  encrypted_cvv: null
}

const initialState: PaymentState = {
  cards: [],
  currentPayment: defaultPayment,
  paymentOption: 'credit-card',
  managePaymentsShow: false,
  managementModal: false,
  memorizedCard: null,
  successCardPopup: false,

  weChatUrl: "",
  weChatUrlIsQr: false,

  securityUrl: "",
  securityParameters: {
    MD: "",
    PaReq: "",
    TermUrl: ""
  },

  isAdding: false,
  pendingAlternativePayment: false,
  paymentMethodSelected: {
    paymentName: "",
    type: null,
  },

  loader: false,
  errorMessage: "",
  userId: null,
  status3DS: null,
  spsTransactionComplete: false,
  spsApmError: false,
  spsApmIsReady: false,
  addCardFields: {},
  failureAddingCardId: "",
  displayAlternativeDivider: true,
};

export function paymentReducer(state = initialState, action: PaymentActionTypes): PaymentState {
  switch (action.type) {
    case FETCH_PAYMENTS:
      return {
        ...state,
        cards: action.cards
      };

    case SET_PAYMENT_METHOD_SELECTED:
      return {
        ...state,
        paymentMethodSelected: action.selectedPaymentType,
        ...action.resetCurrentPayment ? { currentPayment: defaultPayment } : {}
      }

    case SET_CURRENT_PAYMENT:
      return {
        ...state,
        currentPayment: action.card
      };

    case SET_CURRENT_CVV:
      return {
        ...state,
        currentPayment: {
          ...state.currentPayment,
          encrypted_cvv: action.cvv
        },
        cards: state.cards.map(e => e.id === state.currentPayment.id ? ({ ...e, encrypted_cvv: action.cvv }) : e)
      };

    case SET_CURRENT_PAYMENT_OPTION:
      return {
        ...state,
        paymentOption: action.option,
        ...action.option === 'credit-card' ? { currentPayment: state.cards.find(e => e.isDefault) || defaultPayment } : {}
      };

    case SET_MANAGE_PAYMENTS:
      return {
        ...state,
        managePaymentsShow: action.value,
        isAdding: false
      };

    case ON_WE_CHAT:
    case ON_PAYCONIQ:
      return {
        ...state,
        weChatUrl: action.source,
        weChatUrlIsQr: !!action.source
      };

    case ON_WE_CHAT_COMPLETED:
    case ON_PAYCONIQ_COMPLETED:
      return {
        ...state,
        weChatUrl: "",
        weChatUrlIsQr: false,
      };

    case ON_3DS_AUTHORIZE:
      return {
        ...state,
        securityUrl: action.url,
        securityParameters: {
          TermUrl: action.parameters.TermUrl,
          PaReq: action.parameters.PaReq,
          MD: action.parameters.MD,
        }
      };

    case ON_SPS_3DS_AUTHORIZE:
      return {
        ...state,
        securityUrl: action.url
      }

    case ON_3DS_AUTHORIZE_COMPLETED:
      return {
        ...state,
        securityUrl: "",
        securityParameters: {
          TermUrl: "",
          PaReq: "",
          MD: "",
        }
      };

    case SET_3DS_STATUS:
      return {
        ...state,
        status3DS: action.status3DS
      }

    case ON_ADD_CARD_PRESS:
      return {
        ...state,
        isAdding: true
      };

    case ON_CANCEL_ADD:
      return {
        ...state,
        isAdding: false
      };

    case SET_DEFAULT_PAYMENT:
      return {
        ...state,
        cards: state.cards.map((pm: PaymentMethod) => pm.id === action.id ?
          ({ ...pm, isDefault: true }) :
          ({ ...pm, isDefault: false })
        ) as PaymentMethod[]
      };

    case SET_PENDING_ALTERNATIVE_PAYMENT:
      return {
        ...state,
        pendingAlternativePayment: action.pending
      };

    case SET_LOADER:
      return {
        ...state,
        loader: action.loader
      }

    case SET_ERROR_MESSAGE:
      return {
        ...state,
        errorMessage: action.message,
      }

    case SET_USER_ID:
      return {
        ...state,
        userId: action.id,
      }

    case HANDLE_MANAGEMENT_MODAL:
      return {
        ...state,
        managementModal: action.state,
      }

    case SET_MEMORIZED_CARD:
      return {
        ...state,
        memorizedCard: action.card
      }

    case SET_SUCCESS_CARD_POPUP:
      return {
        ...state,
        successCardPopup: action.state,
      }

    case SPS_TRANSACTION_COMPLETE:
      return {
        ...state,
        spsTransactionComplete: action.state,
      }

    case SET_SPS_APM_ERROR:
      return {
        ...state,
        spsApmError: action.error,
      }

    case SET_READY_SPS_APM:
      return {
        ...state,
        spsApmIsReady: action.isReady
      }

    case SET_ADD_CARD_FIELDS:
      return {
        ...state,
        addCardFields: action.addCardFields
      }
    case SET_FAILURE_ADDING_CARD_ID:
      return {
        ...state,
        failureAddingCardId: action.id
      }

    case SET_DISPLAY_ALTERNATIVE_DIVIDER:
      return {
        ...state,
        displayAlternativeDivider: action.displayAlternativeDivider,
      }

    default:
      return state;
  }
}
