import * as React from "react";
import { useDispatch, useSelector } from "react-redux";

import { ApiClient } from "../ApiClient";
import { mobileAndTabletCheck } from "../IsMobile";
import { Tracking } from "../AnalyticsService/GoogleAnalyticsService";
import { Configuration } from "../../configs/Configuration";

import { store } from "../../store";
import {
  setCurrentPaymentOption,
  setPaymentMethodSelected,
} from "../../store/payment/PaymentActions";

import { Container } from "../../components/Container/Container";
import { MyTouchable } from "../../components/MyTouchable/MyTouchable";
import { MyText } from "../../components/MyText/MyText";
import { MyRadio } from "../../components/MyRadio/MyRadio";
import { InstantPaymentsName } from "../../containers/InstantPayments/InstantPaymentsName";

import styles from "../../containers/InstantPayments/InstantPaymentsDisplay.module.scss";
import { getMerchantConfig, MerchantsList } from "../../helpers/helpers";
import { parseTimestamp6 } from "../../helpers/parseTimestamp6";
import { MyCheckWalletService } from "../MyCheckWalletService/WalletManager";
import { firebaseService } from "../FirebaseService";

const getBusinessId = () => {
  try {
    return JSON.parse(atob(store.getState().account.accessToken!.split(".")[1])).sub.business_id;
  } catch (err) {
    return "";
  }
};

export const attachPayconiq = async () => {
  try {
    const merchantConfigurations =store.getState().configuration.walletConfiguration.merchantConfigurations
    const { id } = getMerchantConfig(MerchantsList.PAYCONIQ, merchantConfigurations) || {};

    const response = await ApiClient("/wallet/api/v1/billing/alternative/pay", {
      domain: Configuration.api_domain,
      method: "POST",
      body: {
        merchant_id: id,
        business_id: getBusinessId(),
        type: mobileAndTabletCheck() ? "url" : "qr",
        return_url: window.location.href,
        amount: MyCheckWalletService.getPaymentInfo().amount,
        currency: MyCheckWalletService.getPaymentInfo().currency,
      }
    });

    return {
      qr: response.response_data.qr,
      url: response.response_data.url,
      transaction_id: response.transaction.transaction_id,
      expiration: response.transaction?.metadata?.find((element: any) => element.key === "expiration")?.value
    };
  } catch (e){
  }

};

export const onPayconiqCompleteListener = ({ transactionId, expiration }: { transactionId: string, expiration?: number }) => new Promise(async (resolve, reject) => {
  const defaultDatabase = firebaseService.getDatabase();
  const refTransaction = defaultDatabase.ref(`Transactions/${transactionId}`);
  const refLogs = defaultDatabase.ref(`Transactions/${transactionId}/logs`);

  refTransaction.on("value", function (snapshot: any) {
    const value = snapshot.val();
    if (value) {
      const object: any = Object.values(value)[0];

      if (object.status === "SUCCESS" || object.status === "FAILURE") {
        MyCheckWalletService.events.paymentCompleted(object);
        refLogs.push(`fired event of paymentCompleted at: ${parseTimestamp6()}`)
        resolve(null);
      }
    }
  });

  if (expiration) {
    const currentDate = new Date();
    const expirationDate = new Date(expiration);
    const dif = expirationDate.getTime() - currentDate.getTime() - 10000;
    setTimeout(() => {
      reject()
    }, dif);
  }
});

type PayconiqServiceType = {}

export const PayconiqService: React.FunctionComponent<PayconiqServiceType> = () => {
  const type = "Payconiq";
  const dispatch = useDispatch();
  const currentPaymentOption = useSelector((state: GlobalState) => state.payment.paymentOption);

  const onPress = () => {
    Tracking.trackEvent(Tracking.EventAction.InstantPM, type);
    dispatch(setCurrentPaymentOption(type));

    MyCheckWalletService.events.paymentMethodSelected('payconiq', 'instant');
    dispatch(setPaymentMethodSelected('payconiq', 'instant', true));

    MyCheckWalletService.checkIsCheckoutReady({});
  };

  return (
    <MyTouchable onClick={onPress} ariaLabel="Payconiq button" className={styles.root} id="payconiq">
      <Container className={styles.row} smallPadding>
        <MyRadio value={currentPaymentOption} option={{ label: "", value: type }} tabIndex={-1} id={type}/>
        <img
          src={`${Configuration.assets}/static/media/${InstantPaymentsName.payconiq}`} alt={type}
          className={styles.image}
        />
        <MyText type="three" className={styles.label}>Payconiq</MyText>
      </Container>
    </MyTouchable>
  );
};
