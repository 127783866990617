import { Configuration } from "../configs/Configuration";

export const attachMasterpass = (checkoutid: string) => Loader.js(Configuration.masterpass + `?locale=en_us&checkoutid=${checkoutid}`);
export const attachGoogleAnalytics = async () => Loader.js(Configuration.google_analytics);

export const attachGooglePay = () => Loader.js(Configuration.google_pay);
export const attachPayPal = () => Promise.all([
  Loader.js(Configuration.paypal),
  Loader.js(Configuration.paypal_client),
  Loader.js(Configuration.paypal_checkout)
]);
export const attachPayPalCollector = () => Loader.js(Configuration.paypal_collector);

type PromiseCacheType = {
  [key: string]: Promise<void> | unknown
}

const _PromiseCache: PromiseCacheType = {};

const _load = (url: string) => {
  if (_PromiseCache[url]) {
    return _PromiseCache[url];
  }

  const newPromise = new Promise(function (resolve, reject) {
    const element = document.createElement("script");
    element.onload = function () {
      resolve(url);
    };
    element.onerror = function () {
      reject(url);
    };

    element.async = true;
    element.src = url;
    if (document.body) {
      document.body.appendChild(element);
    } else if(document.head) {
      document.head.appendChild(element);
    }
  });

  _PromiseCache[url] = newPromise;

  return newPromise;
};

const Loader = { js: _load };
