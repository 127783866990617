import creditCardType from "credit-card-type";
import cardValidator from "card-validator";
import { store } from "../store";

const trimmedValue = (value: string) => value.split('_',).join('').trim();

export const getDenormalizedValue = (type: string) => {
  switch (type) {
    case "amex":
      return "american-express";
    case "diners":
      return "diners-club";

    default:
      return type;
  }
};

export const getNormalizedValue = (type: string) => {
  switch (type) {
    case "american-express":
      return "amex";
    case "diners-club":
      return "diners";

    default:
      return type;
  }
};

export const getCardType = (value: string) => {
  const isMaestroSupported = store.getState().configuration.walletConfiguration.general.supported_card_types
    .filter((cardType: string) => cardType === 'maestro');
  if (!isMaestroSupported.length) {
    // @ts-ignore
    creditCardType.updateCard(creditCardType.types.DISCOVER, {
      patterns: [6011, [644, 649], 65, 601, 60, 6]
    });
    // @ts-ignore
    creditCardType.updateCard(creditCardType.types.MAESTRO, {
      patterns: [493698, [500000, 506698], [506779, 508999], [56, 59], 63, 67]
    });
  }

  if (isMaestroSupported.length) {
    // @ts-ignore
    creditCardType.updateCard(creditCardType.types.MAESTRO, {
      patterns: [493698, [500000, 506698], [506779, 508999], [56, 59], [61, 64], 6, 50, 60]
    });
  }
  
  // @ts-ignore
  creditCardType.updateCard(creditCardType.types.UNIONPAY, {
    patterns: [ 622126, [622200, 622925], [624000, 626999], [628200, 628899], [629100, 629199], 628000, 62 ]
  }); 

  const typeValue = creditCardType(value)[0];
  if (!typeValue || !value) {
    return "";
  }
  const type = typeValue.type;
  return getNormalizedValue(type || "");
};

export const isAmexCard = (value: string) => cardValidator.number(value.replace(/_/g, "")).card?.niceType === "American Express";
export const isDinersCard = (value: string) => cardValidator.number(value.replace(/_/g, "")).card?.niceType === "Diners Club";
export const isValidCardType = (value: string, isTypeHere = true) => {
  if (!isTypeHere) {
    return false;
  }
  return cardValidator.number(value).isValid;
}
export const isValidCardExpiration = (value: string) => cardValidator.expirationDate(value).isValid;
export const isValidCvv = (value: string, maxLength?: number) => cardValidator.cvv(value, maxLength).isValid;
export const isValidZip = (value: string) => cardValidator.postalCode(value).isValid;

export const NOT_ACCEPTED_CARD: string = 'not_accepted_card';
export const NON_VALID_CARD: string = 'non_valid_card';

let lastCardType: string | null = null;

export const isPotentiallyValid = (value: string, acceptedCards: Array<string>) => {
  const typeValue = creditCardType(value)[0];
  const cardType = typeValue && typeValue.type;
  const trimmedCardNumber = trimmedValue(value);

  const notValid = {
    isValid: false,
    isPotentiallyValid: false,
    reason: NOT_ACCEPTED_CARD,
  }

  if (trimmedCardNumber.length > 3 && cardType) {
    lastCardType = cardType;
    if (acceptedCards && !acceptedCards.includes(getNormalizedValue(cardType))) {
      return notValid;
    }
  }
  if (trimmedCardNumber.length === 4 && !cardType) {
    return notValid;
  }
  if (trimmedCardNumber.length > 3 && !cardType) {
    if (lastCardType) {
      return {...notValid, isPotentiallyValid: true}
    }
    return notValid;
  }

  return {
    ...cardValidator.number(value.replace(/_/g, "")),
    reason: NON_VALID_CARD,
  };
};
