import * as React from "react";
import ReactModal from "react-modal";
import classNames from "classnames";

import styles from "./Modal.module.scss";
import { MyTouchable } from "../MyTouchable/MyTouchable";
import { WalletRootId } from "../..";
import { MyIcon } from "../MyIcon/MyIcon";

type ModalType = {
  isOpen: boolean,
  onRequestClose: () => void,
  contentLabel: string,
  isAdaptive?: boolean,
  className?: string,
  touchableOverlay?: boolean
}

export const Modal: React.FunctionComponent<ModalType> = ({ isOpen, onRequestClose, contentLabel, children, className, isAdaptive = true, touchableOverlay = true }) => {
  React.useEffect(() => {
    ReactModal.setAppElement("#" + WalletRootId);
  }, []);

  const classes = classNames(styles.modal, { [styles.adaptiveModal]: isAdaptive }, className && { [className]: true });

  const onOverlayClick = () => {
    if (touchableOverlay) {
      onRequestClose();
    }
  };

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onOverlayClick}
      contentLabel={contentLabel}
      className={classes}
      overlayClassName={styles.overlay}
      ariaHideApp={false}
    >
      {touchableOverlay && (
        <MyTouchable onClick={onRequestClose} className={styles.close} id="modal-close">
          <MyIcon style={{ maxHeight: 30 }} type="close" />
        </MyTouchable>
      )}
      {children}
    </ReactModal>
  );
};
