import { store } from "../store";
import { CVVRecord } from "./EncryptedCVVManager";
import {
  fetchPayments,
  setCurrentPaymentOption,
  setPaymentMethodSelected,
  setSuccessCardPopup
} from "../store/payment/PaymentActions";
import { getPaymentMethodNameType } from "./GetPaymentMethodNameType";
import { dropApplyBucks } from "../store/giftCard/GiftCardActions";
import { MyCheckWalletService } from "../services/MyCheckWalletService/WalletManager";

export const clickControl = async (CVVRecordData?: CVVRecord | null, paymentMethod?: any) => {
  store.dispatch(fetchPayments());
  store.dispatch(setCurrentPaymentOption('credit-card'));
  const { name, type } = getPaymentMethodNameType(paymentMethod)
  MyCheckWalletService.events.paymentMethodSelected(name, type)
  store.dispatch(setPaymentMethodSelected(name, type));
  store.dispatch(dropApplyBucks());
  store.dispatch(setSuccessCardPopup(false));

  await MyCheckWalletService.checkIsCheckoutReady({
    cc_token: paymentMethod ? paymentMethod.ccToken : null,
    encrypted_cvv: CVVRecordData ? CVVRecordData.encryptedCVV : null,
  });
}