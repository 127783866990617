import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";

import { getGiftCards, onApplyHelloBucks } from "../../store/giftCard/GiftCardActions";

import { MyCheckbox } from "../../components/MyCheckbox/MyCheckbox";
import { Container } from "../../components/Container/Container";

import { GiftCardsBenefitsModal } from "./GiftCardsBenefitsModal";
import { GiftCardsAmountToApply } from "./GiftCardsAmountToApply";
import { GiftCardsAvailable } from "./GiftCardsAvailable";

import { useIsRTL } from "../../hooks/useIsRTL";
import { useTranslation } from "../../hooks/useTranslation";

import { Tracking } from "../../services/AnalyticsService/GoogleAnalyticsService";

import styles from "./GiftCardsSection.module.scss";
import { useFadeOutWhen } from "../../hooks/useFadeOutWhen/useFadeOutWhen";
import { View } from "../../services/MyCheckWalletService/WalletServiceTypes";
import { MyCheckWalletService } from "../../services/MyCheckWalletService/WalletManager";

type GiftCardsSectionType = {}

export const checkGiftCardSection = (section: any): boolean => {
  if (
    section &&
    section.active &&
    section.balance_modal &&
    section.balance_modal.background &&
    section.balance_modal.background.color &&
    section.balance_modal.header &&
    section.balance_modal.header.background &&
    section.balance_modal.header.background.color
    ) {
    return true;
  }
  return false;
};

export const GiftCardsSection: React.FunctionComponent<GiftCardsSectionType> = () => {
  const dispatch = useDispatch();
  const isRTL = useIsRTL();

  React.useEffect(() => {
    Tracking.trackEvent(Tracking.EventAction.Section, "Gift Cards");
    dispatch(getGiftCards());
  }, []);

  const isApplyBucksChecked = useSelector((state: GlobalState) => state.giftCard.isApplyBucks);
  const hasBalance = useSelector((state: GlobalState) => !!state.giftCard.balance);
  const available = useSelector((state: GlobalState) => state.giftCard.balance);
  const amountToApply = useSelector((state: GlobalState) => state.giftCard.amountToApply);
  const currentView = useSelector((state: GlobalState) => state.configuration.customWalletConfiguration.view)

  const onClick = () => {
    Tracking.trackEvent(Tracking.EventAction.GiftCardApply, isApplyBucksChecked ? "change to no" : "change to yes");

    if (isApplyBucksChecked) {
      MyCheckWalletService.events.giftCardSelected({
        isSelected: false,
        balance: available,
        used: amountToApply === "" ? 0 : amountToApply,
        currency: MyCheckWalletService.getPaymentInfo().currency,
      });
      dispatch(onApplyHelloBucks(0));
    } else {
      dispatch(onApplyHelloBucks(""));
    }
  };

  const containerClasses = classNames(styles.root, currentView === View.MANAGE && styles.manageView, useFadeOutWhen("card-add"))
  const firstRowClasses = classNames(styles.firstRow, { [styles.rowRtl]: isRTL }, currentView === View.MANAGE && styles.manageViewButton);
  const rowClasses = classNames(styles.row, { [styles.rowRtl]: isRTL });

  const label = useTranslation("APPLY_GIFT_CARD")

  return (
    <Container className={containerClasses} testId="gift-card-section">
      <div className={firstRowClasses}>
        {(currentView === View.CHECKOUT) &&
          <MyCheckbox
            testId="apple-balance"
            textGroup="one"
            disabled={!hasBalance}
            isChecked={isApplyBucksChecked}
            onClick={onClick}
            label={label}
          />}
        <GiftCardsBenefitsModal />
      </div>
      <div className={rowClasses}>
        {(currentView === View.CHECKOUT) && <GiftCardsAmountToApply />}
        <GiftCardsAvailable />
      </div>
    </Container>
  );
};
