import { store } from "../store";
import { Base64 } from "./Base64";

export const isWebView = () => {
  //@ts-ignore
  return !!window.webkit && !!window.webkit.messageHandlers.walletMessage
};

export enum MerchantsList {
  "THREEC" = "THREEC",
  "GOOGLE_PAY" = "GOOGLE_PAY",
  "MASTERPASS" = "MASTERPASS",
  "BRAINTREE" = "BRAINTREE",
  "PAYCONIQ" = "PAYCONIQ",
  "APPLE_PAY" = "APPLE_PAY",
  "APPLE_PAY_WEB" = "APPLE_PAY_WEB",
  "AMAZONPAY" = "AMAZONPAY",
  "AMEX" = "AMEX",
  "ALIPAY" = "ALIPAY",
  "WECHAT" = "WECHAT",
  "SPS_ECOMMERCE" = "SPS_ECOMMERCE",
}
export enum MerchantSpeciality {
  GPAY = "gpay_api_user",
}

export const getPaymentType = (payment?: PaymentMethod) => {
  if (
    !payment || !payment.source ||
    payment.source === MerchantsList.THREEC ||
    payment.source === MerchantsList.SPS_ECOMMERCE
  ) {
    return 'traditional';
  }

  if (
    payment.source === MerchantsList.WECHAT
    || payment.source === MerchantsList.ALIPAY
    || payment.source === MerchantsList.PAYCONIQ
  ) {
    return 'instant';
  }

  return 'alternative';
}

export const getPaymentName = (payment?: PaymentMethod) => {
  if (
    !payment || !payment.source ||
    payment.source === MerchantsList.THREEC ||
    payment.source === MerchantsList.SPS_ECOMMERCE
  ) {
    return 'creditcard';
  }

  return payment.source.toLocaleLowerCase().replace('_', '')
};

export const getMerchantConfig = (merchantName: MerchantsList, merchantConfigs: any, speciality?: MerchantSpeciality) => {
  const isSps = store.getState().configuration.walletConfiguration.general.creditcard_provider.name === "sps";
  const merchantId = store.getState().configuration.customWalletConfiguration.merchant_id;

  if (isSps && merchantName !== MerchantsList.GOOGLE_PAY) {
    const foundConfigById = merchantConfigs.find(
      (config: any) => Number(config.id) === Number(merchantId)
    );
    const foundConfigByName = merchantConfigs.find(
      (config: any) =>
        config.name === merchantName &&
        config.usage.find((usage: string) => usage === "add_card")
    );
    const foundConfigBySpeciality = speciality && merchantConfigs.find(
      (config: any) =>
        config.name === merchantName &&
        config.usage.find((usage: string) => usage === "add_card") &&
        !!config.settings[speciality]
    );

    if (foundConfigById) {
      return foundConfigById;
    }
    if (speciality) {
      return foundConfigBySpeciality;
    }
    if (foundConfigByName) {
      return foundConfigByName;
    }
  }
  return merchantConfigs.find((config: any) => config.name === merchantName);
}

export const checkMerchant = (configs: any, merchantId: string | number, providerName: string) => {
  if (providerName === 'sps') {
    return !!configs.find(
      (merchant: any) => {
        return Number(merchant.id) === Number(merchantId) && merchant.name === MerchantsList.SPS_ECOMMERCE
      }
    );
  }
  if (providerName === 'mycheck' && !merchantId) {
    return true;
  }
  return !!configs.find(
    (merchant: any) =>
      Number(merchant.id) === Number(merchantId)
      && merchant.name !== MerchantsList.SPS_ECOMMERCE
      && merchant.name !== MerchantsList.GOOGLE_PAY
      && merchant.name !== MerchantsList.BRAINTREE
      && merchant.name !== MerchantsList.APPLE_PAY
      && merchant.name !== MerchantsList.APPLE_PAY_WEB
      && merchant.name !== MerchantsList.MASTERPASS
      && merchant.name !== MerchantsList.ALIPAY
  );
}

export const getUserId = (accessToken: string): number | null => {
  const decodedAccessToken = Base64.decode(accessToken);
  let userId: RegExpMatchArray | string | null = decodedAccessToken.match(/\"id\":\d+/);
  if (!!userId) {
    userId = userId[0].replace(`"id":`, '').trim();
    return Number(userId);
  }
  return null;
};

export const generateRandomNumber = (min: number = 1000, max: number = 9999): number => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}
