import * as React from 'react'
import { RadioGroup, Radio, FormControlLabel, FormGroup } from "@material-ui/core";

type Props = {
  value: string,
  handleChange: (_event: React.ChangeEvent<HTMLInputElement>, value: string) => void,
  options: Array<{ value: string, label: string }>
}

export const DemoRadioButton: React.FC<Props> = ({ options, value, handleChange }) => {
  return (
    <FormGroup row>
      <RadioGroup aria-label="gender" name="gender1" value={value} onChange={handleChange}>
        {options.map(e => <FormControlLabel key={e.value} value={e.value} control={<Radio />} label={e.label} />)}
      </RadioGroup>
    </FormGroup>
  );
}
