/** @jsx jsx */

import * as React from "react";
import { jsx } from "@emotion/core";
import classNames from "classnames";

import { MyText } from "../MyText/MyText";

import { useIsRTL } from "../../hooks/useIsRTL";

import styles from "./MyInput.module.scss";

type MyInputLabelType = {
  label: string,
  padding: string,
  tooltip?: React.ReactNode
}

export const MyInputLabel: React.FunctionComponent<MyInputLabelType> = ({ label, tooltip, padding }) => {
  const isRTL = useIsRTL();
  const labelClasses = classNames(styles.labelRoot, { [styles.inputRtl]: isRTL });

  return (
    <div className={labelClasses} css={{ padding }}>
      <MyText type="three">
        {label}
      </MyText>
      {tooltip && (
        <div className={styles.icon}>{tooltip}</div>
      )}
    </div>
  );
};
