/** @jsx jsx */

import * as React from "react";
import { useSelector } from "react-redux";
import { jsx } from "@emotion/core";

import { MyTouchable } from "../MyTouchable/MyTouchable";

import styles from "./MyRadio.module.scss";
import { MyText } from "../MyText/MyText";
import classNames from "classnames";
import { useIsRTL } from "../../hooks/useIsRTL";
import { composeRadioBtnStyles } from "../../helpers/CreateStyleFromConfig";

type MyCheckboxType = {
  id: string
  value: string,
  option: RadioOption
  onClick?: (value: string) => void,
  disabled?: boolean,
  className?: string,
  tabIndex?: number
}

export const MyRadio: React.FunctionComponent<MyCheckboxType> = ({ id, disabled, onClick, value, option, className, tabIndex }) => {
  const isRTL = useIsRTL();
  const { general } = useSelector((state: GlobalState) => state.configuration.walletConfiguration);
  const isSelected = value === option.value;
  const onClickHandler = () => onClick ? onClick(option.value) : null;
  const stylesForInput = classNames(styles.row, { [className!]: !!className, [styles.rowRtl]: isRTL });
  const stylesRadio = classNames(styles.radio, { [styles.radioRtl]: isRTL, [styles.selected]: isSelected});

  const renderContent = () => (
    <React.Fragment>
      <div className={stylesRadio} style={{ ...composeRadioBtnStyles(general) }}>
        <div className={styles.dot} css={isSelected && { backgroundColor: general.button.color.primary }} />
      </div>
      <MyText type="one" className={className}>{option.label}</MyText>
    </React.Fragment>
  );

  if (!onClick) {
    return (
      <div className={stylesForInput} tabIndex={tabIndex!}>
        {renderContent()}
      </div>
    )
  }

  return (
    <MyTouchable disabled={disabled!} onClick={onClickHandler} className={stylesForInput} tabIndex={tabIndex!} id={id}>
      {renderContent()}
    </MyTouchable>
  );
};
