/** @jsx jsx */
import { css, jsx } from '@emotion/core'

import React from "react";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";

import { PaymentOption } from "../PaymentOption/PaymentOption";
import { MyButton } from "../../components/MyButton/MyButton";

import {
  handleManagementModal,
  onCancelAdd,
  setErrorMessage,
  setManagePayments,
  setMemorizedCard,
} from "../../store/payment/PaymentActions";

import { useIsRTL } from "../../hooks/useIsRTL";
import { useTranslation } from "../../hooks/useTranslation";

import styles from "./CreditCards.module.scss";
import { Tracking } from "../../services/AnalyticsService/GoogleAnalyticsService";
import { clickControl } from '../../helpers/CardOptionHelper';
import { CVVRecord, EncryptedCVVManagerHelper } from '../../helpers/EncryptedCVVManager';
import { View } from '../../services/MyCheckWalletService/WalletServiceTypes';
import { mobileAndTabletCheck } from '../../services/IsMobile';

type CreditCardsOptionType = {
  hasCards: boolean,
  showRadioButtons: boolean,
  isAdding: boolean
}

export const CreditCardsOption: React.FunctionComponent<CreditCardsOptionType> = ({ hasCards, isAdding }) => {
  const dispatch = useDispatch();
  const isRTL = useIsRTL();
  const isGuest = useSelector((state: GlobalState) => state.account.isGuest);
  const managePaymentsShow = useSelector((state: GlobalState) => state.payment.managePaymentsShow);
  const currentView = useSelector((state: GlobalState) => state.configuration.customWalletConfiguration.view);
  const isModalConfigured = useSelector((state: GlobalState) => state.configuration.walletConfiguration.sections.credit_cards.manage_payment?.modal);
  const paymentMethod = useSelector(
    (state: GlobalState) =>
      state.payment.cards.find((e) => e.isDefault)! ||
      state.payment.currentPayment
  );
  const { general: theme } = useSelector((state: GlobalState) => state.configuration.walletConfiguration);
  const { alternative_payments, instant_payments } = useSelector((state: GlobalState) => state.configuration.walletConfiguration.sections);

  const paymentMethodsLabel = useTranslation("MANAGE_PAYMENT_METHODS");
  const backLabel = useTranslation(isAdding ? "BACK_BUTTON" : "BALANCE_BALANCE");

  const CVVRecordData: CVVRecord | null = EncryptedCVVManagerHelper.getRecordBy(paymentMethod.id);
  const isMobile = mobileAndTabletCheck();
  const isMobileMode = isModalConfigured === false && isMobile;

  if (!hasCards) {
    dispatch(setManagePayments(false));
  }

  const onManagePaymentsClick = () => {
    if (!hasCards && currentView === View.CHECKOUT) {
      return
    }

    if (hasCards && currentView === View.CHECKOUT && !managePaymentsShow) {
      if(isMobileMode) {
        dispatch(setManagePayments(true));
      }
      if(!isMobileMode) {
        dispatch(setErrorMessage(""));
        dispatch(handleManagementModal(true));
      }
      dispatch(setMemorizedCard(paymentMethod));
      clickControl();
      return;
    }

    if (managePaymentsShow && isAdding) {
      dispatch(onCancelAdd());
      dispatch(setManagePayments(managePaymentsShow));
      Tracking.trackEvent(Tracking.EventAction.CCManage, "back");
    } else {
      dispatch(setManagePayments(!managePaymentsShow));
      Tracking.trackEvent(Tracking.EventAction.CCManage, "open");
    }
    clickControl(CVVRecordData, paymentMethod)
  };

  const rootClasses = classNames(styles.radioContainer, { [styles.radioContainerRtl]: isRTL });
  const label = useTranslation("CREDIT_CARD_OPTION")
  const checkoutViewNavigation = !isGuest && hasCards && (currentView === View.CHECKOUT)
  const manageViewNavigation = !isGuest && hasCards && isAdding && (currentView === View.MANAGE)
  const addCardViewNavigation = managePaymentsShow && isAdding

  return (
    <div className={rootClasses} data-test-id="credit-cards-option">
      {(!!alternative_payments.length || !!instant_payments.length) && !managePaymentsShow && (
        <div className={classNames(styles.optionWrapper)}>
          <PaymentOption
            label={label}
            value={"credit-card"}
          />
        </div>
      )}
      {(checkoutViewNavigation ||
        addCardViewNavigation ||
        manageViewNavigation) && (
        <MyButton
          onClick={onManagePaymentsClick}
          type="unstyled"
          label={managePaymentsShow ? backLabel : paymentMethodsLabel}
          className={classNames({ [styles.backLabel]: managePaymentsShow, [styles.backLabelIcon]: managePaymentsShow && !isAdding} )}
          css={
            managePaymentsShow && currentView === View.CHECKOUT &&
            (css`
              font-size: ${managePaymentsShow ? theme.text.size.large : theme.text.size.medium};
              text-transform: none;
            ` as any)
          }
          id={"navigationButton"}
        />
      )}
    </div>
  );
};
