import { Configuration } from "../configs/Configuration";
import { ApiUrls } from "./ApiClient";

export const DataDomeOptionsScriptId = "mycheck-data-dome-options";
export const DataDomeTagsJsScriptId = "mycheck-data-dome-tags-js"

export const initDataDomeOptions = () => {
  const ddConfigScript = document.createElement("script");
  const ddjskey = `window.ddjskey = "${Configuration.data_dome_key}"`
  const ddoptions = `window.ddoptions = { ajaxListenerPath: "payments-dev.mycheckapp.com${ApiUrls.addCreditCardPCI}", sessionByHeader: true }`
  ddConfigScript.id = DataDomeOptionsScriptId;
  ddConfigScript.innerHTML = `${ddjskey};\n${ddoptions};`

  document.head.appendChild(ddConfigScript);
}

export const initDateDomeTagsJs = () => {
  const ddTagsJsScript = document.createElement("script");
  ddTagsJsScript.id = DataDomeTagsJsScriptId;
  ddTagsJsScript.src = Configuration.data_dome_tags_js;
  ddTagsJsScript.async = true;

  document.head.appendChild(ddTagsJsScript);
}