import { useSelector } from "react-redux";
import { isWebView } from "../helpers/helpers";

const checkApplePaySupport = (methods: string[]) => {
  const isApplePayExisted = methods?.find((alternative: string) => alternative === "applepay");
  if (isApplePayExisted) {
    return (window.ApplePaySession && window.ApplePaySession.canMakePayments()) || isWebView();
  } else {
    return false;
  }
};

export const useSuitableAlternatives = () => {
  const sps_apm = useSelector((state: GlobalState) => state.configuration.walletConfiguration?.general.alternative_payments_provider?.name) === "sps";
  const alternativePayments = useSelector((state: GlobalState) => state.configuration.walletConfiguration.sections.alternative_payments);
  const acceptedPaymentMethods = useSelector((state: GlobalState) => state.configuration.customWalletConfiguration?.acceptedPaymentMethods);
  const removedAlternativeMethods = useSelector((state: GlobalState) => state.configuration.removedAlternativeMethods);

  const possiblelAlternativePayments = ["applepay", "googlepay", "braintree", "masterpass"];
  const checkIfBraintreeExist = alternativePayments.find((payment: string) => payment === "braintree");
  const checkIfAcceptedPaymentMethodsExist = !!acceptedPaymentMethods && Array.isArray(acceptedPaymentMethods);

  const filtredAcceptedMethods = acceptedPaymentMethods
  ?.filter(
    (payment: string) => possiblelAlternativePayments.indexOf(payment) !== -1
  )
  .filter(
    (payment: string) => checkApplePaySupport(acceptedPaymentMethods) ? payment : payment !== 'applepay'
  );

  const filtredAlternatives = alternativePayments
  .filter((payment: string) =>
  checkApplePaySupport(alternativePayments) ? payment : payment !== "applepay"
  );

  const fixedAlternativesList = () => {
    if ( acceptedPaymentMethods && checkIfAcceptedPaymentMethodsExist) {
      const isBraintreeAccepted = filtredAcceptedMethods.find((method: string) => method === "braintree");
      const isAlternativeAvailableInAcceptedPayments = filtredAlternatives.filter((payment: string) => filtredAcceptedMethods.includes(payment))

      return isBraintreeAccepted && !sps_apm
        ? isAlternativeAvailableInAcceptedPayments
        : isAlternativeAvailableInAcceptedPayments.filter((payment: string) => payment !== "braintree");
    }

    return checkIfBraintreeExist && !sps_apm
      ? filtredAlternatives
      : filtredAlternatives.filter((payment: string) => payment !== "braintree");
  }

  const suitableAlternatives: string[] = [] = fixedAlternativesList();
  return suitableAlternatives?.filter(method => !removedAlternativeMethods.includes(method));
}
