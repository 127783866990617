import { useCallback, useState } from 'react';
import {
  RECAPTCHA_URL,
  RECAPTCHA_V2_PLACEHOLDER_ID,
} from '@internal/constants/recaptchaConstants';
import { useEffect, FC } from 'react';
import useRecaptchaScript from '@internal/hooks/useRecaptchaScript';

export interface ReCaptchaV2Props {
  siteKey: string;
  callback: (token: string) => void;
  expiredCallback: () => void;
  windowObjAppId: string;
}

const RECAPTCHA_V2_SCRIPT_URL = `${RECAPTCHA_URL}/api.js?onload=onRecaptchaLoad&render=explicit`;

const ReCaptchaV2: FC<ReCaptchaV2Props> = ({
  siteKey,
  callback,
  expiredCallback,
  windowObjAppId,
}) => {
  const [isRecaptchaRendered, setIsRecaptchaRendered] = useState(false);

  const SCRIPT_ID = `${windowObjAppId}RecaptchaV2`;
  const {
    removeRecaptchaScriptsIfExist,
    appendRecaptchaScript,
    clearRecaptcha,
    isRecaptchaLoaded,
    setIsRecaptchaLoaded,
  } = useRecaptchaScript({
    scriptId: SCRIPT_ID,
    windowObjAppId,
  });

  const onRecaptchaLoadCallback = useCallback(() => {
    if (!window[windowObjAppId]?.grecaptchaV2) {
      window[windowObjAppId].grecaptchaV2 = { ...window.grecaptcha };
      window[windowObjAppId].grecaptchaV2.ready(() => {
        setIsRecaptchaLoaded(true);
      });
    }
  }, [setIsRecaptchaLoaded, windowObjAppId]);

  const initWalletWindowRecaptchaObj = useCallback(() => {
    if (!window[windowObjAppId]) {
      window.grecaptcha = undefined;
      window[windowObjAppId] = {};
    }
  }, [windowObjAppId]);

  useEffect(() => {
    initWalletWindowRecaptchaObj();
    removeRecaptchaScriptsIfExist();

    if (!window[windowObjAppId]?.grecaptchaV2) {
      appendRecaptchaScript(RECAPTCHA_V2_SCRIPT_URL, onRecaptchaLoadCallback);
    } else if (
      window[windowObjAppId]?.grecaptchaV2 &&
      window[windowObjAppId]?.grecaptchaV2.render
    ) {
      onRecaptchaLoadCallback();
    }

    return () => {
      clearRecaptcha();
      setIsRecaptchaRendered(false);
    };
  }, [
    appendRecaptchaScript,
    clearRecaptcha,
    initWalletWindowRecaptchaObj,
    onRecaptchaLoadCallback,
    removeRecaptchaScriptsIfExist,
    windowObjAppId,
  ]);

  useEffect(() => {
    if (isRecaptchaLoaded && !isRecaptchaRendered) {
      window[windowObjAppId]?.grecaptchaV2.render(RECAPTCHA_V2_PLACEHOLDER_ID, {
        sitekey: siteKey,
        callback: callback,
        'expired-callback': expiredCallback,
      });
      setIsRecaptchaRendered(true);
    }
  }, [
    callback,
    expiredCallback,
    isRecaptchaLoaded,
    isRecaptchaRendered,
    siteKey,
    windowObjAppId,
  ]);

  return <div id={RECAPTCHA_V2_PLACEHOLDER_ID}></div>;
};

export default ReCaptchaV2;
