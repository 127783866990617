import * as React from "react";

import styles from "./AlternativeWalletWrapper.module.scss"
import {CSSProperties, ReactElement} from "react";

type AlternativeWalletWrapperType = {
  children: any,
  style?: CSSProperties,
}

export const AlternativeWalletWrapper: React.FunctionComponent<AlternativeWalletWrapperType> = (
  {
    children,
    style,
  }) => {
  return (
    <div style={style} className={styles.root}>
      {children}
    </div>
  );
};
