import * as React from "react";
import { Checkbox, FormControlLabel, FormGroup } from "@material-ui/core";

type DemoCheckboxType = {
  className?: string
  isChecked: boolean,
  onChange: () => void,
  label: string
}

export const DemoCheckbox: React.FunctionComponent<DemoCheckboxType> = ({ isChecked, onChange, label, className }) => {
  return (
    <FormGroup row className={className}>
      <FormControlLabel
        control={
          <Checkbox
            checked={isChecked}
            onChange={onChange}
            color="primary"
          />
        }
        label={label}
      />
    </FormGroup>
  );
};
