export const defaultTranslation: any = {
    APP_NAME: "WalletUI",
    DIALOG_OK: "OK",
    OR_ADD_CARD_WITH: "Or add card with",
    WE_ACCEPT: "Major Cards Accepted",
    CHECKOUT_CARD_NOT_ADDED: "Card is NOT added",
    CHECKOUT_DELETE_TEXT: "Delete",
    CHECKOUT_PAYPAL: "PayPal",
    CHECKOUT_TEMPORARY_CARD: "Temporary Card",
    EXP_HINT: "MM/YY",
    CVV_HINT: "CVV",
    CARD_CVV_TOOLTIP: "The CVV Number on your credit/debit card is a 3 digit number located on the back of your card.\nOn AMEX you will find a 4 digit number on the front of the card.",
    ZIP_HINT: "ZIP/Postal",
    ADD_CREDIT_CARD: "ADD CREDIT CARD",
    ADD_CARD: "ADD CARD",
    ENDING_IN: "Ending in",
    REMOVE: "Remove",
    PM_ERROR: "We are unable to process this payment method",
    CHECKOUT_CHECKBOX_TV: "Do not store this card",
    CHECKBOX_ADD_CARD: "Add card to my profile",
    CARD_NOT_SUPPORTED: "Card is not supported",
    CARD_NUMBER: "Card Number",
    CARD_NUMBER_TOOLTIP: "Your reservation is safe and secure. Protected with 256-bit SSL Encryption\n",
    MANAGE_PAYMENT_METHODS: "Manage Payment Methods",
    TEXT_BUTTON_CANCEL: "Cancel",
    TEXT_BUTTON_VERIFY: "Verify Card",
    TEXT_BUTTON_VERIFY_CVV: "Verify CVV",
    ARE_YOU_SURE: "Are you sure?",
    YES: "Yes",
    NO: "No",
    OR: "or",
    ERROR_CREDIT_CARD_FIELD: "Please enter a valid credit card number",
    ERROR_EXP_FIELD: "Please enter a valid expiration date",
    ERROR_CVV_FIELD: "Please enter a valid CVV number",
    ERROR_ZIP_FIELD: "Please enter a ZIP code",
    ERROR_FILTER_CARD: "The card is not supported. Please use a different card",
    SETTINGS_DIALOG_MESSAGE: "This app needs permission to use this feature. You can grant them in the app settings.",
    SETTINGS_DIALOG_POSITIVE_BUTTON: "Go to settings",
    SETTINGS_DIALOG_NEGATIVE_BUTTON: "Cancel",
    DIALOG_MASTERPASS_TEXT: "I would like to store payment card data from my Masterpass wallet to be used for payment for this Merchant.",
    DIALOG_MASTERPASS_POSITIVE_BUTTON: "YES",
    DIALOG_MASTERPASS_NEGATIVE_BUTTON: "NO",
    CHECKOUT_TITLE: "Title",
    APPLY_GIFT_CARD: "Apply Gift Card",
    SELECT_YOUR_PAYMENT_LABEL: "Select your payment method:",
    CONFIRM_YOUR_SELECTION: "Confirm Your Selection",
    BALANCE_DETAILS_BUTTON: "Benefits Details",
    BALANCE_DETAILS_TITLE: "Balance Details",
    BALANCE_PROPERTY: "Property",
    BALANCE_AMOUNT: "Amount",
    BALANCE_BALANCE: "Balance",
    BALANCE_EXPIRED: "Expired",
    BALANCE_NO_CARDS: "No Gift Cards",
    AMOUNT_APPLY_GIFTCARD: "Amount to apply",
    AMOUNT_AVAILABLE: "Gift Card Balance",
    CREDIT_CARD_OPTION: "Credit Card",
    BACK_BUTTON: "Back",
    BACK_BUTTON_CHECKOUT: "Return to Checkout",
    OR_CHECKOUT_WITH_LABEL: "OR CHECKOUT WITH",
    CHECKOUT_CHECKBOX_HINT: "Your credit card information will not be saved to your profile. However, it will be stored temporarily and then removed within 24 hours.",
    AUTH_ERROR: "Sorry, the wallet is temporarily unavailable. Please contact us for further support.",
    VERIFY_CVV: 'Verify your cvv',
    VERIFY_CARD_TEXT: 'Please verify your card in order to proceed:',
    CARD_HAS_BEEN_VERIFY: 'Your card has been verified for a transaction:',
    CARD_HAS_BEEN_VERIFY_AND_STORED: "Your card has been verified and stored for future transactions.",
    APPLE_PAY_REDIRECT: 'You will be redirected to Apple Pay\'s website to confirm your use of Apple Pay.',
    HEADER_3DS: `3D Secure Authentication (This may take a few moments...)`,

    PCI_ERROR: 'Unfortunately, the transaction was declined. Please enter a different payment method and try again, or contact our support.',
    PCI_ERROR_01: 'Unfortunately, the transaction was declined. Please contact our support for assistance.',
    PCI_ERROR_02: 'The payment method was declined. Please enter a different payment method and try again.',
    PCI_ERROR_03: 'The payment method was declined. Please enter a different payment method and try again.',
    PCI_ERROR_04: 'The transaction was declined. Please enter a different payment method and try again, or contact your bank for support.',
    PCI_ERROR_05: 'Unfortunately, something went wrong. Please contact our support for assistance.',
    PCI_ERROR_06: 'Unfortunately, something went wrong. Please try again in a few minutes.',
    PCI_ERROR_07: 'Unfortunately, the transaction was declined. Please contact our support for assistance.',
    PCI_ERROR_08: 'Unfortunately, the transaction was declined. Please enter a different payment method and try again, or contact our support.',

    RECAPTCHA_DESCRIPTION: "This site is protected by reCAPTCHA and the Google",
    RECAPTCHA_PRICACY_POLICY: "Privacy Policy",
    RECAPTCHA_TERMS_SERVICE: "Terms of Service",
    AND: "and",
    APPLY: "apply"
};
