import { ON_APPLY_BUCKS, SET_AMOUNT_TO_APPLY, GET_GIFTCARDS, DROP_APPLY_BUCKS } from "./GiftCardTypes";
import { Dispatch } from "redux";

import { ApiClient, ApiUrls } from "../../services/ApiClient";

export const getGiftCards = () => async (dispatch: Dispatch, getState: () => GlobalState) => {
  const accessToken = getState().account.accessToken;
  if (!accessToken) {
    return;
  }

  const response = await ApiClient(ApiUrls.getGiftCards);

  const uniqueBids = [...new Set(response.payment_method_list.map((element: Benefit) => element.business_id))];
  const responseBid = await Promise.all(uniqueBids.map(element => ApiClient(`/users/api/v1/business?accessToken=${getState().account.accessToken}&BID=${element}`)));

  dispatch({
    type: GET_GIFTCARDS,
    benefits: response.payment_method_list,
    balance: response.payment_method_list.reduce((a: number, b: any) => a + b.balance, 0),
    bids: responseBid.reduce((accu, elem) => ({ ...accu, [elem.id]: elem.name }), {})
  });
};

export const onApplyHelloBucks = (value: number | string) => ({ type: ON_APPLY_BUCKS, value });
export const onSetAmountToApply = (value: number | string) => ({ type: SET_AMOUNT_TO_APPLY, value });
export const dropApplyBucks = () => ({type: DROP_APPLY_BUCKS});