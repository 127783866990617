import * as React from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  refreshUser,
  setAccessToken,
  setAuthStatus
} from "../store/account/AccountActions";
import { appIsReady, fetchConfiguration } from "../store/configuration/ConfigurationActions";
import { Loader } from "../components/Loader/Loader";
import { fetchPayments } from "../store/payment/PaymentActions";
import { firebaseService } from "../services/FirebaseService";
import { getMasterpassToken } from "../services/MasterpassService";

import styles from './AppLoader.module.scss';
import { onWeChatComplete } from "../services/WeChatAliPayService";
import { composeBodyStyles } from "../helpers/CreateStyleFromConfig";
import { AuthFail } from "../components/AuthFail/AuthFail";
import { MyCheckWalletService } from "../services/MyCheckWalletService/WalletManager";
import { useDataDome } from "../hooks/useDataDome";

type AppLoaderType = {
  publishableKey: string,
  refreshToken?: string,
  accessToken?: string
}

export const AppLoader: React.FunctionComponent<AppLoaderType> = ({
  children,
  refreshToken,
  accessToken,
  publishableKey
}) => {
  const dispatch = useDispatch();
  const isReady = useSelector((state: GlobalState) => state.configuration.appIsReady);
  const config = useSelector((state: GlobalState) => state.configuration.walletConfiguration);
  const authIsFailed = useSelector((state: GlobalState) => state.account.authIsFailed);

  const isWeChatPending = async () => {
    const urlParams = new URLSearchParams(window.location.search);
    const myParam = urlParams.get("mycheck-transaction-id");

    if (myParam) {
      await onWeChatComplete(myParam);
    }
  };

  useDataDome();
  React.useEffect(() => {
    const onTokenChange = async () => {
      if(refreshToken && !accessToken) {
        await dispatch(refreshUser(refreshToken, publishableKey));
      }
      if (accessToken) {
        dispatch(setAccessToken(accessToken))
      }
      await dispatch(fetchPayments(true));
    };

    const appLoading = async () => {
      try {
        await dispatch(fetchConfiguration(publishableKey));
        await firebaseService.initFirebase();

        await onTokenChange();

        getMasterpassToken();
        isWeChatPending();

        await dispatch(appIsReady());
        MyCheckWalletService.events.ready();
      } catch (err) {
        dispatch(setAuthStatus(true));
        MyCheckWalletService.events.error('AppLoader error', err);
      }
    };

    appLoading();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, publishableKey, refreshToken]);

  if (config) {
    composeBodyStyles(config.general);
  }

  if (authIsFailed){
    return <AuthFail />
  }

  if (!isReady) {
    return (
      <div className={styles.root}>
        <Loader />
      </div>
    );
  }

  return <React.Fragment>{children}</React.Fragment>;
};
