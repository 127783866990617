import * as React from "react";
import { useSelector } from "react-redux";
import { ClassNames } from "@emotion/core";
import Tooltip from "react-tooltip-lite";

import { composeTooltipStyle } from "../../helpers/CreateStyleFromConfig";

import { MyTooltipText } from "./MyTooltipText";

type MyTooltipType = {
  text: string,
  maxWidthTooltip?: number,
}

export const MyTooltip: React.FunctionComponent<MyTooltipType> = ({ text, children, maxWidthTooltip }) => {
  const { general } = useSelector((state: GlobalState) => state.configuration.walletConfiguration);

  const {
    borderWidth,
    borderRadius,
    borderColor,
    backgroundColor,
  } = composeTooltipStyle(general);

  return (
    <ClassNames>
      {({ css }) => {
        const contentClassName = css(`span {
          border-width: ${borderWidth}px;
          border-radius: ${borderRadius}px;
          border-style: solid;
          border-color: ${borderColor};
          z-index: 9999999999 !important;
          max-width: ${maxWidthTooltip}px !important;
        }`);

        return (
          <Tooltip
            arrowSize={7} content={<MyTooltipText text={text} />} direction="down" background={backgroundColor}
            tipContentClassName={contentClassName}
          >
            {children}
          </Tooltip>
        );
      }}
    </ClassNames>
  );
};
