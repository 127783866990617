/** @jsx jsx */

import React, { useState, useEffect, useRef, useImperativeHandle } from "react";
import MaskedInput, { maskArray } from "react-text-mask";
import { jsx } from "@emotion/core";
import { useSelector } from "react-redux";
import classNames from "classnames";

import { MyInputLabel } from "./MyInputLabel";
import { calculatePaddingHorizontal } from "../../helpers/CalculateBorderRadiusPadding";
import { composeInputStyles, composeTextStyle, ERROR_BORDER_COLOR } from "../../helpers/CreateStyleFromConfig";
import { useIsRTL } from "../../hooks/useIsRTL";
import { PlaceholderType, BorderSideType } from "./constants";

import styles from "./MyInput.module.scss";
import { useTranslation } from "../../hooks/useTranslation";

type MyInputType = {
  onCompleted?: () => void,
  onBlur?: (value: React.FocusEvent<HTMLInputElement>) => void,
  onFocus?: (value: React.FocusEvent<HTMLInputElement>) => void,
  onChange: (value: React.ChangeEvent<HTMLInputElement>) => void,
  onKeyDown?: (value: React.KeyboardEvent<HTMLInputElement>) => void,
  id?: string,
  guide?: boolean,
  placeholder?: any,
  className?: string,
  disabled?: boolean,
  hasError?: boolean,
  autoComplete?: string,
  value: string | number,
  placeholderChar?: string,
  placeholderFloating?: any,
  tooltip?: React.ReactNode,
  accessibilityLabel?: string,
  afterIcon?: React.ReactNode,
  beforeIcon?: React.ReactNode,
  isFloatingDisabled?: boolean,
  withCursorCorrection?: boolean,
  mask?: ((value: string) => maskArray) | any[],
  isAfterIconStatic?: boolean,
  autoFocus?: boolean,
  inputmode?: boolean,
}

const _MyInput = (props: MyInputType, ref: any) => {
  const {
    onBlur,
    onFocus,
    onChange,
    onKeyDown,
    onCompleted = () => null,
    id,
    mask,
    guide,
    value,
    tooltip,
    hasError,
    disabled,
    afterIcon,
    beforeIcon,
    placeholder,
    autoComplete,
    isFloatingDisabled,
    accessibilityLabel,
    placeholderFloating,
    withCursorCorrection,
    placeholderChar = "_",
    isAfterIconStatic,
    autoFocus,
    inputmode
  } = props;

  const isRTL = useIsRTL();
  const inputRef = useRef<HTMLInputElement & MaskedInput & any>(null);
  const [isInputFocused, setInputFocused] = useState(false);
  const { general } = useSelector((state: GlobalState) => state.configuration.walletConfiguration);

  useEffect(() => {
    onCompletedHandler();
  }, [value]);

  useImperativeHandle(ref, () => ({
    focus: () => {
      if (mask) {
        inputRef.current!.inputElement.focus();
      } else {
        inputRef.current!.focus();
      }
    }
  }));

  useEffect(() => {
    if (autoFocus) {
      if (mask) {
        inputRef.current!.inputElement.focus();
      } else {
        inputRef.current!.focus();
      }
    }
  }, [autoFocus])

  const onCompletedHandler = () => {
    const isCompleted = value
      .toString()
      .replace(/_/g, "")
      .length === (typeof mask === "function" ? (mask(value.toString()) as []).length : mask?.length);

    if (isCompleted) {
      onCompleted();
    }
  };

  const isValueEmpty = !value.toString().length;
  const placeholderType = general.input.placeholder_animation;
  const isPlacaholderActive = isInputFocused || !isValueEmpty;
  const isPlaceholderFloating = placeholderType === PlaceholderType.floating;

  const {
    borderWidth,
    borderColor,
    borderRadius,
    backgroundColor,
    height,
    boxShadow,
  } = composeInputStyles(general, isPlaceholderFloating && isPlaceholderFloating);
  const isBorderBottom = general.input.border.side === BorderSideType.bottom;
  const errorBorderColor = ERROR_BORDER_COLOR;

  const realBorderWidth = hasError ? borderWidth || 1 : borderWidth;
  const borderStyle = {
    borderColor: hasError ? errorBorderColor : borderColor,
    ...isBorderBottom ? { borderWidth: 0, borderBottomWidth: realBorderWidth } : {},
    ...!isBorderBottom ? { borderWidth: realBorderWidth } : {},
    borderStyle: "solid",
    borderRadius,
  };

  const padding = `0 ${calculatePaddingHorizontal({ height, borderRadius, borderWidth }).paddingHorizontal}`;
  const paddingLeft = calculatePaddingHorizontal({ height, borderRadius, borderWidth }).paddingHorizontal;

  const inputClasses = classNames(styles.input, { [styles.inputRtl]: isRTL });
  const shadowClasses = general.input.shadow
    ? classNames({ [styles.shadow]: boxShadow })
    : null;

  const isContainerFloating = isPlaceholderFloating && isPlacaholderActive && !isFloatingDisabled;

  const inputStyles: any = {
    backgroundColor,
    ...composeTextStyle(general, "three"),
    margin: !beforeIcon && padding,
    height: isContainerFloating ? height / 1.5 : height,
  };

  const onChangeWithCursorCorrection = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const valueLength = value.length;

    if (value && inputRef && inputRef.current) {
      event.target.selectionStart = valueLength;
      event.target.selectionEnd = valueLength;

      setTimeout(() => {
        inputRef.current.inputElement.selectionStart = valueLength;
        inputRef.current.inputElement.selectionEnd = valueLength;
      }, 0);

      onChange(event);
    }
  };

  const handleOnFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    onFocus && onFocus(event);
    setInputFocused(true);
  };

  const handleOnBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    onBlur && onBlur(event);
    setInputFocused(false);
  };

  const renderBeforeIcon = () => beforeIcon && (
    <div css={{ paddingLeft, paddingRight: 32 }} className={styles.beforeIcon}>{beforeIcon}</div>
  );

  const renderAfterIcon = () => {
    if (afterIcon && isAfterIconStatic) {
      const classes = (isPlaceholderFloating && isPlacaholderActive)
        ? classNames(styles.afterIconWrapper, styles.afterIconWrapper_floated)
        : classNames(styles.afterIconWrapper)
      return (
        <div className={classes}>
          <MyInputLabel label={''} tooltip={afterIcon} padding={'0'} />
        </div>
      )
    }
    if (afterIcon && ((isPlaceholderFloating && !isPlacaholderActive) || !isPlaceholderFloating)) {
      return (
        <div className={classNames(styles.afterIconWrapper)}>
          <MyInputLabel label={''} tooltip={tooltip} padding={'0'} />
        </div>
      )
    }
  };

  const renderFloatingPlaceholder = () => {
    const plPadding = beforeIcon ? '0' : padding;
    const margin = isPlacaholderActive ? padding : plPadding;
    const label = isPlacaholderActive ? placeholderFloating : placeholder;
    const floatingStyles = isPlacaholderActive && styles.activePlaceholder;

    return isPlaceholderFloating && !isFloatingDisabled && (
      <label
        htmlFor={id!}
        className={classNames(styles.placeholder, floatingStyles)}
        style={{ ...composeTextStyle(general, "two") }}
        css={{ margin }}
      >
        {isPlacaholderActive && <MyInputLabel label={label} tooltip={tooltip} padding={'0'} />}
        {!isPlacaholderActive && label}
      </label>
    );
  };

  const renderMaskedInput = () => mask && (
    <MaskedInput
      id={id!}
      mask={mask!}
      value={value!}
      guide={guide!}
      keepCharPositions
      css={inputStyles!}
      disabled={disabled!}
      ref={inputRef! as any}
      className={inputClasses!}
      autoComplete={autoComplete!}
      aria-label={accessibilityLabel!}
      placeholderChar={placeholderChar}
      placeholder={!isPlaceholderFloating && placeholder}
      onBlur={handleOnBlur}
      onKeyDown={onKeyDown!}
      onFocus={handleOnFocus}
      onChange={withCursorCorrection ? onChangeWithCursorCorrection : onChange}
      autoFocus={autoFocus}
      inputMode={inputmode ? 'numeric' : ''}
    />
  );

  const renderDefaultInput = () => !mask && (
    <input
      id={id!}
      value={value}
      css={inputStyles}
      disabled={disabled!}
      ref={inputRef as any}
      className={inputClasses}
      autoComplete={autoComplete!}
      aria-label={accessibilityLabel!}
      placeholder={!isPlaceholderFloating && placeholder}
      onBlur={onBlur!}
      onChange={onChange}
      onFocus={handleOnFocus}
      autoFocus={autoFocus}
      inputMode={inputmode ? 'numeric' : ''}
    />
  );

  return (
    <div data-test-id={`${id}-input`} className={classNames(styles.containerWrapper, props.className,)}>
      <div
        css={{
          ...borderStyle,
          height,
          backgroundColor,
        }}
        className={classNames(
          shadowClasses,
          styles.container,
          { [styles.containerFloating]: isContainerFloating }
        )}
      >
        <div className={classNames(styles.inputWrapper)}>
          {renderBeforeIcon()}
          <div className={classNames(styles.inputWrapper, styles.positionUnset)}>
            {renderFloatingPlaceholder()}
            {renderMaskedInput()}
            {renderDefaultInput()}
          </div>
        </div>
      </div>
      {renderAfterIcon()}
    </div>
  );
};

export const MyInput = React.forwardRef(_MyInput);
