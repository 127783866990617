import React from "react";
import ReactDOM from "react-dom";

import "./index.scss";

import { DemoRoot } from "./DemoRoot";

export const DemoRootId = "mycheck-demo-settings";

// @ts-ignore
window.loggerInitialValues = [];
window.mycheck.wallet.on("cardTokenReceived", (value: string) => {
  console.log(`event - token; ${value ? `value - ${value}` : ""}`);
// @ts-ignore
  window.loggerInitialValues = [{ eventName: "cardTokenReceived", value }];
});
const root = document.getElementById(DemoRootId);
if (root) {
  ReactDOM.render(<DemoRoot />, document.getElementById(DemoRootId));
}

