import { Dispatch } from "redux";

import {
  AccountActionTypes,
  SET_AUTH_STATUS,
  SET_USER_TOKENS,
  SET_ACCESS_TOKEN
} from "./AccountTypes";
import { ApiClient, ApiUrls, fetchAPIInstance, } from "../../services/ApiClient";
import { Configuration } from "../../configs/Configuration";
import { Tracking } from "../../services/AnalyticsService/GoogleAnalyticsService";

export const refreshUser = (refreshToken: string, publishableKey: string) => async (dispatch: Dispatch): Promise<void> => {
  try  {
    const response = await ApiClient(ApiUrls.refreshUser, {
      domain: Configuration.api_domain,
      method: "POST",
      body: { publishableKey, refreshToken }
    });


    Tracking.trackEvent(Tracking.EventAction.FlowType, response.userInfo.isGuest ? "guest" : "member");
    
    dispatch(setUserTokens({
      accessToken: response.accessToken,
      refreshToken: response.refreshToken,
      isGuest: response.userInfo.isGuest
    }));


    fetchAPIInstance.token = response.accessToken;
    fetchAPIInstance.retry = false;
  } catch (err) {
    console.log({ error: err })
    dispatch(setAuthStatus(true))
  }
};


export const setUserTokens = ({ accessToken, refreshToken, isGuest }: SetUserTokensParams): AccountActionTypes => ({
  type: SET_USER_TOKENS,
  accessToken,
  refreshToken,
  isGuest
});

export const setAuthStatus = (status: boolean): AccountActionTypes => ({type: SET_AUTH_STATUS, status})

export const setAccessToken = (accessToken: string): AccountActionTypes => ({type: SET_ACCESS_TOKEN, accessToken})

