import * as React from "react";
import { Provider } from "react-redux";

import { store } from "../src/store";
import { Demo } from "./containers/Demo";
import { DemoContext } from "./hooks/DemoContext";

type DemoRootType = {}

export const DemoRoot: React.FunctionComponent<DemoRootType> = (props) => {
  return (
    <Provider store={store}>
      <DemoContext>
        <Demo />
      </DemoContext>
    </Provider>
  );
};
