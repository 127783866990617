import {CreditCardManager, getCard, ToCardResponse} from "../../containers/CreditCards/CreditCardManagerProvider";
import {store} from "../../store";
import {
  fetchTranslationFromServer,
  setCustomConfiguration,
  setPaymentDetails,
  setTranslation
} from "../../store/configuration/ConfigurationActions";
import {setAuthStatus} from "../../store/account/AccountActions";
import {MyCheckWalletInitError} from "./MyCheckWalletErrors";
import {MyCheckWalletAuth} from "./WalletManager";
import { Base64 } from "../ApplePayService/Base64";
import {
  CardDetails,
  EventsCallback,
  Fingerprint,
  InitParams,
  instantPaymentMethod,
  MyCheckAuthConnect,
  MyCheckEventName,
  PaymentDetails,
  Settings,
  SupportedCard,
  SupportedLanguage,
  UserData,
  View
} from "./WalletServiceTypes";
import { generateUUID } from "../GenerateUUID";
import { ApiClient, ApiUrls } from "../ApiClient";
import { getTokenId } from "../SPSService";
import { getMerchantConfig, MerchantsList } from "../../helpers/helpers";
import { setAddCardFields, setFailureAddingCardId, setLoader } from "../../store/payment/PaymentActions";
import { CardFormInvalidError, CardValidationError } from "../../hooks/useCardAdd";
import { setErrorMessage } from "../../store/payment/PaymentActions";
import { CVVRecord, EncryptedCVVManagerHelper } from "../../helpers/EncryptedCVVManager";
import { parsePCIError } from "../../helpers/ParseError";
import { getRecaptchaConfigs } from "../../helpers/getRecaptchaConfigs";
import { create_UUID } from "../../helpers/CreateUUID";
import { resetRecaptchaV2 } from "../../helpers/resetRecaptchaV2";
import { captureMessage } from "@sentry/react";

const getUserId = (accessToken: string): number | null => {
  const decodedAccessToken = Base64.decode(accessToken);
  let userId: RegExpMatchArray | string | null = decodedAccessToken.match(/\"id\":\d+/);
  if (!!userId) {
    userId = userId[0].replace(`"id":`, '').trim();
    return Number(userId);
  }
  return null;
};

export enum WalletEvent {
  Init = 'mycheck.wallet.init',
  Destroy = 'mycheck.wallet.destroy',
}

export interface InitFunctionParams {
  settings: Settings,
  payment_details: PaymentDetails,
}

export interface IWallet {
  init: (params: InitFunctionParams) => void;
  destroy: () => void;
  debug: () =>  void;
  on: (eventName: MyCheckEventName, callback: (param?: any) => void) => void;
  addPaymentMethod: (type?: instantPaymentMethod) => Promise<ToCardResponse | void>;
  makeInstantPayment: () => Promise<void>;
  getCustomConfig: () => Settings;
  get3DSecureSignature: (sps_apm?: boolean) => Promise<Fingerprint>;
  getPaymentMethod: () => Promise<ToCardResponse>;
  getPaymentMethodSelected: () => MyCheckSelectedPaymentType;
  setPaymentDetails: (paymentDetails: PaymentDetails, view: View) => void;
  setAcceptedCreditCards: (cards: SupportedCard[]) => void;
  setLocale: (props: SupportedLanguage) => Promise<void>;
  setView: (view: View) => void;
  setCardIssuer: (cardIssuer: string) => void;
  getPaymentInfo: () => PaymentDetails;
  checkIsCheckoutReady: (details: CardDetails) => Promise<void>;
  cardAddFailureEvent: (error: any, issuer?: string) => Promise<void>;
  setWalletId: (walletId: string) => void;
  getWalletId: () => string;
  getInitParams: () => InitParams;
  submitCardForm: () => void

  events: EventsCallback<(param?: any, param2?: any) => void, MyCheckEventName>;
  views: typeof View;
}

export class Wallet implements IWallet {
  public views = View;
  private paymentInfo: PaymentDetails = {
    amount: "",
    currency: "",
    return_url: ""
  };
  private selectedPaymentMethod: MyCheckSelectedPaymentType = {
    paymentName: null,
    type: "traditional",
  }
  private canCheckoutReadyBeTriggered: boolean = true;
  private cardIssuer: string = "";
  private defaultPaymentDetails: CardDetails = {
    cc_token: null,
    encrypted_cvv: null,
    type: null,
  };

  private walletId: string = "";
  private checkoutReady: boolean = false;

  private initEvent: CustomEvent = new CustomEvent(WalletEvent.Init);
  private destroyEvent: CustomEvent = new CustomEvent(WalletEvent.Destroy);

  private setCustomConfig = (config: Settings | any): void => {
    let settings: Settings = {};
    if (!config) {
      throw new Error("Config object is not passed!");
    }

    for (let key in config) {
      if (config[key] !== undefined && config[key] !== null) {
        settings[key as keyof Settings] = config[key];
      }
    }

    if(settings.acceptedPaymentMethods === null) {
      settings.acceptedPaymentMethods = [];
    }

    store.dispatch(setCustomConfiguration(settings))
  }

  private setAndReturnPaymentMethodSelected = (paymentMethodName: string, type: SelectedPaymentMethod): MyCheckSelectedPaymentType => {
    this.selectedPaymentMethod.paymentName = paymentMethodName;
    this.selectedPaymentMethod.type = type;
    return this.selectedPaymentMethod;
  }
  private onFalseEventHandler = (combinedPaymentDetails: CardDetails) => {
    if (this.canCheckoutReadyBeTriggered) {
      this.canCheckoutReadyBeTriggered = false;
      this.checkoutReady = false;
      return this.events.checkoutReady(false, combinedPaymentDetails);
    }
    return;
  }

  private onTrueEventHandler = (combinedPaymentDetails: CardDetails) => {
    if (!this.canCheckoutReadyBeTriggered) {
      this.canCheckoutReadyBeTriggered = true;
    }
    this.checkoutReady = true;
    return this.events.checkoutReady(true, combinedPaymentDetails);
  }

  public checkIsCheckoutReady = async (paymentsDetails: CardDetails = {}) => {
    const { cc_token, encrypted_cvv } = paymentsDetails;
    const isSPS = store.getState().configuration.walletConfiguration
      && (store.getState().configuration.walletConfiguration.general.creditcard_provider.name === 'sps');
    const cvvActive = store.getState().configuration.walletConfiguration
      && (store.getState().configuration.walletConfiguration.sections.credit_cards.cvv?.active);
    const view = store.getState().configuration.customWalletConfiguration.view;
    const fingerprint: Fingerprint = await this.get3DSecureSignature();

    const isCvvActive = !cvvActive && cvvActive !== undefined && cvvActive !== null ? false : true;

    const { authIsFailed } = store.getState().account;

    const { paymentMethodSelected, managePaymentsShow, managementModal } = store.getState().payment;

    const combinedPaymentDetails: CardDetails = {
      ...this.defaultPaymentDetails,
      ...paymentsDetails,
      type: paymentMethodSelected ? paymentMethodSelected.type : null,
      fingerprint,
    }
    const instantPaymentDetails: CardDetails = {
      ...this.defaultPaymentDetails,
      type: paymentMethodSelected ? paymentMethodSelected.type : null,
      fingerprint,
    }

    if (authIsFailed) {
      return this.onFalseEventHandler(combinedPaymentDetails);
    }

    if (view === View.MANAGE) {
      const emptyPaymentDetails = {...this.defaultPaymentDetails, fingerprint}
      return this.onFalseEventHandler(emptyPaymentDetails);
    }

    if (paymentMethodSelected.type === "alternative") {
      if (!cc_token || managePaymentsShow || managementModal) {
        return this.onFalseEventHandler(combinedPaymentDetails);
      }
      return this.onTrueEventHandler(combinedPaymentDetails);
    }

    if (paymentMethodSelected.type === "instant") {
      return this.onTrueEventHandler(instantPaymentDetails);
    }

    if (managePaymentsShow || managementModal) {
      return this.onFalseEventHandler(combinedPaymentDetails);
    }

    if (!isSPS || (isSPS && !isCvvActive)) {
      if (!cc_token) {
        return this.onFalseEventHandler(combinedPaymentDetails);
      }
      return this.onTrueEventHandler(combinedPaymentDetails);
    }

    if (!encrypted_cvv || !cc_token || !fingerprint) {
      return this.onFalseEventHandler(combinedPaymentDetails);
    }
    return this.onTrueEventHandler(combinedPaymentDetails);
  }

  public cardAddFailureEvent = async (err: any, issuer?: string) => {
    const accessToken = store.getState().account.accessToken!;
    const customConfig = store.getState().configuration.customWalletConfiguration;
    const businessId = store.getState().configuration.businessDetails?.id;
    const providerName = store.getState().configuration.walletConfiguration?.general.creditcard_provider.name;
    const merchantConfigurations = store.getState().configuration.walletConfiguration.merchantConfigurations;

    const receivedMerchantId = providerName === "sps"
      ? getMerchantConfig(MerchantsList.SPS_ECOMMERCE, merchantConfigurations)?.id
      : getMerchantConfig(MerchantsList.THREEC, merchantConfigurations)?.id;

    const data: any = {
      user_id: getUserId(accessToken),
      business_id: customConfig.businessId || businessId,
      merchant_id: customConfig.merchant_id || receivedMerchantId,
      error_code : err.code,
      error_info : err.info,
      error_message : err.message,
      card_issuer: issuer ? issuer : this.cardIssuer,
    };
    if (err.errorId) {
      data.error_category = err.errorId.slice(0, 2);
    }
    if (err.uniqueErrorIdentifier) {
      data.error_category = err.uniqueErrorIdentifier.slice(0, 2);
    }

    this.events.onAddCardFailure('Card add failure', data);
  }

  public sentryErrorEventMiddleware = (message: string, error?: unknown) => {
    const initialParams = this.getInitParams();
    const initialAuthParams = MyCheckWalletAuth.getInitialParams() as MyCheckAuthConnect;
    const account = store.getState().account

    captureMessage(message, { 
      level: 'error',
      extra: {
        errorObj: error,
        initialParams: JSON.stringify(initialParams, null, 2),
        initialAuthParams: initialAuthParams,
        accountReduxState: account,
      }
    });

    return message;
  }

  public setCardIssuer = (value: string) => {
    this.cardIssuer = value;
  }

  public addPaymentMethod = (type: instantPaymentMethod | undefined): Promise<ToCardResponse | void> => {
    return Promise.resolve(undefined);
  }

  public debug = (): void => {
    const config = this.getCustomConfig()
    const currentDebug = config.debug

    if (currentDebug) {
      console.log('%c [debug disabled]', 'color: #f44336')
    } else {
      console.log('%c [debug enabled]', 'color: #8fce00')
    }

    store.dispatch(setCustomConfiguration({...config, debug: !currentDebug}))
  }

  public destroy = (): void => {
    this.setWalletId("");
    dispatchEvent(this.destroyEvent);
  }

  public events: EventsCallback<(param?: any, param2?: any) => void, MyCheckEventName> = {
    cardTokenReceived: (token: string) => token,
    error: (reason: string, error?: unknown) => this.sentryErrorEventMiddleware(reason, error),

    addCardFormReady: (isValid: boolean) => isValid,
    giftCardSelected: () => null,
    init: () => null,
    paymentCompleted: () => null,
    paymentMethodRemoved: () => null,
    paymentMethodSelected: (paymentMethodName: string, type: SelectedPaymentMethod) =>
      this.setAndReturnPaymentMethodSelected(paymentMethodName, type),
    ready: () => null,
    addUserInformation: (data: UserData) => window.mycheck.auth.setUserInformation(data),
    encryptedCvvReceived: (encryptedCvv: string) => encryptedCvv,
    checkoutReady: (status: boolean, paymentDetails: CardDetails) => ({status, paymentDetails}),
    onAddCardFailure: (reason, error) => this.sentryErrorEventMiddleware(reason, error),
  };

  public getPaymentInfo = () => this.paymentInfo;

  public get3DSecureSignature = async (sps_apm?: boolean): Promise<Fingerprint> => {
    const providerName = store.getState().configuration.walletConfiguration.general.creditcard_provider.name;
    const { ip } = await ApiClient(ApiUrls.get3dsSignature());
    const isProviderMycheck = providerName === "mycheck";
    const date = new Date();

    const data: Fingerprint = {
      ip,
      screenHeight: window.innerHeight,
      screenWidth: window.innerWidth,
      timezoneOffset: date.getTimezoneOffset(),
      browserAgent: navigator.userAgent,
      walletSdkSessionId: this.getWalletId(),
    };

    if(sps_apm || isProviderMycheck) {
      delete data.walletSdkSessionId
    }

    return data;
  }

  public getCustomConfig = (): Settings => {
    return store.getState().configuration.customWalletConfiguration;
  }

  public getPaymentMethod = async (): Promise<ToCardResponse> => {
    return await getCard();
  }

  public getPaymentMethodSelected = (): MyCheckSelectedPaymentType => {
    return this.selectedPaymentMethod;
  }

  public init = (params: InitFunctionParams): void => {
    const {settings, payment_details} = params;
    const publishableKey = MyCheckWalletAuth.getInitialParams()?.publishableKey;

    if (settings.confirm_button && settings.confirm_button.display === false) {
      delete settings.confirm_button;
      this.events.error("The parameter 'confirm_button display' can only accept true");
    }

    this.destroy();
    this.setCustomConfig(settings);
    this.setPaymentDetails(payment_details, settings.view);

    this.setWalletId(generateUUID());

    if (!publishableKey) {
      throw MyCheckWalletInitError;
    }
    dispatchEvent(this.initEvent);
  }

  public makeInstantPayment = async (): Promise<void> => {
    const { paymentOption } = store.getState().payment
    const type = paymentOption.toLowerCase()
    let data

    if (type === "payconiq" || type === "wechat" || type === "alipay") {
      const amount = this.paymentInfo.amount;
      const currency = this.paymentInfo.currency;

      if (!amount || !currency) {
        console.error({
          message: "One of required parameters 'amount' or(and) 'currency' is missing!",
          fields: {
            amount,
            currency,
          }
        });
        console.error('Please use "setPaymentDetails()" method to set the required parameters');
        this.events.error("One of parameters 'amount' or(and) 'currency' is missing!", {
          amount,
          currency,
        });
        return;
      }
      data = await this.addPaymentMethod(paymentOption as instantPaymentMethod)
    }
    if (!data) {
      console.error('You don\'t have the relevant parameters to trigger an instant payment');
      this.events.error('You don\'t have the relevant parameters to trigger an instant payment');
    }
  }

  public on = (eventName: MyCheckEventName, callback: (reason?: any, error?: any) => void): void => {
    this.events[eventName] = (...params) => { 
      if(eventName === 'error' || eventName === 'onAddCardFailure') {
        this.sentryErrorEventMiddleware(...params)
      }
      callback(...params)
    }
  }
  

  public setAcceptedCreditCards = (cards: SupportedCard[]): void => {
    if (!Array.isArray(cards) || cards.some(card => typeof card !== "string")) {
      return this.events.error("Wrong parameter being passed. Accepts: strings array");
    }

    const cardsToLowerCase = cards
      .map((card: string) => card.toLowerCase())
      .map((card: string) =>
        card === "tr" ? card.toUpperCase() : card
      ) as SupportedCard[];

    const uniqueCards = [...new Set(cardsToLowerCase)];
    const filteredTypesCard = uniqueCards.filter((card: SupportedCard) =>
      Object.values(SupportedCard).includes(card)
    );
    const config = store.getState().configuration.customWalletConfiguration;
    config.acceptedCreditcards = filteredTypesCard;
    store.dispatch(setCustomConfiguration(config));
  }

  public setLocale = async (props: SupportedLanguage): Promise<void> => {
    const config = store.getState().configuration.customWalletConfiguration;
    config.locale = props;
    store.dispatch(setCustomConfiguration(config));
    const publishableKey = MyCheckWalletAuth.getInitialParams()?.publishableKey

    const response = await fetchTranslationFromServer(publishableKey, props);
    if (response) {
      store.dispatch(setTranslation(response.metadata.isRTL, response.translations));
    }
  }

  public setPaymentDetails = (paymentDetails: PaymentDetails, view: View = View.CHECKOUT): void => {
    if (!paymentDetails && view === View.CHECKOUT) {
      this.events.error('Payment details object is not passed!', { paymentDetails })
      store.dispatch(setAuthStatus(true));
      return;
    }

    const { amount, currency, return_url = "", callback_url = "", metadata = "", orderId = "" } = paymentDetails || {};

    const checkAmount = (amount: any): boolean => {
      if (amount === 0 || /^0+(\.0+)?$/.test(amount)) {
        return false;
      } else if (typeof amount === 'number' || !isNaN(Number(amount))) {
        return false;
      } else {
        return true;
      }
    };

    const formattingCurrencyText = (currency: string) => {
      if (checkAmount(amount) && currency) {
        return "";
      }
      if (checkAmount(amount) && !currency) {
        return ", currency";
      }
      return "currency"
    }

    if ((checkAmount(amount) || !currency) && view === View.CHECKOUT) {
      this.events.error(`setPaymentDetails: missing required parameters: ${checkAmount(amount) ? 'amount' : ''}${formattingCurrencyText(currency)}`)
      store.dispatch(setAuthStatus(true))
      return
    }
    if (metadata) {
      let transformedData = ''
      for (let [key, value] of Object.entries(metadata)) {
        transformedData += `\"${key}\": ${value},`
      }
      this.paymentInfo.metadata = `{${transformedData.substring(0, transformedData.length - 1)}}`;
    }
    this.paymentInfo = {
      ...this.paymentInfo,
      currency,
      amount,
      return_url,
      callback_url,
      orderId,
    }

    const newDetails = { ...this.paymentInfo };

    for (const key in newDetails) {
      if(!newDetails[key as keyof PaymentDetails]) {
        delete newDetails[key as keyof PaymentDetails]
      }
    }
    store.dispatch(setPaymentDetails(newDetails));
  }

  public setView = (view: View): void => {
    const config = store.getState().configuration.customWalletConfiguration
    if (view.toUpperCase() === View.MANAGE) {
      config.view = View.MANAGE
      store.dispatch(setCustomConfiguration(config))
    } else {
      config.view = View.CHECKOUT
      store.dispatch(setCustomConfiguration(config))
    }
  }

  public setWalletId = (walletId: string) => {
    this.walletId = walletId;
  }

  public getWalletId = () => {
    return this.walletId;
  }

  public getInitParams = () => {
    const initParams = {
      settings: this.getCustomConfig(),
      payment_details: {
        currency: this.paymentInfo.currency,
        amount: this.paymentInfo.amount,
        return_url: this.paymentInfo.return_url,
        callback_url: this.paymentInfo.callback_url,
        metadata: this.paymentInfo.metadata,
        orderId: this.paymentInfo.orderId,
      }
    }
    for (const key in initParams.payment_details) {
      if(!initParams.payment_details[key as keyof PaymentDetails]) {
        delete initParams.payment_details[key as keyof PaymentDetails]
      }
    }
    return initParams;
  }

  public submitCardForm = async () => {
    const state = store.getState();

    const addCardFields = state.payment.addCardFields as CardFormFieldsValue;
    const accessToken = state.account.accessToken;
    const customConfig = state.configuration.customWalletConfiguration;
    const publishableKey = state.configuration.publishableKey;
    const merchantConfigurations = state.configuration.walletConfiguration.merchantConfigurations;
    const providerName = state.configuration.walletConfiguration?.general.creditcard_provider.name;
    const acceptedCards = state.configuration.walletConfiguration.general.supported_card_types;
    const isManagementModal = state.payment.managementModal;
    const currentPayment = state.payment.currentPayment;
    const confirmButton = state.configuration.walletConfiguration.sections.credit_cards.confirm?.button;
    const cvvActive = state.configuration.walletConfiguration.sections.credit_cards.cvv?.active
    const alternativePayments = state.configuration.walletConfiguration.sections.alternative_payments;
    const customConfigButton = state.configuration.customWalletConfiguration.confirm_button;
    const hasManageOpen = state.payment.managePaymentsShow;

    const merchantConfig = getMerchantConfig(MerchantsList.SPS_ECOMMERCE, merchantConfigurations) || {};
    const recaptchaConfigs = getRecaptchaConfigs();

    const isSps = providerName === "sps";
    const checkFields = !!Object.keys(addCardFields).length;
    const CVVRecordData: CVVRecord | null = EncryptedCVVManagerHelper.getRecordBy(currentPayment?.id);
    const isCvvActive = !cvvActive && cvvActive !== undefined && cvvActive !== null ? false : true;

    const onSpsCardAdd = async () => {
      const merchantId = customConfig.merchant_id ? customConfig.merchant_id : merchantConfig.id;

      const { data: { accessToken } } = await ApiClient(ApiUrls.fetchSPSMerchantConfiguration(publishableKey, merchantId));
      getTokenId(merchantConfig.settings.clientKey, addCardFields?.cardNumber, addCardFields?.monthYear, accessToken);

      if (recaptchaConfigs.isRecaptchaEnabled) {
        CreditCardManager.setRecaptchaToken(addCardFields?.recaptchaToken || null);
      }

      CreditCardManager.setZip(addCardFields?.zip);
      CreditCardManager.setEncryptedCvv({ clientKey: merchantConfig.settings.client_key, cvv: addCardFields?.cvv, accessToken });
      CreditCardManager.setIsSingleUse(addCardFields?.isSingleUse);
    }

    if (customConfigButton && customConfig.view === View.CHECKOUT) {
      this.events.error("function submitCardForm is not available when wallet was initiated with confirm_button true");
      return "function submitCardForm is not available when wallet was initiated with confirm_button true";
    }

    if ((confirmButton || confirmButton === undefined) && customConfig.view === View.CHECKOUT) {
      this.events.error("the wallet_v3 config is not setup for confirm button = false");
      return "the wallet_v3 config is not setup for confirm button = false";
    }

    if (
      customConfig.view === View.MANAGE ||
      isManagementModal ||
      hasManageOpen
    ) {
      this.events.error("function submitCardForm is not available in manage view");
      return "function submitCardForm is not available in manage view";
    }

    if (isCvvActive) {
      this.events.error("function submitCardForm is not available when cvv active = true");
      return "function submitCardForm is not available when cvv active = true";
    }

    if (!!alternativePayments.length) {
      this.events.error("function submitCardForm is not available when wallet has alternative_payments methods");
      return "function submitCardForm is not available when wallet has alternative_payments methods";
    }

    if (this.checkoutReady) {
      this.checkIsCheckoutReady({
        cc_token: currentPayment.ccToken,
        encrypted_cvv: CVVRecordData?.encryptedCVV
      });
      return "checkoutReady is already true";
    }

    if (!this.checkoutReady && currentPayment.isDefault) {
      this.canCheckoutReadyBeTriggered = true;
      this.checkIsCheckoutReady({
        cc_token: currentPayment.ccToken,
        encrypted_cvv: CVVRecordData?.encryptedCVV || null,
      });
      return "CVV of current card is missing"
    }

    if (!checkFields) {
      this.events.error("error - cannot add card, addCardFormReady is false");
      return "error - cannot add card, addCardFormReady is false";
    }

    if (recaptchaConfigs.isRecaptchaEnabled && !addCardFields?.recaptchaToken) {
      store.dispatch(setErrorMessage(CardFormInvalidError.info));
      this.events.error("captcha token not received");
      return CardFormInvalidError.info;
    }

    if (
      (customConfig &&
        customConfig.acceptedCreditcards &&
        !customConfig.acceptedCreditcards.includes(addCardFields?.cardType)) ||
      !acceptedCards.includes(addCardFields?.cardType)
    ) {
      store.dispatch(setErrorMessage(addCardFields?.errorLabel));
      CreditCardManager.onGetCardFailure(CardFormInvalidError);
      return CardFormInvalidError.info;
    }

    try {
      store.dispatch(setLoader(true));
      if (isSps) {
        await onSpsCardAdd();
      }
      store.dispatch(setAddCardFields({}));
      return "Card is submitting";
    } catch (err) {
      // @ts-ignore
      store.dispatch(setErrorMessage(parsePCIError(err.uniqueErrorIdentifier) || addCardFields?.errorLabel));
      CreditCardManager.onGetCardFailure(CardValidationError);
      // @ts-ignore
      if (err.uniqueErrorIdentifier) {
        this.cardAddFailureEvent(err);
      }
      if (recaptchaConfigs.isRecaptchaEnabled) {
        store.dispatch(setFailureAddingCardId(create_UUID()));
      }
      resetRecaptchaV2();
      store.dispatch(setLoader(false));
      return "Card submit failed";
    }
  }
}
