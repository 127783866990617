import * as React from "react";
import { useSelector } from "react-redux";
import classNames from "classnames";

import { InstantPaymentsName } from "./InstantPaymentsName";
import { WeChatAliPayService } from "../../services/WeChatAliPayService";
import { Tracking } from "../../services/AnalyticsService/GoogleAnalyticsService";

import { Container } from "../../components/Container/Container";
import { MyText } from "../../components/MyText/MyText";

import { useIsRTL } from "../../hooks/useIsRTL";

import styles from "./InstantPaymentsDisplay.module.scss";
import { PayconiqService } from "../../services/Payconiq/PayconiqService";

type InstantPaymentsDisplayType = {
  instantPayments?: string[]
}

export const InstantPaymentsDisplay: React.FunctionComponent<InstantPaymentsDisplayType> = ({ instantPayments }) => {
  React.useEffect(() => {
    Tracking.trackEvent(Tracking.EventAction.Section, "Instant Payment Methods");
  }, []);

  const isRTL = useIsRTL();
  const currentPaymentOption = useSelector((state: GlobalState) => state.payment.paymentOption);

  const renderWallet = (element: keyof typeof InstantPaymentsName, i: number) => {
    switch (element.toLowerCase()) {

      case "alipay":
        return <WeChatAliPayService key={i} type="AliPay" />;

      case "wechat":
        return <WeChatAliPayService key={i} type="WeChat" />;

      case "payconiq":
        return <PayconiqService key={i} />;

      default:
        return null;
    }
  };

  const rootStyle = classNames(styles.wrapper, { [styles.wrapperRtl]: isRTL });
  const containerStyle = classNames(styles.container, { [styles.containerRtl]: isRTL });
  return (
    <div>
      <div className={rootStyle}>
        {instantPayments?.map((element: any, key: number) => renderWallet(element, key))}
      </div>
      {Object.keys(InstantPaymentsName).includes(currentPaymentOption.toLowerCase()) && (
        <Container className={containerStyle}>
          <MyText type="three">After clicking checkout, you will be redirected to {currentPaymentOption}'s website to complete the payment.</MyText>
        </Container>
      )}
    </div>
  );
};

