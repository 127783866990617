import * as React from "react";
import { MyDisplayCardInput } from "../../components/MyDisplayCardInput/MyDisplayCardInput";
import { useSelector } from "react-redux";
import { acceptedCardsName } from "../AcceptedCards/AcceptedCardNames";

import styles from "./CreditCards.module.scss";
import { MyText } from "../../components/MyText/MyText";
import { useTranslation } from "../../hooks/useTranslation";
import { useCVVConfirmationShow } from "../../hooks/useCVVConfirmationShow";

type ManageCreditCardsType = {}

export const DisplaySelectedCard: React.FunctionComponent<ManageCreditCardsType> = () => {

  const { cards, currentPayment } = useSelector((state: GlobalState) => state.payment);  
  const isGuest = useSelector((state: GlobalState) => state.account.isGuest);

  const value = cards.find(e => e.isDefault)! || currentPayment;

  const verifyLabel = useTranslation("CARD_HAS_BEEN_VERIFY");
  const isCvvConfirmationShown = useCVVConfirmationShow();

  let paymentMethodValue = value.last4Digits;
  if (value.issuerFull === 'paypal' && value.externalId) {
    paymentMethodValue = value.externalId;
  }

  if (value.source === 'APPLE_PAY') {
    paymentMethodValue = "";
  }

  return (
    <React.Fragment>
      <MyText type={"four"} className={styles.cardVerifyText}>
        {!isCvvConfirmationShown && verifyLabel}
      </MyText>
      <MyDisplayCardInput
        className={styles.root}
        id={value.id} value={paymentMethodValue} isSingleUse={value.isSingleUse} isRemoveAvailable={isGuest}
        issuer={value.issuerFull.toLowerCase() as keyof typeof acceptedCardsName}
        source={value.source}
        data-test-id="display-selected-card-input"
      />
    </React.Fragment>
  );
};
