import { TransactionType, TransactionUrlType } from "./3DSServiceTypes";
import { store } from "../../store";
import { fetchPayments, resetCVVForCard } from "../../store/payment/PaymentActions";
import { EncryptedCVVManagerHelper } from "../../helpers/EncryptedCVVManager";
import { ApiClient, ApiUrls } from "../ApiClient";
import { Configuration } from "../../configs/Configuration";
import { ON_3DS_AUTHORIZE_COMPLETED, SET_3DS_STATUS} from "../../store/payment/PaymentTypes";
import { createTimestampValues, parseTimestamp6 } from "../../helpers/parseTimestamp6";
import { MyCheckWalletService } from "../MyCheckWalletService/WalletManager";
import { generateRandomNumber } from "../../helpers/helpers";
import { firebaseService } from "../FirebaseService";

let refTransactionListener: any;
let transactionId: string = "";
let prevTransactionValue: any = null;
let is3DSModalOpen: boolean = false;
let currentToken: string = "";
let timestamp: string = "";
let canCancelBeTriggered: boolean = true;

const comparisonTransactionObjects = (obj1: any, obj2: any) => {
  let previousValues: string[] = [];
  let changedValues: string[] = [];
  for (let key in obj2) {
    if (!obj1) {
      changedValues = [...changedValues, ` ${key}: ${obj2[key]}`]
    }
    if (obj1) {
      if (obj2[key] !== obj1[key]) {
        previousValues = [...previousValues, ` ${key}: ${obj1[key] || null}`]
        changedValues = [...changedValues, ` ${key}: ${obj2[key]}`]
      }
    }
  }
  return { previousValues, changedValues };
}

const resetCVVRecord = () => {
  const { id } = store.getState().payment.currentPayment;
  const CVVRecord = EncryptedCVVManagerHelper.getRecordBy(id);
  EncryptedCVVManagerHelper.removeRecord(CVVRecord);
  store.dispatch(resetCVVForCard());
}

const checkIfCVVwasAdded = () => {
  const { id } = store.getState().payment.currentPayment;
  const CVVRecord = EncryptedCVVManagerHelper.getRecordBy(id);
  if (!canCancelBeTriggered && CVVRecord) {
    canCancelBeTriggered = true;
  }
}

const addLogInformation = (information: any, token: string): void => {
  const info = `${information}, log ID: ${generateRandomNumber()}`
  const defaultDatabase = firebaseService.getDatabase();
  const walletSdkSessionId = MyCheckWalletService.getWalletId();
  const providerName = store.getState().configuration.walletConfiguration.general.creditcard_provider.name;
  const onProviderPath = providerName === "sps" ? `sessions/${walletSdkSessionId}/tokens/${token}` : token;
  defaultDatabase.ref(`3DS/${onProviderPath}/logs`).push(info);
}

const readFirstTimestamp = (token: string): Promise<string | null> => new Promise(async resolve => {
  const defaultDatabase = firebaseService.getDatabase();
  const walletSdkSessionId = MyCheckWalletService.getWalletId();
  const providerName = store.getState().configuration.walletConfiguration.general.creditcard_provider.name;
  const onProviderPath = providerName === "sps" ? `sessions/${walletSdkSessionId}/tokens/${token}` : token;
  const refTimestamp = defaultDatabase.ref(`3DS/${onProviderPath}/timestamp`);

  refTimestamp.once("value", function (snapshot: any) {
    const timestampValues = snapshot.val();
    if (timestampValues) {
      resolve(timestampValues.created_at);
    } else {
      resolve(null);
    }
  })
})

const recordTimestamp = async (token: string) => {
  const defaultDatabase = firebaseService.getDatabase();
  const walletSdkSessionId = MyCheckWalletService.getWalletId();
  const providerName = store.getState().configuration.walletConfiguration.general.creditcard_provider.name;
  const onProviderPath = providerName === "sps" ? `sessions/${walletSdkSessionId}/tokens/${token}` : token;
  timestamp = parseTimestamp6();

  const createdTimestamp = await readFirstTimestamp(token);
  const timestamp3DS = createTimestampValues(createdTimestamp, timestamp);

  defaultDatabase.ref(`3DS/${onProviderPath}/timestamp`).set(timestamp3DS);
}

const checkLastTransactionId = (transactionValue: any, token: string) => {
  const newTransactionValue = { ...transactionValue };
  const transactionKey = Object.keys(newTransactionValue)[0];
  const transactionObject = newTransactionValue[transactionKey];

  const walletSdkSessionId = MyCheckWalletService.getWalletId();

  const finalizeTransaction = () => {
    delete transactionObject.last_transaction_id;
    MyCheckWalletService.events.paymentCompleted(transactionObject);
    recordTimestamp(token);
    addLogInformation(`fired event of paymentCompleted with walletSdkSessionId: ${walletSdkSessionId} at: ${timestamp}`, token);

    recordTimestamp(token);
    addLogInformation(`after3DsComplete with walletSdkSessionId: ${walletSdkSessionId} at: ${timestamp}`, token);

    resetCVVRecord();

    newTransactionValue[transactionKey] = {
      ...transactionObject,
      last_transaction_id: transactionObject.transaction_id,
    }
    return newTransactionValue;
  }

  if (transactionObject.last_transaction_id !== transactionObject.transaction_id) {
    finalizeTransaction();
  }

  return newTransactionValue;
}

export const add3DsTokenListener = (token: string): Promise<TransactionUrlType> => new Promise(async resolve => {
  const defaultDatabase = firebaseService.getDatabase();
  const walletSdkSessionId = MyCheckWalletService.getWalletId();
  const providerName = store.getState().configuration.walletConfiguration.general.creditcard_provider.name;
  const onProviderPath = providerName === "sps" ? `sessions/${walletSdkSessionId}/tokens/${token}` : token;
  const refUrl = defaultDatabase.ref(`3DS/${onProviderPath}/url`);

  if(currentToken !== token) {
    recordTimestamp(token);
    addLogInformation(`initialized 3ds flow with walletSdkSessionId: ${walletSdkSessionId} at: ${timestamp}`, token);
    prevTransactionValue = null;
    store.dispatch({ type: SET_3DS_STATUS, status3DS: null });

    currentToken = token;
  }

  if (refTransactionListener) {
    refTransactionListener.off();
  }

  refTransactionListener = defaultDatabase.ref(`3DS/${onProviderPath}/transaction`);
  refTransactionListener.on("value", function (snapshot: any) {
    const transactionValue = snapshot.val();

    if (transactionValue) {
      const transactionObject = Object.values(transactionValue)[0] as TransactionType;
      if (!!comparisonTransactionObjects(prevTransactionValue, transactionObject).changedValues.length) {
        recordTimestamp(token);
        addLogInformation(
          `changed value in transaction 3ds action ${!!comparisonTransactionObjects(prevTransactionValue, transactionObject).previousValues.length ? `from <${comparisonTransactionObjects(prevTransactionValue, transactionObject).previousValues} > ` : ""}
          to <${comparisonTransactionObjects(prevTransactionValue, transactionObject).changedValues} > with walletSdkSessionId: ${walletSdkSessionId} at: ${timestamp}`, 
          token
        );
      }

      transactionId = transactionObject.transaction_id;
      prevTransactionValue = transactionObject;
      checkIfCVVwasAdded();

      if (transactionObject.status === "PENDING") {
        refUrl.on("value", (snapshot: any) => {
          const urlValue = snapshot.val();

          if (urlValue) {
            const transactionUrl = Object.values(urlValue)[0] as TransactionUrlType;
            refUrl.off();
            refUrl.remove();

            const delay = generateRandomNumber(1000, 4000);

            const resolveUrl = () => {
              resolve(transactionUrl);
              is3DSModalOpen = true;
              recordTimestamp(token);
              addLogInformation(`opened modal with walletSdkSessionId: ${walletSdkSessionId} with delay: ${delay}ms at: ${timestamp}`, token);
              store.dispatch({ type: SET_3DS_STATUS, status3DS: transactionObject.status });
            }

            resetCVVRecord();
            recordTimestamp(token);
            addLogInformation(`got 3ds parameters from the wallet: ${encodeURIComponent(JSON.stringify(transactionUrl))} with walletSdkSessionId: ${walletSdkSessionId} at: ${timestamp}`, token);

            if (providerName === "sps") {
              setTimeout(() => {
                resolveUrl();
              }, delay);
            } else {
              resolveUrl();
            }
          }
        });
      }
      if (transactionObject.status === "SUCCESS" || transactionObject.status === "FAILURE") {
        refUrl.off();
        refUrl.remove();
        if (
          !transactionObject.last_transaction_id 
          || transactionObject.transaction_id !== transactionObject.last_transaction_id
        ) {
          recordTimestamp(token);
          addLogInformation(`3DS ${transactionObject.status === "SUCCESS" ? "success" : "failure"}. Challenge ${is3DSModalOpen ? "was" : "was not"} generated with walletSdkSessionId: ${walletSdkSessionId} at: ${timestamp}`, token);
        }
        if(is3DSModalOpen) {
          recordTimestamp(token);
          addLogInformation(`closed modal with walletSdkSessionId: ${walletSdkSessionId} at: ${timestamp}`, token);
          store.dispatch({ type: ON_3DS_AUTHORIZE_COMPLETED });
          is3DSModalOpen = false;
        }

        store.dispatch({ type: SET_3DS_STATUS, status3DS: transactionObject.status });
        refTransactionListener.set(checkLastTransactionId(transactionValue, token));
      };
    }
  });
});

export const checkIfTransactionCompleted = (): Promise<boolean> => new Promise(async (resolve) => {
  refTransactionListener.on("value", function (snapshot: any) {
    const transactionValue = snapshot.val();
    if (transactionValue) {
      const transactionObject = Object.values(transactionValue)[0] as TransactionType;
      if (transactionObject.status === "SUCCESS" || transactionObject.status === "FAILURE") {
        resolve(true);
      }
    }
  })
});

const handleErrorCancel = (errCode: string | number) => {
  const defaultDatabase = firebaseService.getDatabase();
  const walletSdkSessionId = MyCheckWalletService.getWalletId();
  const providerName = store.getState().configuration.walletConfiguration.general.creditcard_provider.name.name;
  const onProviderPath = providerName === "sps" ? `sessions/${walletSdkSessionId}/tokens/${currentToken}` : currentToken;
  const refTransaction = defaultDatabase.ref(`3DS/${onProviderPath}/transaction`);

  refTransaction.once("value", function (snapshot: any) {
    const value = snapshot.val();
    if (value) {
      const transactionObject = Object.values(value)[0] as TransactionType;
      let modifiedTransactionObject = { ...transactionObject };
      delete modifiedTransactionObject.last_transaction_id;

      if (errCode == 2085) {
        modifiedTransactionObject = { ...modifiedTransactionObject, status: "SUCCESS" };
        MyCheckWalletService.events.paymentCompleted(modifiedTransactionObject);
        recordTimestamp(currentToken);
        addLogInformation(`fired event of paymentCompleted with walletSdkSessionId: ${walletSdkSessionId} at: ${timestamp}`, currentToken);

        recordTimestamp(currentToken);
        addLogInformation(`tried closing the challenge with walletSdkSessionId: ${walletSdkSessionId}, but transaction status is already success ${timestamp}`, currentToken);
      }
      if (errCode == 2086) {
        modifiedTransactionObject = { ...modifiedTransactionObject, status: "FAILURE" };
        MyCheckWalletService.events.paymentCompleted(modifiedTransactionObject);
        recordTimestamp(currentToken);
        addLogInformation(`fired event of paymentCompleted with walletSdkSessionId: ${walletSdkSessionId} at: ${timestamp}`, currentToken);

        recordTimestamp(currentToken);
        addLogInformation(`tried closing the challenge with walletSdkSessionId: ${walletSdkSessionId}, but transaction status is already failed ${timestamp}`, currentToken);
      }
    }
  })
}

export const cancel3DsSecurity = async () => {
  const status3DS = store.getState().payment.status3DS;
  if (
    !transactionId
    || !canCancelBeTriggered
    || status3DS && status3DS === 'SUCCESS'
    || status3DS && status3DS === 'FAILURE'
  ) {
    return;
  }

  try{
    canCancelBeTriggered = false;
    await ApiClient(ApiUrls.cancel3DS(transactionId), {
      domain: Configuration.api_domain,
      method: 'POST',
    });
  } catch(err) {
    canCancelBeTriggered = false;
    // @ts-ignore
    handleErrorCancel(err.code);
  }
};
