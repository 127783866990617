export const FETCH_PAYMENTS = "FETCH_PAYMENTS";
export const SET_CURRENT_PAYMENT = "SET_CURRENT_PAYMENT";
export const SET_CURRENT_PAYMENT_OPTION = "SET_CURRENT_PAYMENT_OPTION";
export const SET_MANAGE_PAYMENTS = "SET_MANAGE_PAYMENTS";
export const ON_WE_CHAT = "ON_WE_CHAT";
export const ON_WE_CHAT_COMPLETED = "ON_WE_CHAT_COMPLETED";
export const ON_PAYCONIQ = "ON_PAYCONIQ";
export const ON_PAYCONIQ_COMPLETED = "ON_PAYCONIQ_COMPLETED";
export const ON_ADD_CARD_PRESS = "ON_ADD_CARD_PRESS";
export const ON_CANCEL_ADD = "ON_CANCEL_ADD";
export const SET_DEFAULT_PAYMENT = "SET_DEFAULT_PAYMENT";
export const SET_PENDING_ALTERNATIVE_PAYMENT = "SET_PENDING_ALTERNATIVE_PAYMENT";
export const ON_3DS_AUTHORIZE = "ON_3DS_AUTHORIZE";
export const ON_SPS_3DS_AUTHORIZE = "ON_SPS_3DS_AUTHORIZE";
export const ON_3DS_AUTHORIZE_COMPLETED = "ON_3DS_AUTHORIZE_COMPLETED";
export const SET_CURRENT_CVV = "SET_CURRENT_CVV";
export const SET_PAYMENT_METHOD_SELECTED = "SET_PAYMENT_METHOD_SELECTED";
export const SET_LOADER = "SET_LOADER";
export const SET_ERROR_MESSAGE = "SET_ERROR_MESSAGE";
export const SET_USER_ID = "SET_USER_ID";
export const SET_3DS_STATUS = "SET_3DS_STATUS";
export const HANDLE_MANAGEMENT_MODAL = "HANDLE_MANAGEMENT_MODAL";
export const SET_MEMORIZED_CARD = "SET_MEMORIZED_CARD";
export const SET_SUCCESS_CARD_POPUP = "SET_SUCCESS_CARD_POPUP";
export const SPS_TRANSACTION_COMPLETE = "SPS_TRANSACTION_COMPLETE";
export const SET_SPS_APM_ERROR = "SET_SPS_APM_ERROR";
export const SET_READY_SPS_APM = "SET_READY_SPS_APM";
export const SET_ADD_CARD_FIELDS = "SET_ADD_CARD_FIELDS";
export const SET_FAILURE_ADDING_CARD_ID = "SET_FAILURE_ADDING_CARD_ID";
export const SET_DISPLAY_ALTERNATIVE_DIVIDER = "SET_DISPLAY_ALTERNATIVE_DIVIDER";

type FetchPaymentsAction = { type: typeof FETCH_PAYMENTS, cards: any[] }
type SetCurrentPaymentAction = { type: typeof SET_CURRENT_PAYMENT, card: PaymentMethod }
type SetCurrentPaymentOptionAction = { type: typeof SET_CURRENT_PAYMENT_OPTION, option: string }
type SetManagePaymentsActions = { type: typeof SET_MANAGE_PAYMENTS, value: boolean }
type OnWeChatAction = { type: typeof ON_WE_CHAT, source: any }
type OnWeChatCompletedAction = { type: typeof ON_WE_CHAT_COMPLETED, response: any }
type OnPayconiqAction = { type: typeof ON_PAYCONIQ, source: any }
type OnPayconiqCompletedAction = { type: typeof ON_PAYCONIQ_COMPLETED, response: any }
type OnAddCardPressAction = { type: typeof ON_ADD_CARD_PRESS }
type OnCancelAddAction = { type: typeof ON_CANCEL_ADD }
type SetDefaultPaymentAction = { type: typeof SET_DEFAULT_PAYMENT, id: string }
type SetPendingAlternativePayment = { type: typeof SET_PENDING_ALTERNATIVE_PAYMENT, pending: boolean }
type On3DSAuthorizeAction = { type: typeof ON_3DS_AUTHORIZE, url: string, parameters: any }
type OnSPS3DSAuthorizeAction = { type: typeof ON_SPS_3DS_AUTHORIZE, url: string }
type On3DSAuthorizeCompletedAction = { type: typeof ON_3DS_AUTHORIZE_COMPLETED }
type SetCurrentCvv = { type: typeof SET_CURRENT_CVV, cvv: string | null }
type SetPaymentMethodSelected = { type: typeof SET_PAYMENT_METHOD_SELECTED, selectedPaymentType: MyCheckSelectedPaymentType, resetCurrentPayment?: boolean }
type SetLoader = { type: typeof SET_LOADER, loader: boolean }
type SetErrorMessage = { type: typeof SET_ERROR_MESSAGE, message: string }
type SetUserId = { type: typeof SET_USER_ID, id: string }
type Set3DSStatus = { type: typeof SET_3DS_STATUS, status3DS: string }
type HandleManagementModal = { type: typeof HANDLE_MANAGEMENT_MODAL, state: boolean }
type SetMemorizedCard = { type: typeof SET_MEMORIZED_CARD, card: PaymentMethod | null}
type SetSuccessCardPopup = { type: typeof SET_SUCCESS_CARD_POPUP, state: boolean }
type SetSPSTransactionComplete = { type: typeof SPS_TRANSACTION_COMPLETE, state: boolean }
type SetSpsApmError = { type: typeof SET_SPS_APM_ERROR, error: boolean }
type SetReadySpsApm = { type: typeof SET_READY_SPS_APM, isReady: boolean }
type SetAddCardFields = { type: typeof SET_ADD_CARD_FIELDS, addCardFields: object }
type SetFailureAddingId = { type: typeof SET_FAILURE_ADDING_CARD_ID, id: string }
type SetDisplayAlternativeDivider = { type: typeof SET_DISPLAY_ALTERNATIVE_DIVIDER, displayAlternativeDivider: boolean }

export type PaymentActionTypes =
  FetchPaymentsAction
  | SetCurrentPaymentAction
  | SetCurrentPaymentOptionAction
  | SetManagePaymentsActions
  | OnWeChatAction
  | OnWeChatCompletedAction
  | OnPayconiqAction
  | OnPayconiqCompletedAction
  | OnAddCardPressAction
  | OnCancelAddAction
  | SetDefaultPaymentAction
  | SetPendingAlternativePayment
  | On3DSAuthorizeAction
  | OnSPS3DSAuthorizeAction
  | On3DSAuthorizeCompletedAction
  | SetCurrentCvv
  | SetPaymentMethodSelected
  | SetLoader
  | SetErrorMessage
  | SetUserId
  | Set3DSStatus
  | HandleManagementModal
  | SetMemorizedCard
  | SetSuccessCardPopup
  | SetSPSTransactionComplete
  | SetSpsApmError
  | SetReadySpsApm
  | SetAddCardFields
  | SetFailureAddingId
  | SetDisplayAlternativeDivider
