export enum CardFieldName {
  cardNumber = 'cardNumber',
  cvvNumber = 'cvvNumber',
  expDate = 'expDate',
  zipCode = 'zipCode',
};

export enum PlaceholderType {
  disappearing = 'disappearing',
  floating = 'floating',
};

export enum BorderSideType {
  bottom = 'bottom',
  all = 'all',
};

export enum BorderColorType {
  default = 'default',
  inherit = 'inherit',
}