/** @jsx jsx */

import * as React from "react";
import { useSelector } from "react-redux";
import { jsx } from "@emotion/core";
import classNames from "classnames";

import { MyText } from "../../components/MyText/MyText";
import { useIsRTL } from "../../hooks/useIsRTL";
import { useTranslation } from "../../hooks/useTranslation";

import styles from "./CheckoutTitle.module.scss";

type CheckoutTitleType = {}

export const CheckoutTitle: React.FunctionComponent<CheckoutTitleType> = () => {
  const displayTitle = useSelector((state: GlobalState) => state.configuration.walletConfiguration.sections.title.visible);
  const titleLabel = useTranslation("CHECKOUT_TITLE");
  const isRTL = useIsRTL();
  if (!displayTitle) {
    return null;
  }

  const textClass = classNames({ [styles.rootRtl]: isRTL });
  return (
    <div className={styles.root}>
      <MyText type="two" className={textClass}>{titleLabel}</MyText>
    </div>
  );
};
