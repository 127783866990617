import { store } from '../store'

const log = (...params: any[]) => {
  const isEnabled = store.getState().configuration.customWalletConfiguration.debug

  if (isEnabled) {
    console.log(params)
  }
}

const logStart = (reason: string) => {
  const isEnabled = store.getState().configuration.customWalletConfiguration.debug

  if (isEnabled) {
    console.log(`%c [debug log]: ${reason}`, 'color: #4287f5')
  }
}

const logEnd = () => {
  const isEnabled = store.getState().configuration.customWalletConfiguration.debug

  if (isEnabled) {
    console.log(`%c [debug log end]`, 'color: #4287f5')
  }
}

export const DebugLogger = {
  log,
  logStart,
  logEnd
}
