import * as React from "react";
import { useSelector } from "react-redux";
import classNames from "classnames";

import { MyText } from "../../components/MyText/MyText";

import { useIsRTL } from "../../hooks/useIsRTL";
import { useTranslation } from "../../hooks/useTranslation";

import styles from "./GiftCardsAvailable.module.scss";

type GiftCardsAvailableType = {}

export const GiftCardsAvailable: React.FunctionComponent<GiftCardsAvailableType> = () => {
  const available = useSelector((state: GlobalState) => state.giftCard.balance);
  const isRTL = useIsRTL();

  const rootClasses = classNames(styles.root, { [styles.rootRtl]: isRTL });
  return (
    <div className={rootClasses}>
      <MyText type="four" className={styles.label}>{useTranslation("AMOUNT_AVAILABLE")}</MyText>
      <MyText type="one" className={styles.value}>{available}</MyText>
    </div>
  );
};
