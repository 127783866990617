import * as React from "react";

import { MainSettings } from "./MainSettings";

import { DemoClose } from "../components/DemoClose";
import { Logger } from "./Logger";
import { useSelector } from "react-redux";
import { Functions } from "./Functions";

type DemoType = {}

export const Demo: React.FunctionComponent<DemoType> = ({ children }) => {
  const [isReady, setIsReady] = React.useState(false);
  const walletConfiguration = useSelector((state: GlobalState) => state.configuration.walletConfiguration);

  const [expanded, setExpanded] = React.useState("general")

  // @ts-ignore
  const [events, setEvents] = React.useState<LoggerEvent[]>(window.loggerInitialValues);
  const handleEvent = (name: string) => (...args: any[]) => {
    setEvents(events => [{ type: "event", name, value: args }, ...events]);
    console.log(`event - ${name}; ${args ? `value - ${JSON.stringify(args)}` : ""}`);
  };

  const handleFunction = (name: string, value: string) => {
    setEvents(events => [{ type: "function", name, value }, ...events]);
    console.log(`function - ${name}; ${value ? `value - ${JSON.stringify(value)}` : ""}`);
  };

  const setIsNotReady = () => setIsReady(false);

  React.useEffect(() => {
    setIsReady(!!walletConfiguration);
  }, [walletConfiguration]);

  return (
    <React.Fragment>
      <DemoClose />
      <MainSettings setIsNotReady={setIsNotReady} expanded={expanded} setExpanded={setExpanded} />
      <Functions
        handleEvent={handleFunction} setIsNotReady={setIsNotReady} isReady={isReady} expanded={expanded}
        setExpanded={setExpanded}
      />
      <Logger handleEvent={handleEvent} events={events} expanded={expanded} setExpanded={setExpanded} />
    </React.Fragment>
  );
};
