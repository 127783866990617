import * as React from "react";
import { useSelector } from "react-redux";

import { Container } from "../../components/Container/Container";
import { Divider } from "../../components/Divider/Divider";

import { CreditCardsOption } from "./CreditCardsOption";
import { CardAdd } from "../CardAdd/CardAdd";
import { AcceptedCardsDisplay } from "../AcceptedCards/AcceptedCardsDisplay";
import { AlternativeWalletsDisplay } from "../AlternativeWallets/AlternativeWalletsDisplay";
import { DisplaySelectedCard } from "./DisplaySelectedCard";
import { ManageCreditCards } from "../ManageCreditCards/ManageCreditCards";

import styles from "./CreditCards.module.scss";
import cardStyles from "../CardAdd/CardAdd.module.scss";
import { Tracking } from "../../services/AnalyticsService/GoogleAnalyticsService";
import { useTranslation } from "../../hooks/useTranslation";
import { CvvConfirmation } from "../CvvConfirmation/CvvConfirmation";
import { MerchantsList } from "../../helpers/helpers";
import { EncryptedCVVManagerHelper } from "../../helpers/EncryptedCVVManager";
import { useCVVConfirmationShow } from "../../hooks/useCVVConfirmationShow";
import {CardAddError} from "../CardAdd/CardAddError";
import { View } from "../../services/MyCheckWalletService/WalletServiceTypes";
import { ManageCreditCardModal } from "../ManageCreditCards/ManageCreditCardModal";
import { RecaptchaTerms } from "../../components/RecaptchaTerms/RecaptchaTerms";

type CreditCardsType = {
  showRadioButtons: boolean,
  isCreditCardsVisible: boolean,
  alternatives?: string[]
}

export const CreditCards: React.FunctionComponent<CreditCardsType> = (
    {
      showRadioButtons,
      isCreditCardsVisible,
      alternatives,
    }
  ) => {
  const addCardWithLabel = useTranslation("OR_ADD_CARD_WITH");
  const isCvvConfirmationShown = useCVVConfirmationShow();

  const creditCardProviderName = useSelector((state: GlobalState) => state.configuration.walletConfiguration?.general.creditcard_provider.name);
  const hasCards = useSelector((state: GlobalState) => state.payment.cards.length);
  const isAdding = useSelector((state: GlobalState) => state.payment.isAdding);
  const isGuest = useSelector((state: GlobalState) => state.account.isGuest);

  const hasManageOpen = useSelector((state: GlobalState) => state.payment.managePaymentsShow);
  const currentView = useSelector((state: GlobalState) => state.configuration.customWalletConfiguration.view);
  const currentPayment = useSelector((state: GlobalState) => state.payment.currentPayment);
  const isManagementModal = useSelector((state: GlobalState) => state.payment.managementModal);
  const displayAlternativeDivider = useSelector((state: GlobalState) => state.payment.displayAlternativeDivider);

  const errorMessageLabel = useSelector((state: GlobalState) => state.payment.errorMessage);

  const renderContentCards = () => {
    if (isCreditCardsVisible) {
      return (
        <>
          <CreditCardsOption hasCards={!!hasCards} showRadioButtons={showRadioButtons} isAdding={isAdding} />
          {renderCreditElement()}

          <div className={styles.detailsWrapper}>
            {isCvvConfirmationShown && <CvvConfirmation />}
            <AcceptedCardsDisplay />
            <RecaptchaTerms />
          </div>
        </>
      )
    }
  }

  const renderCreditElement = () => {
    if ((!hasCards || isAdding) && !isManagementModal) {
      return (
        <div className={cardStyles.cardForm}>
          <div data-test-id="iframe-add">
            <CardAdd isSps={creditCardProviderName === 'sps'} />
          </div>
        </div>
      );
    }

    if ((hasManageOpen && !isGuest && !isAdding) || (currentView === View.MANAGE)) {
      return <ManageCreditCards />;
    }

    return <DisplaySelectedCard />;
  };

  const renderError = () => {
    if (!!hasCards && !isAdding) {
      return (
        <div style={{ marginBottom: 24 }}>
          <CardAddError errorMessage={errorMessageLabel} />
        </div>
      )
    }
  }

  React.useEffect(() => {
    if (currentPayment.source === MerchantsList.SPS_ECOMMERCE) {
      EncryptedCVVManagerHelper.calculateExpirationFor(currentPayment.id);
    }
  }, [currentPayment]);

  React.useEffect(() => {
    if (isCreditCardsVisible) {
      Tracking.trackEvent(Tracking.EventAction.Section, "Credit Cards");
    }

    if (alternatives?.length) {
      Tracking.trackEvent(Tracking.EventAction.Section, "Alternative Payment Methods");
    }
  }, []);

  return (
    <Container testId="credit-card-section">
      {renderContentCards()}

      {renderError()}

      {isCreditCardsVisible && alternatives?.length && displayAlternativeDivider ? (
        <Divider label={addCardWithLabel} className={styles.divider} />
      ) : null}

      {!isManagementModal && !!alternatives?.length && (
        <AlternativeWalletsDisplay alternatives={alternatives} />
      )}

      <ManageCreditCardModal />
    </Container>
  );
};
