/** @jsx jsx */

import * as React from "react";
import { useSelector } from "react-redux";
import { jsx } from "@emotion/core";

import { ButtonColorType, composeBtnStyles } from "../../helpers/CreateStyleFromConfig";
import styles from "./MyButton.module.scss";
import classNames from "classnames";

type MyButtonType = {
  type: ButtonColorType,
  label: string,
  onClick?: () => void,
  className?: string,
  useBootstrap?: boolean,
  disabled?: boolean,
  id?: string,
  css?: React.CSSProperties,
  icon?: any
}

export const MyButton: React.FunctionComponent<MyButtonType> = (
  {
    type,
    onClick,
    label,
    className,
    useBootstrap,
    disabled,
    css,
    id,
    icon,
  }) => {
  const { general } = useSelector((state: GlobalState) => state.configuration.walletConfiguration);
  const btnStyle = composeBtnStyles(general, general.button.theme, type, useBootstrap!)
  const disabledCSS = { cursor: 'default', backgroundColor: 'grey', borderColor: '#989898' };

  const onInternalClick = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();

    onClick!();
  };

  const stylesForInput = classNames(styles.button, className && { [className]: true });

  return (
    <button
      disabled={disabled}
      data-test-id={id}
      className={stylesForInput} {...onClick ? { onClick: onInternalClick } : {}}
      css={[disabled ? { ...btnStyle, ...disabledCSS  } : { ...btnStyle } as any, icon && { position: "relative" }, css]}
    >
      {icon && (
        <div className={styles.icon}>{icon}</div>
      )}
      {label}
    </button>
  );
};
