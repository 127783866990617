import * as React from "react";
import { useDispatch, useSelector } from "react-redux";

import { Divider } from "../../components/Divider/Divider";
import { useTranslation } from "../../hooks/useTranslation";
import {
  getAccessTokenFromFB,
  completeTransactionSPS,
  generateAccessTokenSPS,
  injectScriptSPS
} from "../../services/SPSAlternativeService";
import { setSPSTransactionComplete } from "../../store/payment/PaymentActions";
import { MyCheckWalletService } from "../../services/MyCheckWalletService/WalletManager";

import styles from "./SPSAlternative.module.scss";
import classNames from "classnames";
import { useSuitableAlternatives } from "../../hooks/useSuitableAlternatives";

const spsApmID = "sps-alternative-payment";
const apmRootId = "apmRoot";

export const SPSAlternative: React.FunctionComponent = () => {
  const label = useTranslation("OR_CHECKOUT_WITH_LABEL");
  const dispatch = useDispatch();

  const [divider, setDivider] = React.useState(false);
  const [shift, shiftRoot] = React.useState(false);
  const [isTokenGenerated, setTokenIsGenerated] = React.useState(false);

  const transactionCompleted = useSelector((state: GlobalState) => state.payment.spsTransactionComplete);
  const isReady = useSelector((state: GlobalState) => state.payment.spsApmIsReady);
  const spsApmError = useSelector((state: GlobalState) => state.payment.spsApmError);
  const paymentDetails = useSelector((state: GlobalState) => state.configuration.paymentDetails)!;

  const suitableAlternatives = useSuitableAlternatives();
  const alternativesLength = suitableAlternatives?.length;
  const currency = paymentDetails.currency;
  const amount = paymentDetails.amount;
  const orderId = paymentDetails.orderId;

  const loadSPSAlternatives = async () => {
    try {
      await generateAccessTokenSPS();
      getAccessTokenFromFB();
      setTokenIsGenerated(true);
    } catch (err) {
      MyCheckWalletService.events.error('SPSAlternative error', err);
      setTokenIsGenerated(false);
    }
  }

  const destroySpsAlternatives = () => {
    // @ts-ignore
    Shiji.Destroy();
    setTokenIsGenerated(false);
  }

  React.useEffect(() => {
    injectScriptSPS();
  }, [])

  React.useEffect(() => {
    if (transactionCompleted) {
      completeTransactionSPS();
      dispatch(setSPSTransactionComplete(false));
      destroySpsAlternatives();
      return;
    };
    if (isTokenGenerated) {
      destroySpsAlternatives();
      loadSPSAlternatives();
      return;
    }
    if (!isTokenGenerated) {
      loadSPSAlternatives();
      return;
    }
  }, [amount, currency, orderId, transactionCompleted])

  React.useEffect(() => {
    const apmRootElement = document.getElementById(apmRootId);
    if (spsApmError) {
      setDivider(false);
      shiftRoot(false);
      destroySpsAlternatives();
      return;
    }
    if (isReady && !alternativesLength) {
      if (apmRootElement && apmRootElement.clientHeight > 0) {
        setDivider(true);
        shiftRoot(false);
        return;
      }
    }
    if (isReady && alternativesLength) {
      if (apmRootElement && apmRootElement.clientHeight > 0) {
        setDivider(false);
        shiftRoot(true);
        return;
      }
    }
  }, [isReady, alternativesLength, spsApmError])

  if (!isTokenGenerated) {
    return null;
  }

  return (
    <div id={spsApmID}>
      {divider && <Divider label={label}/>}
      <div
        id={apmRootId}
        className={classNames(styles.apmRoot, {
          [styles.apmRootShift]: shift,
        })}
      />
    </div>
  );
};
