import React, { FC } from "react";
import styles from "./Iframe3DSecurity.module.css";
import { SPSTransactionUrlType } from "./3DSServiceTypes";

export const IframeSPS3DSecurityForm: FC<SPSTransactionUrlType> = ({ url }) => {
  const iframeRef = React.useRef<HTMLIFrameElement>(null);

  const onLoad = () => iframeRef.current!.contentWindow!.focus();

  return (
    <iframe
      className={styles.iframe}
      ref={iframeRef}
      title="3dSecure"
      name="3dSecure"
      src={url}
      onLoad={onLoad}
    />
  );
};
