/** @jsx jsx */

import * as React from 'react'
import { InterpolationWithTheme, jsx } from '@emotion/core'

import { Configuration } from '../../configs/Configuration'
import { acceptedCardsName, cardTypes } from '../../containers/AcceptedCards/AcceptedCardNames'

import './assets'

type MyIconType = {
  style?: any,
  type: 'grey_card' | 'lock' | 'info' | 'close' | keyof typeof acceptedCardsName | keyof typeof cardTypes;
}

export const MyIcon: React.FunctionComponent<MyIconType> = ({ style, type }) => {
  const getIcon = () => {
    switch (type) {
      case 'grey_card':
        return `${Configuration.assets}/static/media/grey_card.png`

      case 'lock':
        return `${Configuration.assets}/static/media/lock.svg`

      case 'info':
        return `${Configuration.assets}/static/media/i.svg`

      case 'close':
        return `${Configuration.assets}/static/media/x.png`

      case 'visa':
        return `${Configuration.assets}/static/media/${cardTypes.visa}`

      default:
        return `${Configuration.assets}/static/media/${acceptedCardsName[type]}`
    }
  }

  return (
    <img
      src={getIcon()}
      data-test-id={`${type}-icon`}
      style={(style && style.maxHeight) ? { maxHeight: style.maxHeight } : { maxHeight: 20 }}
    />
  )
}
