import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentPayment, setCurrentPaymentOption, setPaymentMethodSelected } from "../store/payment/PaymentActions";

export function useRevertPaymentSelection() {
  const dispatch = useDispatch()
  const currentOption = useSelector((state: GlobalState) => state.payment.paymentOption)
  const currentPayment = useSelector((state: GlobalState) => state.payment.currentPayment)
  const currentMethod = useSelector((state: GlobalState) => state.payment.paymentMethodSelected)

  const sealedOption = React.useRef<string | null>(null)
  const sealedPayment = React.useRef<PaymentMethod | null>(null)
  const sealedMethod = React.useRef<MyCheckSelectedPaymentType | null>(null)

  const sealed = React.useRef<boolean>(false)

  React.useEffect(() => {
    if (!sealed.current) {
      sealedOption.current = currentOption
      sealedPayment.current = currentPayment
      sealedMethod.current = currentMethod
    }
  }, [currentOption, currentPayment, currentMethod])

  const seal = () => sealed.current = true

  const revert = () => {
    if (sealedOption.current) {
      dispatch(setCurrentPaymentOption(sealedOption.current))
    }

    if (sealedMethod.current) {
      dispatch(setPaymentMethodSelected(sealedMethod.current!.paymentName!, sealedMethod.current!.type!))
    }

    if (sealedPayment.current) {
      dispatch(setCurrentPayment(sealedPayment.current!))
    }

    sealed.current = false
  }

  return { seal, revert }
}
