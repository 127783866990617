import * as React from 'react'
import classNames from 'classnames'

import styles from './MyTouchable.module.scss'

type MyTouchableType = {
  onClick: (e: React.MouseEvent<HTMLElement>) => void,
  className?: string,
  disabled?: boolean,
  tabIndex?: number,
  ariaLabel?: string,
  id: string
}

export const MyTouchable: React.FunctionComponent<MyTouchableType> = ({
                                                                        onClick,
                                                                        children,
                                                                        className,
                                                                        tabIndex,
                                                                        ariaLabel,
                                                                        id
                                                                      }) => {
  const onInternalClick = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault()
    e.stopPropagation()

    onClick(e)
  }

  const classes = classNames(className, styles.touchable)

  return (
    <button
      aria-label={ariaLabel} onClick={onInternalClick} className={classes} tabIndex={tabIndex}
      data-test-id={`${id}-touchable`}
    >
      {children}
    </button>
  )
}
