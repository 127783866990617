import * as React from 'react';
import { useSelector } from "react-redux";
import { DebugLogger } from "../services/DebugLogger";

export const AppLogger: React.FC = (props) => {
  const currentPayment = useSelector((state: GlobalState) => state.payment.currentPayment)
  const currentMethod = useSelector((state: GlobalState) => state.payment.paymentMethodSelected)

  const toDisplayPayment = (payment: PaymentMethod )=> {
    if (!!Object.values(payment).filter(Boolean).length && currentMethod.type !== "instant") {
      return payment
    }

    return null
  }

  React.useEffect(() => {
    DebugLogger.logStart("method changed")
    DebugLogger.log(currentMethod.type, currentMethod.paymentName, toDisplayPayment(currentPayment))
    DebugLogger.logEnd()
  }, [currentPayment, currentMethod])

  return <>{props.children}</>
}
