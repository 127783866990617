export const acceptedCardsName = {
    amazonpay: "ac_amazonpay.png",
    "american-express": "ac_amex.png",
    amex: "ac_amex.png",
    applepay: "ac_applepay.png",
    cirrus: "ac_cirrus.png",
    "diners-club": "ac_diners.png",
    diners: "ac_diners.png",
    discover: "ac_discover.png",
    elo: "ac_elo.png",
    jcb: "ac_jcb.png",
    maestro: "ac_maestro.png",
    mastercard: "ac_mastercard.png",
    paypal: "ac_paypal.png",
    solo: "ac_solo.png",
    switch: "ac_switch.png",
    unionpay: "ac_unionpay.png",
    unknown: "ac_unknown.png",
    visa: "ac_visa.png",
    tr: "ac_tr.png",
};

export const cardTypes = {
  visa: "ct_visa.png"
}
