import * as React from "react";
import { useSelector } from "react-redux";

import { mergeDeep } from "../../src/configs/PrepareConfig";
import { store } from "../../src/store";
import { setAppConfiguration, setCustomConfiguration } from "../../src/store/configuration/ConfigurationActions";
import { View } from "../../src/services/MyCheckWalletService/WalletServiceTypes";

type DemoContextType = {}

const DEFAULT_CONTEXT = {
  publishableKey: "",
  refreshToken: "",
  accessToken: "",

  methods: {
    setPublishableKeyRefreshToken: (pb: string, refresh: string) => {
    },
    setAccessToken: (accessToken: string) => {
    },
    setSection: (section: string, value: any, isIframe?: boolean) => {
    },
    setCustomWalletConfiguration: (path: string, view: View) => {
    }
  }
};

export const ContextObject = React.createContext(DEFAULT_CONTEXT);

const createValue = (key: string, value: any, obj = {}) => {
  const result = obj;
  let arr = key.split(".");
  for (let i = 0; i < arr.length - 1; i++) {
    // @ts-ignore
    obj = obj[arr[i]] = {};
  }
  // @ts-ignore
  obj[arr[arr.length - 1]] = value;
  return result;
};
export const DemoContext: React.FunctionComponent<DemoContextType> = ({ children }) => {
  const [contextState, setContextState] = React.useState(DEFAULT_CONTEXT);
  const configuration = useSelector((state: GlobalState) => state.configuration.walletConfiguration);
  const customWalletConfiguration = useSelector((state: GlobalState) => state.configuration.customWalletConfiguration);

  const setPublishableKeyRefreshToken = (pb: string, refresh: string) => setContextState({
    ...contextState,
    publishableKey: pb,
    refreshToken: refresh
  });

  const setAccessToken = (accessToken: string) => setContextState({
    ...contextState,
    accessToken,
  });

  const setSection = async (path: string, value: any, isIframe?: boolean) => {
    await store.dispatch(setAppConfiguration(mergeDeep({}, configuration, createValue(path, value))));
  };

  const setCustomWalletConfiguration = (path: string, value: any) => store.dispatch(setCustomConfiguration(mergeDeep({}, customWalletConfiguration, createValue(path, value))));

  const methods = {
    setPublishableKeyRefreshToken,
    setSection,
    setCustomWalletConfiguration,
    setAccessToken,
  };

  return (
    <ContextObject.Provider value={{ ...contextState, methods }}>
      {children}
    </ContextObject.Provider>
  );
};


