export default {
  amex: require("./mc_amex.png"),
  applepay: require("./mc_applepay.png"),
  elo: require("./mc_elo.png"),
  diners: require("./mc_diners.png"),
  discover: require("./mc_discover.png"),
  giftcard: require("./mc_giftCard.png"),
  jcb: require("./mc_jcb.png"),
  maestro: require("./mc_maestro.png"),
  mastercard: require("./mc_mastercard.png"),
  paypal: require("./mc_paypal.png"),
  unionpay: require("./mc_union_pay.png"),
  visa: require("./mc_visa.png"),
  amazonpay: require("./mc_amazonpay.png"),
  tr: require("./mc_tr.png"),
};
