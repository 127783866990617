import * as React from "react";

import { Divider } from "../../components/Divider/Divider";
import { useFadeOutWhen } from "../../hooks/useFadeOutWhen/useFadeOutWhen";
import { InstantPaymentsDisplay } from "../InstantPayments/InstantPaymentsDisplay";

import { useTranslation } from "../../hooks/useTranslation";

import styles from "./AlternativePayments.module.scss";

type AlternativePaymentsType = {
  payments?: Array<string>
}

export const AlternativePayments: React.FunctionComponent<AlternativePaymentsType> = ({payments}) => {
  const label = useTranslation("OR_CHECKOUT_WITH_LABEL");

  return (
    <div className={useFadeOutWhen("card-add")} data-test-id="instant-payment-section">
      <Divider label={label} className={styles.row} />
      <InstantPaymentsDisplay instantPayments={payments} />
    </div>
  );
};
