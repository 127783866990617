import React from "react"
import { useTranslation } from "../../hooks/useTranslation";
import { getRecaptchaConfigs } from "../../helpers/getRecaptchaConfigs";
import { MyText } from "../MyText/MyText";
import { useSelector } from "react-redux";
import styles from "./RecaptchaTerms.module.scss";
import classNames from "classnames";

export const RecaptchaTerms = () => {
  const linkColor = useSelector((state: GlobalState) => state.configuration.walletConfiguration.general.button.color.primary);
  const hasCards = useSelector((state: GlobalState) => state.payment.cards.length);
  const isAdding = useSelector((state: GlobalState) => state.payment.isAdding);

  const { isRecaptchaEnabled, isRecaptchaV3 } = getRecaptchaConfigs();
  const isRecaptchaHidden = isRecaptchaEnabled && isRecaptchaV3;
  
  const recaptchaDescription = useTranslation("RECAPTCHA_DESCRIPTION");
  const recaptchaPrivacyPolicy = useTranslation("RECAPTCHA_PRICACY_POLICY");
  const recaptchaTermsService = useTranslation("RECAPTCHA_TERMS_SERVICE");
  const andLabel = useTranslation("AND");
  const applyLabel = useTranslation("APPLY");
  
  const renderPrivacyLink = () => (
    <a href="https://policies.google.com/privacy"
      target="_blank"
      style={{ color: linkColor }}
    >
      {recaptchaPrivacyPolicy}
    </a>
  )
  
  const renderTermsLink = () => (
    <a href="https://policies.google.com/terms"
      target="_blank"
      style={{ color: linkColor }}
    >
      {recaptchaTermsService}
    </a>
  )

  if (!isRecaptchaHidden) {
    return null;
  }

  return (
    <MyText
      type="four"
      className={classNames(styles.recaptchaTerms, {
        [styles.marginBottomRemoved]: !!hasCards && !isAdding,
      })}
    >
      {recaptchaDescription} {renderPrivacyLink()} {andLabel} {renderTermsLink()} {applyLabel}.
    </MyText>
  );
}
