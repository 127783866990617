import * as React from "react";
import { useSelector } from "react-redux";
import classNames from "classnames";

import { Configuration } from "../../configs/Configuration";
import { acceptedCardsName } from "./AcceptedCardNames";

import { MyText } from "../../components/MyText/MyText";

import { useIsRTL } from "../../hooks/useIsRTL";

import styles from "./AcceptedCards.module.scss";
import { useTranslation } from "../../hooks/useTranslation";

type AcceptedCardsDisplayType = {}

export const AcceptedCardsDisplay: React.FunctionComponent<AcceptedCardsDisplayType> = () => {
  const isRTL = useIsRTL();

  const isVisible = useSelector((state: GlobalState) => state.configuration.walletConfiguration.sections.credit_cards?.active);
  const acceptedCards = useSelector((state: GlobalState) => state.configuration.walletConfiguration.general.supported_card_types.map((element: string) => element.toLowerCase()));
  const customConfig = useSelector((state: GlobalState) => state.configuration.customWalletConfiguration.acceptedCreditcards?.map((element: string) => element.toLowerCase()));
  const source = customConfig || acceptedCards
  const acceptedImages = source.filter((image: string) => acceptedCardsName.hasOwnProperty(image))
  const rootStyle = classNames(styles.row, { [styles.rowRtl]: isRTL });
  const weAcceptText = useTranslation("WE_ACCEPT");

  if (!isVisible || !source.length) {
    return null;
  }

  return (
    <div data-test-id="accepted-cards-display" className={rootStyle}>
      <MyText type="four" className={styles.acceptedCardText}>{weAcceptText}</MyText>
      <div>
        {acceptedImages.map((element: keyof typeof acceptedCardsName) => (
          <img
            key={element}
            alt={element}
            className={styles.acceptedCard}
            src={`${Configuration.assets}/static/media/${acceptedCardsName[element]}`}
          />
        ))}
      </div>
    </div>
  );
};
