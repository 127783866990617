import Prod from "./Configuration.production";
import Sandbox from "./Configuration.sandbox";
import Test from "./Configuration.test";

const getConfiguration = () => {
  switch (__MODE__) {
    case "production":
      return Prod;

    case "sandbox":
      return Sandbox;

    case "test":
      return Test;
  }
};

export const Configuration = __MODE__ === "local" ? require(`./Configuration.${__MODE__}.tsx`).default : getConfiguration();
