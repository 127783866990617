import {
  MyCheckAuthConnect,
  UserData,
  UserInformation
} from "./WalletServiceTypes";
import { store } from "../../store";
import { generateUUID } from "../GenerateUUID";

export interface IWalletAuth {
  connect: (params: MyCheckAuthConnect) => void;
  getAccessToken: () => string | null;
  getSessionId: () => string;
  getInitialParams: () => MyCheckAuthConnect | null;
  getUserInformation: () => UserInformation;
  setSessionId: (sessionId: string) => void;
  setUserInformation: (data: UserData) => void;
}

export class WalletAuth implements IWalletAuth {
  private currentSessionId: string = "";
  private initParams: MyCheckAuthConnect | null = null;
  private userInformation: UserInformation = {
    additional_data: {},
    user: {}
  };

  public connect = (params: MyCheckAuthConnect): void => {
    this.currentSessionId = generateUUID();
    this.initParams = {...params};
  }

  public getAccessToken = (): string | null => {
    return store.getState().account.accessToken;
  }

  public getInitialParams = (): MyCheckAuthConnect | null => {
    return this.initParams;
  }

  public getSessionId = (): string => {
    return this.currentSessionId;
  }

  public setSessionId = (sessionId: string): void => {
    this.currentSessionId = sessionId;
  }

  public getUserInformation = (): UserInformation => {
    return this.userInformation;
  }

  public setUserInformation = (data: UserData): void => {
    this.userInformation = {
      additional_data: data,
      user: {
        email: data.email,
        first_name: data.firstName || data.name,
        last_name: data.lastName,
      }
    }
  }
}
