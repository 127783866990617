import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { ManageCreditCardRow } from './ManageCreditCardRow'
import { MyButton } from '../../components/MyButton/MyButton'

import { useTranslation } from '../../hooks/useTranslation'

import {
  handleManagementModal,
  onAddCardPress,
  setDefaultPayment,
  setManagePayments,
  setMemorizedCard
} from '../../store/payment/PaymentActions'

import { Tracking } from '../../services/AnalyticsService/GoogleAnalyticsService'

import styles from './ManageCreditCards.module.scss'
import classNames from 'classnames'
import { MyText } from '../../components/MyText/MyText'
import { css } from '@emotion/core'
import { CVVRecord, EncryptedCVVManagerHelper } from '../../helpers/EncryptedCVVManager'
import { clickControl } from '../../helpers/CardOptionHelper'
import { SuccessPopup } from '../../components/SuccessPopup/SuccessPopup'
import { View } from '../../services/MyCheckWalletService/WalletServiceTypes'
import { mobileAndTabletCheck } from '../../services/IsMobile'

type ManageCreditCardsType = {}

export const ManageCreditCards: React.FunctionComponent<ManageCreditCardsType> = () => {
  const dispatch = useDispatch()
  const cards = useSelector((state: GlobalState) => state.payment.cards)
  const isManagementModal = useSelector((state: GlobalState) => state.payment.managementModal);
  const managePaymentsShow = useSelector((state: GlobalState) => state.payment.managePaymentsShow);
  const currentView = useSelector((state: GlobalState) => state.configuration.customWalletConfiguration.view);
  const sectionBackground = useSelector((state: GlobalState) => state.configuration.walletConfiguration.general.background.section);
  const buttonColor = useSelector((state: GlobalState) => state.configuration.walletConfiguration.general.button.color.primary);
  const memorizedCard = useSelector((state: GlobalState) => state.payment.memorizedCard);
  const currentPayment = useSelector((state: GlobalState) => state.payment.currentPayment);
  const successCardPopup = useSelector((state: GlobalState) => state.payment.successCardPopup);

  const selectPaymentText = useTranslation("SELECT_YOUR_PAYMENT_LABEL");
  const addCardText = useTranslation('ADD_CREDIT_CARD');
  const confirmCardText = useTranslation('CONFIRM_YOUR_SELECTION');
  const orLabel = useTranslation("OR");

  const isModalOpened = isManagementModal && currentView === View.CHECKOUT;
  const nonModalLabelsReceived = managePaymentsShow && currentView === View.CHECKOUT;
  const CVVRecordData: CVVRecord | null = EncryptedCVVManagerHelper.getRecordBy(memorizedCard?.id);
  const isMobile = mobileAndTabletCheck();

  const onConfirmHandler = async () => {
    dispatch(setManagePayments(false));
    dispatch(handleManagementModal(false));
    if (memorizedCard && memorizedCard.id !== currentPayment.id) {
      await dispatch(setDefaultPayment(memorizedCard.id, memorizedCard.ccToken));
    }
    dispatch(setMemorizedCard(null));
    clickControl(CVVRecordData, memorizedCard);
  }

  const onAddCardHandler = () => {
    Tracking.trackEvent(Tracking.EventAction.CCManage, 'add')

    if (!isManagementModal) {
      dispatch(setManagePayments(true))
    }
    dispatch(onAddCardPress())
  }

  const renderModalLabels = () => {
    return (
      <>
        {successCardPopup && <SuccessPopup isModal={isModalOpened}/>}

        <MyText type="two" className={classNames(styles.selectPaymentText, {
          [styles.backdownModalMode]: successCardPopup && isModalOpened,
          [styles.backdown]: nonModalLabelsReceived,
        })}>
          {selectPaymentText}
        </MyText></>
    )
  }

  const renderButtonsCollection = () => {
    return (
      <>
        <MyButton
          type="primary" label={confirmCardText} onClick={onConfirmHandler}
          className={styles.button} id="add-credit-card"
        />
        <MyText type="two" className={styles.orLabel}>{orLabel}</MyText>
        <MyButton
          type="primary" label={addCardText} onClick={onAddCardHandler}
          className={styles.button} id="add-credit-card"
          css={(css`
            background-color: ${sectionBackground};
            color: ${buttonColor};
          `)}
          icon={"+"}
        />
      </>
    )
  }

  const renderAddCardButton = () => {
    return (
      <MyButton
        type="primary" label={addCardText} onClick={onAddCardHandler}
        className={styles.button} id="add-credit-card"
      />
    )
  }

  return (
    <div>
      {(isModalOpened || nonModalLabelsReceived) && renderModalLabels()}
      {cards.map(card => (
        <ManageCreditCardRow key={card.id} paymentMethod={card} />
      ))}
      <div className={classNames(styles.rowButton, {
        [styles.buttonsCollection]: isModalOpened || nonModalLabelsReceived,
        [styles.buttonsCollectionMobile]: isMobile,
      })}>
        {(isModalOpened || nonModalLabelsReceived) ? renderButtonsCollection() : renderAddCardButton()}
      </div>
    </div>
  )
}
