/** @jsx jsx */

import * as React from "react";
import { useSelector } from "react-redux";
import { jsx } from "@emotion/core";
import classNames from "classnames";

import { MyTouchable } from "../MyTouchable/MyTouchable";
import { MyText } from "../MyText/MyText";

import { composeRadioBtnStyles, FontGroups } from "../../helpers/CreateStyleFromConfig";

import { useIsRTL } from "../../hooks/useIsRTL";

import styles from "./MyCheckbox.module.scss";

type MyCheckboxType = {
  testId: string
  isChecked: boolean,
  onClick: () => void,
  label: string,
  disabled?: boolean,
  textGroup?: FontGroups,
}

export const MyCheckbox: React.FunctionComponent<MyCheckboxType> = ({ testId, disabled, onClick, isChecked, label, textGroup }) => {
  const { general } = useSelector((state: GlobalState) => state.configuration.walletConfiguration);
  const isRTL = useIsRTL();

  const checkboxStyle = composeRadioBtnStyles(general);

  const stylesRoot = classNames(styles.root, { [styles.rootRtl]: isRTL });
  const checkboxClassnames = classNames(styles.checkbox, { [styles.checked]: isChecked })
  
  return (
    <MyTouchable disabled={disabled!} onClick={onClick} id={testId}>
      <div className={stylesRoot}>
        <div
          className={checkboxClassnames}
          style={{ ...checkboxStyle }}
        >
          <svg width="100%" height="100%" viewBox={`0 0 ${16} ${18}`}>
            <path
              d="M6.70597638,14.231662 L2.19757886,9.72390157 C1.93414038,9.46110018 1.93414038,9.03679541 2.19757886,8.77431257 C2.45846896,8.51151119 2.88532211,8.51151119 3.1474864,8.77431257 L6.63589601,12.257944 L13.5713041,4.23374179 C13.8149927,3.95087194 14.2383418,3.92124705 14.5212117,4.16397997 C14.8012146,4.40607579 14.8324322,4.8319733 14.591292,5.11293187 L6.70597638,14.231662 Z"
              fill={isChecked ? general.button.color.primary : "transparent"}
            />
          </svg>
        </div>
        <MyText type={textGroup || "three"} className={isRTL ? styles.labelRtl : styles.label}>{label}</MyText>
      </div>
    </MyTouchable>
  );
};
