import { createStore, applyMiddleware, combineReducers, compose } from "redux";
import thunk from "redux-thunk";

import { accountReducer } from "./account/AccountReducers";
import { configurationReducer } from "./configuration/ConfigurationReducers";
import { paymentReducer } from "./payment/PaymentReducers";
import { giftCardReducer } from "./giftCard/GiftCardReducers";

const rootReducer = combineReducers({
  account: accountReducer,
  configuration: configurationReducer,
  payment: paymentReducer,
  giftCard: giftCardReducer
});

const appReducer = (state: any, action: any) => {
  if (action.type === "RESET_APP") {
    state = undefined;
  }

  return rootReducer(state, action);
};

const realCompose = process.env.NODE_ENV === "development" ? require("remote-redux-devtools").composeWithDevTools({
  realtime: true,
  port: 8000
}) : compose;

const enhancer = realCompose(applyMiddleware(thunk));
const store = createStore(appReducer, enhancer);
export { store };
