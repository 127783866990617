import * as React from "react";
import { useSelector } from "react-redux";

import { CheckoutTitle } from "./CheckoutTitle";
import { GiftCardsSection, checkGiftCardSection } from "../GiftCardsSection/GiftCardsSection";
import { CreditCards } from "../CreditCards/CreditCards";
import { AlternativePayments } from "../AlternativePayments/AlternativePayments";

import styles from "./Checkout.module.scss";
import { useEffect } from "react";
import { SPSAlternative } from "../SPSAlternative/SPSAlternative";
import { View } from "../../services/MyCheckWalletService/WalletServiceTypes";
import { MyCheckWalletService } from "../../services/MyCheckWalletService/WalletManager";
import { useSuitableAlternatives } from "../../hooks/useSuitableAlternatives";
import { getRecaptchaConfigs } from "../../helpers/getRecaptchaConfigs";

type CheckoutType = {}

export const Checkout: React.FunctionComponent<CheckoutType> = () => {
  const isCreditCardsVisible = useSelector((state: GlobalState) => state.configuration.walletConfiguration.sections.credit_cards.active);
  const isPaymentSolutionDefined = !!useSelector((state: GlobalState) => state.configuration.walletConfiguration.general.creditcard_provider?.name);
  const giftCardSection = useSelector((state: GlobalState) => state.configuration.walletConfiguration.sections.giftcards);
  const customConfig = useSelector((state: GlobalState) => state.configuration.customWalletConfiguration);
  const isAlternativeProviderSPS = useSelector((state: GlobalState) => state.configuration.walletConfiguration?.general.alternative_payments_provider?.name) === "sps";

  const isShowGiftCardSection = checkGiftCardSection(giftCardSection);
  const reCaptchaConfigs = getRecaptchaConfigs();

  const suitableAcceptedInstantPayments = useSelector(
    (state: GlobalState) =>
      state.configuration.walletConfiguration.sections.instant_payments
  );

  const isShowAlternativePayment = !!suitableAcceptedInstantPayments?.length && (customConfig.view === View.CHECKOUT)

  const suitableAcceptedAlternativePayments = useSuitableAlternatives();

  useEffect(() => {
    const currentWindow: any = window;
    if (
      !currentWindow.recaptchaOptions
      && reCaptchaConfigs.isRecaptchaV3
      && reCaptchaConfigs.isEnterprise
    ) {
      currentWindow.recaptchaOptions = {
        enterprise: true,
      };
    }
    if(customConfig.acceptedCreditcards){
      MyCheckWalletService.setAcceptedCreditCards(customConfig.acceptedCreditcards)
    }
  }, [])

  return (
    <div className={styles.row} data-test-id="checkout">
      <CheckoutTitle />
      {isShowGiftCardSection && <GiftCardsSection />}
      {(isPaymentSolutionDefined && (isCreditCardsVisible || !!suitableAcceptedAlternativePayments?.length)) && (
        <CreditCards
          showRadioButtons={!!suitableAcceptedInstantPayments?.length}
          alternatives={suitableAcceptedAlternativePayments}
          isCreditCardsVisible={isCreditCardsVisible}
        />
      )}
      {!isAlternativeProviderSPS && isShowAlternativePayment && <AlternativePayments payments={suitableAcceptedInstantPayments} />}
      {isAlternativeProviderSPS && <SPSAlternative />}
    </div>
  );
};
