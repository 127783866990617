import React from 'react'
import { css } from '@emotion/core'
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from '../../components/Modal/Modal';
import {
  handleManagementModal,
  onCancelAdd,
  setErrorMessage,
  setManagePayments,
  setMemorizedCard,
  setSuccessCardPopup
} from '../../store/payment/PaymentActions';

import styles from './ManageCreditCards.module.scss';
import creditCardStyles from '../CreditCards/CreditCards.module.scss';
import { clickControl } from '../../helpers/CardOptionHelper';
import { CVVRecord, EncryptedCVVManagerHelper } from '../../helpers/EncryptedCVVManager';
import { MyButton } from '../../components/MyButton/MyButton';
import { useTranslation } from '../../hooks/useTranslation';
import { ManageCreditCards } from './ManageCreditCards';
import { Divider } from '../../components/Divider/Divider';
import { AcceptedCardsDisplay } from '../AcceptedCards/AcceptedCardsDisplay';
import { CardAdd } from '../CardAdd/CardAdd';
import { AlternativeWalletsDisplay } from '../AlternativeWallets/AlternativeWalletsDisplay';
import classNames from 'classnames';
import { useSuitableAlternatives } from '../../hooks/useSuitableAlternatives';
import { RecaptchaTerms } from '../../components/RecaptchaTerms/RecaptchaTerms';
import { CardAddError } from '../CardAdd/CardAddError';

export const ManageCreditCardModal: React.FunctionComponent = () => {
  const dispatch = useDispatch();

  const creditCardProviderName = useSelector((state: GlobalState) => state.configuration.walletConfiguration?.general.creditcard_provider.name);
  const { general: theme } = useSelector((state: GlobalState) => state.configuration.walletConfiguration);
  const isManagementModal = useSelector((state: GlobalState) => state.payment.managementModal);
  const successCardPopup = useSelector((state: GlobalState) => state.payment.successCardPopup);
  const paymentMethod = useSelector(
    (state: GlobalState) =>
      state.payment.cards.find((e) => e.isDefault)! ||
      state.payment.currentPayment
  );
  const isAdding = useSelector((state: GlobalState) => state.payment.isAdding);
  const hasCards = useSelector((state: GlobalState) => state.payment.cards.length);
  const cards = useSelector((state: GlobalState) => state.payment.cards);
  const errorMessageLabel = useSelector((state: GlobalState) => state.payment.errorMessage);
  const displayAlternativeDivider = useSelector((state: GlobalState) => state.payment.displayAlternativeDivider);

  const oldCards = React.useRef<PaymentMethod[]>([])

  const addCardWithLabel = useTranslation("OR_ADD_CARD_WITH");
  const backLabel = useTranslation(isAdding ? "BACK_BUTTON" : "BALANCE_BALANCE");

  const CVVRecordData: CVVRecord | null = EncryptedCVVManagerHelper.getRecordBy(paymentMethod.id);
  const suitableAlternatives = useSuitableAlternatives();

  React.useEffect(() => {
    oldCards.current = cards;
  }, []);

  React.useEffect(() => {
    const oldCardsId = oldCards.current.map(card => card.id);
    const cardsId = cards.map(card => card.id);
    const newCardsID = cardsId.filter(id => oldCardsId.indexOf(id) == -1);

    if(!newCardsID.length && successCardPopup) {
      dispatch(setSuccessCardPopup(false));
    }
  }, [cards]);

  const hideModal = () => {
    dispatch(setManagePayments(false));
    dispatch(handleManagementModal(false));
  }
  const closeModal = () => {
    hideModal();
    dispatch(setErrorMessage(""));
    dispatch(setMemorizedCard(null));
    clickControl(CVVRecordData, paymentMethod);
  }
  const clickBack = () => {
    dispatch(onCancelAdd());
    clickControl(CVVRecordData, paymentMethod);
  }
  const renderCreditElement = () => {
    if (isAdding) {
      return <CardAdd isSps={creditCardProviderName === 'sps'}/>
    }
    return <ManageCreditCards />
  }

  if(!hasCards) {
    hideModal();
  }

  if (!isManagementModal) {
    return null;
  }

  return (
    <Modal
      contentLabel=""
      isOpen={isManagementModal}
      onRequestClose={closeModal}
      className={styles.managementModal}
      touchableOverlay={false}
    >
      <div className={styles.container}>
        <MyButton
            onClick={isManagementModal && isAdding ? clickBack: closeModal}
            type="unstyled"
            label={backLabel}
            className={classNames(styles.backLabel, {[styles.backLabelIcon]: !isAdding})}
            css={
              (css`
                font-size: ${theme.text.size.medium};
                text-transform: none;
              ` as any)
            }
            id={"navigationButton"}
          />

          <div className={styles.formWrapp}>
            {renderCreditElement()}
          </div>

          <div className={styles.acceptedCards}>
            <AcceptedCardsDisplay />
            <RecaptchaTerms />
          </div>

          {!isAdding && errorMessageLabel && (
            <CardAddError errorMessage={errorMessageLabel} />
          )}

          {!!suitableAlternatives.length && displayAlternativeDivider && (
            <>
              <Divider
                label={addCardWithLabel}
                className={creditCardStyles.divider}
              />
              <AlternativeWalletsDisplay alternatives={suitableAlternatives}/>
            </>
          )}
      </div>
    </Modal>
  );
}
