import React, { useCallback, useState } from "react";
import { attachGooglePay } from "../helpers/FetchScript";
import { useDispatch, useSelector } from "react-redux";
import { Configuration } from "../configs/Configuration";
import { ApiClient, ApiUrls } from "./ApiClient";
import {
  checkCardPayment,
  setLoader,
  setCurrentPaymentOption,
  setPaymentMethodSelected,
  setErrorMessage,
  fetchPayments,
  setDisplayAlternativeDivider,
} from "../store/payment/PaymentActions";
import { Tracking } from "./AnalyticsService/GoogleAnalyticsService";
import { getMerchantConfig, MerchantsList, MerchantSpeciality } from "../helpers/helpers";
import { useRevertPaymentSelection } from "../hooks/useRevertPaymentSelection";
import { parseAlternativeError, parseWalletMSError } from "../helpers/ParseError";
import { useTranslation } from "../hooks/useTranslation";
import { CreditCardManager } from "../containers/CreditCards/CreditCardManagerProvider";
import { MyCheckWalletService } from "./MyCheckWalletService/WalletManager";

const GOOGLE_PAY_TYPE: string = "Google Pay";
type GooglePayServiceType = {
};


type MerchantInfo = {
  merchantName?: string,
  merchantId?: string,
}

let _paymentsClient: any = null;

const buttonId = "GooglePayCheckout";

enum FilteredCardName {
  DINERS = "diners",
  TR = "TR",
  UNIONPAY = "unionpay",
  MAESTRO = "maestro",
  ELO = "elo",
  CIRRUS = "cirrus",
  SOLO = "solo",
  SWITCH = "switch",
}

//@ts-ignore
const isWebView = () => !!window.WalletMessage;

export const GooglePayService: React.FunctionComponent<GooglePayServiceType> = () => {
  const [ isReadyToPay, setIsReadyToPay ] = useState(false);

  const dispatch = useDispatch();
  const useRevertPayment = useRevertPaymentSelection();

  const supportedCardTypes = useSelector((state: GlobalState) =>
    state.configuration.walletConfiguration.general.supported_card_types.filter(
      (payment: FilteredCardName) =>
        !Object.values(FilteredCardName).includes(payment)
    )
  );
  const acceptedCreditcards = useSelector((state: GlobalState) =>
    state.configuration.customWalletConfiguration.acceptedCreditcards?.filter(
      (payment: FilteredCardName) =>
        !Object.values(FilteredCardName).includes(payment)
    )
  );

  const merchantConfigurations = useSelector((state: GlobalState) => state.configuration.walletConfiguration.merchantConfigurations);
  const accessToken = useSelector((state: GlobalState) => state.account.accessToken);
  const acceptedCard = acceptedCreditcards ? acceptedCreditcards : supportedCardTypes;

  const errorLabelFilterCard = useTranslation("ERROR_FILTER_CARD");

  const spsMerchantConfig = getMerchantConfig(
    MerchantsList.SPS_ECOMMERCE,
    merchantConfigurations,
    MerchantSpeciality.GPAY
  );

  const onGooglePayLoaded = useCallback(async () => {
    const paymentsClient = getGooglePaymentsClient();
    const response = await paymentsClient.isReadyToPay(getIsReadyToPay());
    if (response.result || isWebView()) {
      addGooglePayButton();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadGooglePay = useCallback(async () => {
    if(!spsMerchantConfig?.settings[MerchantSpeciality.GPAY]) {
      MyCheckWalletService.events.error("Could not locate any Google Pay merchant");
      console.error({
        message: "Could not locate any Google Pay merchant",
      });
      dispatch(setDisplayAlternativeDivider(false))
      return;
    }
    dispatch(setDisplayAlternativeDivider(true))
    await attachGooglePay();
    await onGooglePayLoaded();
    CreditCardManager.init(dispatch);
  }, [spsMerchantConfig?.settings, onGooglePayLoaded, dispatch]);

  React.useEffect(() => {
    loadGooglePay();
  }, [loadGooglePay]);

  const getGooglePaymentsClient = () => {
    if (_paymentsClient === null) {
      _paymentsClient = new window.google.payments.api.PaymentsClient({
        environment: Configuration.env.toUpperCase() === 'PRODUCTION' ? 'PRODUCTION' : 'TEST'
      });
    }

    return _paymentsClient;
  };

  const getIsReadyToPay = () => ({
    apiVersion: 2,
    apiVersionMinor: 0,
    allowedPaymentMethods: [
      {
        type: "CARD",
        parameters: {
          allowedAuthMethods: ["PAN_ONLY"],
          allowedCardNetworks: acceptedCard
            .filter((card: string) => card.toLocaleLowerCase() !== 'diners')
            .map((element: string) => element.toUpperCase())
        }
      }
    ]
  });

  const onGooglePaymentButtonClicked = async () => {
    useRevertPayment.seal()

    Tracking.trackEvent(Tracking.EventAction.AlternativePMClick, GOOGLE_PAY_TYPE);
    MyCheckWalletService.events.paymentMethodSelected('googlepay', 'traditional')
    dispatch(setCurrentPaymentOption('credit-card'));
    dispatch(setPaymentMethodSelected('googlepay', 'traditional', true));
    MyCheckWalletService.checkIsCheckoutReady({});
    const paymentDataRequest = getGooglePaymentDataRequest();

    if (isWebView()) {
      //@ts-ignore
      window.WalletMessage.sendPaymentDataRequest(paymentDataRequest);
      //@ts-ignore
      window.ProcessGooglePay = (paymentData: any, error?: boolean) => {
        if (error) {
          dispatch(checkCardPayment());
          useRevertPayment.revert()
          dispatch(setLoader(false));
        }
        processPayment(paymentData);
      };
    }

    const paymentsClient = getGooglePaymentsClient();

    try {
      const paymentData = await paymentsClient.loadPaymentData(paymentDataRequest);
      await processPayment(paymentData);
    } catch (error) {
      dispatch(checkCardPayment());
      useRevertPayment.revert()
      dispatch(setLoader(false));
    }
  };

  const getMerchantInfoGpay = (): MerchantInfo => {
    const merchantInfoSps: MerchantInfo = Configuration.env === "production" ? {
      merchantId: "BCR2DN4T7SKLDKT5"
    } : {}

    return merchantInfoSps;
  }

  const getGooglePaymentDataRequest = () => ({
    apiVersion: 2,
    apiVersionMinor: 0,
    merchantInfo: getMerchantInfoGpay(),
    allowedPaymentMethods: [
      {
        type: "CARD",
        parameters: {
          allowedAuthMethods: ["PAN_ONLY"],
          allowedCardNetworks: acceptedCard
            .filter((card: string) => card.toLocaleLowerCase() !== 'diners')
            .map((element: string) => element.toUpperCase())
        },
        tokenizationSpecification: {
          type: "PAYMENT_GATEWAY",
          parameters: {
            gateway: "shijipaymentsolutions",
            gatewayMerchantId: spsMerchantConfig?.settings[MerchantSpeciality.GPAY],
          }
        }
      }
    ],
    transactionInfo: {
      totalPriceStatus: "FINAL",
      totalPrice: "1.00",
      currencyCode: "USD"
    }
  });

  const processPayment = async (paymentData: any) => {
    try {
      dispatch(setLoader(true));
      const { paymentMethodData: { tokenizationData: { token } } } = paymentData;

        const response = await ApiClient(ApiUrls.addCreditCard, {
          domain: Configuration.api_domain,
          method: 'POST',
          body: {
            accessToken: accessToken,
            source: 'SPS_ECOMMERCE',
            merchantId: spsMerchantConfig?.id,
            extraParams: {
              tokenId: btoa(token),
              isGpay: true,
            }
          }
        });

        MyCheckWalletService.events.cardTokenReceived(response.ccToken);
        dispatch(fetchPayments());
    } catch (error) {
      // @ts-ignore
      if (error.errorId) {
        // @ts-ignore
        dispatch(setErrorMessage(parseWalletMSError(error.errorId)));
      } else {
        dispatch(setErrorMessage(parseAlternativeError(error) || errorLabelFilterCard));
      }
      MyCheckWalletService.cardAddFailureEvent(error, MerchantsList.SPS_ECOMMERCE);
      dispatch(setLoader(false));
    }
  };

  const addGooglePayButton = () => {
    const paymentsClient = getGooglePaymentsClient();
    const button = paymentsClient.createButton({ onClick: onGooglePaymentButtonClicked, buttonType: "short" });
    const buttonElement = document.getElementById(buttonId);
    if (buttonElement?.hasChildNodes()) {
      buttonElement.innerHTML = "";
    }
    setIsReadyToPay(true);
    if (buttonElement) {
      buttonElement.appendChild(button);
    }
  };


  return (
    <div
      id={buttonId}
      aria-label="GooglePay button"
      role="button"
      style={{ display: `${!isReadyToPay ? 'none' : 'block'}` }}
    />
  );
};
