import { attachGoogleAnalytics } from "../../helpers/FetchScript";

const gaId = "mycheck-google-analytics";

const isGaAvailable = () => typeof window.ga !== "undefined";

const initGoogleAnalytics = () => {
  const isAvailable = isGaAvailable();
  const { body } = document;
  const script = document.createElement("script");
  const inlineScript = document.createTextNode(`
            window.ga=window.ga||function(){(ga.q=ga.q||[]).push(arguments)};ga.l=+new Date;
        `);

  // window.ga('create', 'UA-140613310-27', 'auto', 'mycheckWallet');
  // window.ga('mycheckWallet.send', 'pageview');

  script.id = "mycheck-google-analytics";
  script.type = "text/javascript";
  script.appendChild(inlineScript);

  if (!document.getElementById(gaId)) {
    body.appendChild(script);
  }

  if (!isAvailable) {
    attachGoogleAnalytics();
  }
};

const trackEvent = (action: EventAction, label: string, value: string = "") => {
  window.ga("send", "event", "Wallet", action, label, value);
};

enum EventAction {
  FlowType = "Flow type",
  Language = "Language",
  Direction = "Direction",
  BID = "BID",
  Section = "Section",
  GiftCardApply = "Gift Card - apply",
  GiftCardDetails = "Gift Card - details",
  GiftCardAmount = "Gift Card - amount",
  CreditCardError = "Credit Card error",
  CreditCard = "Credit Card",
  AlternativePMClick = "Alternative PM - click",
  AlternativePMReturn = "Alternative PM - return",
  InstantPM = "Instant PM",
  CCManage = "CC manage",
  CCManageError = "CC manage error",
  CCManageAlternativeClick = "CC manage alternative - click",
  CCManageAlternativeReturn = "CC manage alternative - return",
  Checkout = "Checkout",
  CheckoutType = "Checkout type",
  CheckoutSuccess = "Checkout success",
  CheckoutFailed = "Checkout failed",
  DS3 = "3DS"
}

export const Tracking = {
  initGoogleAnalytics,
  trackEvent,
  EventAction
};
