/** @jsx jsx */

import * as React from "react";
import { useSelector } from "react-redux";
import { jsx } from "@emotion/core";
import classNames from "classnames";

import { composeContainerStyles } from "../../helpers/CreateStyleFromConfig";

import styles from "./Container.module.scss";

type ContainerType = {
  style?: object,
  className?: string,
  smallPadding?: boolean,
  testId?: string
}

export const Container: React.FunctionComponent<ContainerType> = ({ testId, children, style, className, smallPadding = false }) => {
  const { general } = useSelector((state: GlobalState) => state.configuration.walletConfiguration);
  const classes = classNames(smallPadding ? styles.smallRoot : styles.root, className && { [className]: true });
  const containerStyles = composeContainerStyles(general);

  return (
    <div className={classes} css={{ ...containerStyles, ...style }} data-test-id={testId}>
      {children}
    </div>
  );
};
