import {defaultTranslation} from "../assets/defaultTranslations";
import {store} from "../store";

export const parsePCIError = (errorIdentifier: string) => {
  if (!errorIdentifier) {
    return "";
  }
  const errorIdentifierCodes = errorIdentifier.split("-");
  const pciErrorTranslation = store.getState().configuration.translation[`PCI_ERROR_${errorIdentifierCodes[0]}` || "PCI_ERROR"]
    || defaultTranslation[`PCI_ERROR_${errorIdentifierCodes[0]}` || "PCI_ERROR"]
    || "MISSING_KEY_OR_VALUE"
  return `${pciErrorTranslation} Error Code: ${errorIdentifierCodes[0]}-${errorIdentifierCodes[1]}-${errorIdentifierCodes[2]}`;
};

export const parseWalletMSError = (errorIdentifier: string) => {
  if (!errorIdentifier) {
    return "";
  }
  const pciErrorTranslation = store.getState().configuration.translation["PCI_ERROR"]
    || defaultTranslation["PCI_ERROR"]
    || "MISSING_KEY_OR_VALUE"
  return `${pciErrorTranslation} Error Code: ${errorIdentifier}`;
};

export const parseAlternativeError = (error: any) => {
  if (error.message && error.uniqueErrorIdentifier) {
    const errorMessage = store.getState().configuration.translation[error.message];
    return `${errorMessage}. Error Code: ${error.uniqueErrorIdentifier}`;
  }
  return null;
};
