export const GET_GIFTCARDS = "GET_GIFTCARDS";
export const ON_APPLY_BUCKS = "ON_APPLY_BUCKS";
export const SET_AMOUNT_TO_APPLY = "SET_AMOUNT_TO_APPLY";
export const DROP_APPLY_BUCKS = "DROP_APPLY_BUCKS";

type GetGiftCardsActions = { type: typeof GET_GIFTCARDS, benefits: Benefit[], balance: number, bids: {[key: string]: number} };
type ApplyBucksActions = { type: typeof ON_APPLY_BUCKS, value: number | string };
type SetAmountToApplyActions = { type: typeof SET_AMOUNT_TO_APPLY, value: number | string };
type dropApplyBucks = { type: typeof DROP_APPLY_BUCKS};

export type GiftCardActionTypes =
  | GetGiftCardsActions
  | ApplyBucksActions
  | SetAmountToApplyActions
  | dropApplyBucks;
