import * as Sentry from "@sentry/react";
import { Configuration } from "./configs/Configuration";
import { MyCheckAuthConnect } from "./services/MyCheckWalletService/WalletServiceTypes";

const browserContext = {
  userAgent: navigator.userAgent,
  platform: (navigator as any).userAgentData?.platform || navigator.platform,
  language: navigator.language,
  url: window.location.href,
  viewport: {
    width: window.innerWidth,
    height: window.innerHeight,
  },
  connection: navigator.connection ? {
    effectiveType: navigator.connection.effectiveType,
    downlink: `${navigator.connection.downlink} Mbps`,
    rtt: `${navigator.connection.rtt} ms`,
  } : undefined,
  cookiesEnabled: navigator.cookieEnabled,
  doNotTrack: navigator.doNotTrack,
  timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  deviceMemory: (navigator as any).deviceMemory,
  hardwareConcurrency: navigator.hardwareConcurrency,
}

export const InitializeSentry = (publishableKey: string) => {
  const sessionID = window.mycheck.auth.getSessionId() || 'User session id not found'
  Sentry.init({
    release: __RELEASE__VERSION__,
    dsn: Configuration.sentry.dsn,
    environment: Configuration.sentry.env,
    beforeSend(event) {
      if(event.extra) {
        event.extra.initialAuthParams = anonymizeProperties<MyCheckAuthConnect>(
          event.extra?.initialAuthParams as MyCheckAuthConnect, 
          ['refreshToken', 'accessToken']
        );
        
        event.extra.accountReduxState = anonymizeProperties<AccountState>(
          event.extra?.accountReduxState as AccountState, 
          ['refreshToken', 'accessToken']
        );
        
        event.extra.initialParams = anonymizeProperties<any>(
          event.extra?.initialParams, 
          ['amount']
        );
      };
      
      event.fingerprint = ['session', sessionID];
      
      return event;
    },
    integrations: [
      Sentry.replayIntegration({
        maxReplayDuration: 30000,
        onError: (err) => console.warn(err),   
      }), 
      Sentry.replayCanvasIntegration(), 
      Sentry.browserTracingIntegration(), 
      Sentry.debugIntegration()
    ],
  
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
  
    // Session Replay
    replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
  Sentry.setContext("Session", {
    sessionID,
  });
  Sentry.setContext("Publishable Key", {
    PK: publishableKey,
  });
  Sentry.setContext("Browser", browserContext);
};



const anonymizeProperties = <T extends Record<string, any>>(
  obj: T, 
  targetProperties: (keyof T)[], 
): T => {
  try {
    if (!obj) return obj;
    
    if (typeof obj === 'string') obj = JSON.parse(obj);

    const anonymizeValue = (value: any): any => {
      if (Array.isArray(value)) {
        return value.map(anonymizeValue);
      } else if (typeof value === 'object' && value !== null) {
        return anonymizeProperties(value, targetProperties);
      }
      return value;
    };

    return Object.fromEntries(
      Object.entries(obj).map(([key, value]) => [
        key,
        targetProperties.includes(key as keyof T) && value
          ? "*".repeat(String(value).length)
          : anonymizeValue(value)
      ])
    ) as T;
  } catch (error) {
    console.error('Error in anonymizeProperties:', error);
    return obj;
  }
}
