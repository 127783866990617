import { store } from "../store";

enum Provider {
  V2 = "reCaptchaV2",
  V3_ENTERPRISE = "reCaptchaV3_Enterprise",
}

type RecaptchaConfigs = {
  isEnterprise: boolean,
  isRecaptchaEnabled: boolean,
  isRecaptchaV3: boolean,
  isRecaptchaV2: boolean,
  reCaptchaProvider: string,
}

export const getRecaptchaConfigs = (): RecaptchaConfigs => {
  let isRecaptchaV2 = false;
  let isRecaptchaV3 = false;

  const isRecaptchaEnabled = store.getState().configuration.walletConfiguration?.sections.recaptcha?.enabled || false;
  const reCaptchaProvider = store.getState().configuration.walletConfiguration?.sections.recaptcha?.provider || "";

  const isEnterprise = reCaptchaProvider?.split("_")[1]?.toLocaleLowerCase() === "enterprise";
  const checkedProvider = Object.values(Provider).includes(reCaptchaProvider);

  if (reCaptchaProvider === Provider.V2) {
    isRecaptchaV2 = true;
    isRecaptchaV3 = false;
  }
  if (reCaptchaProvider === Provider.V3_ENTERPRISE) {
    isRecaptchaV2 = false;
    isRecaptchaV3 = true;
  }


  if (!checkedProvider && isRecaptchaEnabled) {
    return {
      isRecaptchaEnabled,
      reCaptchaProvider: Provider.V2,
      isRecaptchaV2: true,
      isRecaptchaV3: false,
      isEnterprise: false,
    }
  }

  return {
    isRecaptchaEnabled,
    reCaptchaProvider,
    isRecaptchaV2,
    isRecaptchaV3,
    isEnterprise,
  }
}
