import * as React from "react";
import { useDispatch } from "react-redux";
import classNames from "classnames";

import { MyButton } from "../../components/MyButton/MyButton";
import { Loader } from "../../components/Loader/Loader";

import { deletePayment, fetchPayments } from "../../store/payment/PaymentActions";

import { Tracking } from "../../services/AnalyticsService/GoogleAnalyticsService";

import { useTranslation } from "../../hooks/useTranslation";
import { useIsRTL } from "../../hooks/useIsRTL";

import styles from "./ManageCreditCards.module.scss";

type RemoveCreditCardType = {
  id: string,
  removeButtons: boolean,
  setRemoveButtons: (param: boolean) => void
}

export const RemoveCreditCard: React.FunctionComponent<RemoveCreditCardType> = ({ id, removeButtons, setRemoveButtons }) => {
  const [isPending, setIsPending] = React.useState(false);
  const dispatch = useDispatch();
  const isRTL = useIsRTL();
  const onClickHandler = () => {
    Tracking.trackEvent(Tracking.EventAction.CCManage, "remove");
    setRemoveButtons(!removeButtons);
  };

  const onDelete = async () => {
    try {
      setIsPending(true);
      Tracking.trackEvent(Tracking.EventAction.CreditCard, "remove card");
      await dispatch(deletePayment(id));
      setRemoveButtons(false);
      setIsPending(false);
    } catch {
      setIsPending(false);
      dispatch(fetchPayments());
    }
  };

  const yesLabel = useTranslation("YES");
  const noLabel = useTranslation("NO");
  const removeLabel = useTranslation("REMOVE")

  if (isPending) {
    return (
      <Loader/>
    );
  }

  if (removeButtons) {
    const rowRoot = classNames(styles.row, { [styles.rowRtl]: isRTL });
    return (
      <div className={rowRoot}>
        <MyButton useBootstrap type="yes" label={yesLabel} onClick={onDelete} id="confirm-delete"/>
        <div>|</div>
        <MyButton useBootstrap type="no" label={noLabel} onClick={onClickHandler} id="decline-delete"/>
      </div>
    );
  }

  return (
    <MyButton useBootstrap type="no" label={removeLabel} onClick={onClickHandler} id="remove-button"/>
  );
};
