import { WeChatAliPay } from "../WeChatAliPayService"

export type instantPaymentMethod = WeChatAliPay | 'payconiq'

export type UserInformation = {
  additional_data: object,
  user: {
    first_name?: string,
    last_name?: string,
    email?: string
  }
}

export type ConfirmButton = {
  display: boolean, 
  text: string,
}

export type Fingerprint = {
  ip: string | null,
  screenHeight: number,
  screenWidth: number,
  timezoneOffset: number,
  browserAgent: string,
  walletSdkSessionId?: string
}

export type CardDetails = {
  cc_token?: string | null,
  encrypted_cvv?: string | null,
  type?: SelectedPaymentMethod | null,
  fingerprint?: Fingerprint | null
}

export type MyCheckAuthConnect = {
  publishableKey: string,
  refreshToken?: string,
  accessToken?: string,
}

export type PaymentDetails = {
  currency: string,
  amount: string | number,
  return_url?: string,
  callback_url?: string,
  metadata?: string,
  orderId?: string,
}

export enum SupportedCard {
  AMEX = "amex",
  VISA = "visa",
  MASTERCARD = "mastercard",
  DINERS = "diners",
  DISCOVER = "discover",
  ELO = "elo",
  AMAZONPAY = "amazonpay",
  TR = "TR",
  UNIONPAY = "unionpay",
  MAESTRO = "maestro",
  SWITCH = "switch",
  SOLO = "solo",
  JCB = "jcb",
  CIRRUS = "cirrus",
  APPLE_PAY = "applepay",
  BRAINTREE = "paypal",
}
export type SupportedLanguage = "en_US"
export type MyCheckEventName =
  "cardTokenReceived"
  | "addCardFormReady"
  | "paymentCompleted"
  | "addUserInformation"
  | "paymentMethodRemoved"
  | "paymentMethodSelected"
  | "giftCardSelected"
  | "error"
  | "init"
  | "ready"
  | "encryptedCvvReceived"
  | "checkoutReady"
  | "onAddCardFailure"

export type EventsCallback<T, K extends MyCheckEventName> = Record<K, (param?: any, param2?: any) => void>;

export enum View {
  CHECKOUT = "CHECKOUT",
  MANAGE = "MANAGE"
}

export enum Environment {
  PRODUCTION = "PRODUCTION",
  SANDBOX = "SANDBOX",
  TEST = "TEST",
}

export type Settings = {
  locale?: string,
  merchant_id?: number,
  acceptedPaymentMethods?: Array<any>,
  acceptedCreditcards?: Array<string>,
  acceptGiftcards?: boolean,
  addCardAutofocus?: boolean,
  walletId?: number,
  businessId?: number,
  view?: View,
  debug?: boolean,
  confirm_button?: ConfirmButton,
}

export interface UserData {
  email: string;
  firstName: string;
  lastName: string;
  name: string;
}

export type InitParams = {
  settings: Settings,
  payment_details: PaymentDetails,
}