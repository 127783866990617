import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { MyRadio } from '../../components/MyRadio/MyRadio'

import {
  checkCardPayment,
} from "../../store/payment/PaymentActions";

import styles from "../CreditCards/CreditCards.module.scss";
import { MyText } from "../../components/MyText/MyText";

type PaymentOptionType = {
  label: string,
  value: string,
}

export const PaymentOption: React.FunctionComponent<PaymentOptionType> = ({ label, value }) => {
  const dispatch = useDispatch();

  const onClickHandler = () => {
    dispatch(checkCardPayment());
  };
  const currentPaymentOption = useSelector((state: GlobalState) => state.payment.paymentOption);

  return (
    <MyRadio
      className={styles.radio} value={currentPaymentOption} onClick={onClickHandler} option={{ value, label }}
      id={value}
    />
  )
}
