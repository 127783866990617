import * as React from "react";
import { useEffect, useState } from "react";
import styles from './CvvConfirmation.module.scss'
import { useTranslation } from "../../hooks/useTranslation";
import { CardFieldName } from "../../components/MyInput/constants";
import { MyTooltip } from "../../components/MyTooltip/MyTooltip";
import { MyIcon } from "../../components/MyIcon/MyIcon";
import { MyInput } from "../../components/MyInput/MyInput";
import { useDispatch, useSelector } from "react-redux";
import { isValidCvv } from "../../helpers/GetCardType";
import { getCVVId } from "../../services/SPSService";
import { getMerchantConfig, MerchantsList } from "../../helpers/helpers";
import { ApiClient } from "../../services/ApiClient";
import { MyButton } from "../../components/MyButton/MyButton";
import { setCurrentPaymentOption, setLoader, setPaymentMethodSelected } from "../../store/payment/PaymentActions";
import { CreditCardManager } from "../CreditCards/CreditCardManagerProvider";
import { Loader } from "../../components/Loader/Loader";

type CvvConfirmationType = {}

const ENTER_KEY_CODE = 13

export const CvvConfirmation: React.FunctionComponent<CvvConfirmationType> = () => {
  const [cvv, setCvv] = useState('');
  const dispatch = useDispatch()

  const payment = useSelector((state: GlobalState) => state.payment.currentPayment)
  const merchantConfigurations = useSelector((state: GlobalState) => state.configuration.walletConfiguration.merchantConfigurations);
  const publishableKey = useSelector((state: GlobalState) => state.configuration.publishableKey);
  const loader = useSelector((state: GlobalState) => state.payment.loader);
  const isManagementModal = useSelector((state: GlobalState) => state.payment.managementModal);

  const onChangeCvv = (evt: React.ChangeEvent<HTMLInputElement>) => setCvv(evt.target.value);

  useEffect(() => {
    return CreditCardManager.init(dispatch, true);
  }, []);

  const onVerify = async () => {
    dispatch(setLoader(true));
    dispatch(setCurrentPaymentOption('credit-card'));
    dispatch(setPaymentMethodSelected('creditcard', 'traditional'));
    if (isValidCvv(cvv, payment.issuerFull === "amex" ? 4 : 3)) {
      const merchantConfig = getMerchantConfig(MerchantsList.SPS_ECOMMERCE, merchantConfigurations) || {};
      const { data: { accessToken } } = await ApiClient(
        `/wallet/api/v1/merchants/${merchantConfig.id}/configurations?publishable_key=${publishableKey}`
      );
      getCVVId(merchantConfig.settings.client_key, cvv, accessToken)
    }
  }

  const onFocusCvv = () => {
    dispatch(setCurrentPaymentOption('credit-card'));
    dispatch(setPaymentMethodSelected('creditcard', 'traditional'));
  }

  const enterPress = async (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.keyCode === ENTER_KEY_CODE) {
      await onVerify();
    }
  }

  return (
    <div className={styles.container} data-test-id="cvv-confirmation-input">
      <MyInput
        value={cvv}
        onChange={onChangeCvv}
        onFocus={onFocusCvv}
        onKeyDown={enterPress}
        mask={payment.issuerFull === "amex" ? [/\d/, /\d/, /\d/, /\d/] : [/\d/, /\d/, /\d/]}
        guide={false}
        className={`${styles.middleInput} ${styles.input}`}
        id={CardFieldName.cvvNumber}
        placeholder={useTranslation("CVV_HINT") || "123"}
        placeholderFloating={useTranslation("CVV_HINT")}
        isAfterIconStatic={true}
        afterIcon={(
          <MyTooltip text={useTranslation("CARD_CVV_TOOLTIP")} maxWidthTooltip={270}>
            <MyIcon type="info" />
          </MyTooltip>
        )}
        tooltip={(
          <MyTooltip text={useTranslation("CARD_CVV_TOOLTIP")} maxWidthTooltip={270}/>
        )}
        accessibilityLabel="CVV field"
        inputmode
      />
      <MyButton
        disabled={!cvv || !isValidCvv(cvv, payment.issuerFull === "amex" ? 4 : 3)}
        type="primary"
        onClick={onVerify}
        className={styles.button}
        label={useTranslation("TEXT_BUTTON_VERIFY_CVV")}
      />
      {loader && !isManagementModal && (
        <div className={styles.loader}>
          <div className={styles.loaderRoot}>
            <Loader />
          </div>
        </div>
      )}
    </div>
  )
}
