import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import classnames from "classnames";

import { useCardAdd } from "../../hooks/useCardAdd";
import { useIsRTL } from "../../hooks/useIsRTL";
import { useTranslation } from "../../hooks/useTranslation";

import { acceptedCardsName, cardTypes } from "../AcceptedCards/AcceptedCardNames";

import { Tracking } from "../../services/AnalyticsService/GoogleAnalyticsService";

import { CardAddError } from "./CardAddError";
import { CardFieldName } from "../../components/MyInput/constants";
import { MyInput } from "../../components/MyInput/MyInput";
import { MyIcon } from "../../components/MyIcon/MyIcon";
import { Loader } from "../../components/Loader/Loader";
import { MyButton } from "../../components/MyButton/MyButton";
import { MyCheckbox } from "../../components/MyCheckbox/MyCheckbox";

import { MyTooltip } from "../../components/MyTooltip/MyTooltip";

import styles from "./CardAdd.module.scss";
import { setPaymentMethodSelected } from "../../store/payment/PaymentActions";
import { MyText } from "../../components/MyText/MyText";
import { MyCheckWalletService } from "../../services/MyCheckWalletService/WalletManager";
import { View } from "../../services/MyCheckWalletService/WalletServiceTypes";
import { Configuration } from "../../configs/Configuration";
import { getRecaptchaConfigs } from "../../helpers/getRecaptchaConfigs";
import { ReCaptchaV2, useRecaptchaV3 } from '@sgs/fe-common';

type CardAddType = {
  isSps: boolean
}

const RECAPTCHA_WINDOW_OBJ_APP_ID = 'wallet';

export const CardAdd: React.FunctionComponent<CardAddType> = ({ isSps }) => {
  const {
    getCardToken,
    onChangeNumber, onKeyDownNumber, onCvvChange, onPostalChange, onMonthYearChange, onStoreCardChange,
    cardNumberMask, monthYearMask, isFormValid, onRecaptchaChange,
    errorMessage, cvvError, monthYearError, numberError, postalError,
    cvv, postal, cardNumber, monthYear, storeCard, cardType, validateFieldOnBlur, setInProgress
  } = useCardAdd({ isSps });
  const isRTL = useIsRTL();
  const dispatch = useDispatch();
  const [isRecaptchaV2TokenGenerated, setIsRecaptchaV2TokenGenerated] = React.useState(false);

  const isGuest = useSelector((state: GlobalState) => state.account.isGuest);
  const isZipEnabled = useSelector((state: GlobalState) => state.configuration.walletConfiguration.sections.credit_cards.zip_code.active);
  const confirmButton = useSelector((state: GlobalState) => state.configuration.walletConfiguration.sections.credit_cards.confirm?.button);
  const isStoreCardEnabled = useSelector((state: GlobalState) => state.configuration.walletConfiguration.sections.credit_cards.temporary_card_support.active);
  const customConfig = useSelector((state: GlobalState) => state.configuration.customWalletConfiguration);
  const loader = useSelector((state: GlobalState) => state.payment.loader);
  const errorMessageLabel = useSelector((state: GlobalState) => state.payment.errorMessage);
  const isManagementModal = useSelector((state: GlobalState) => state.payment.managementModal);
  const cvvActive = useSelector((state: GlobalState) => state.configuration.walletConfiguration.sections.credit_cards.cvv?.active);
  const customConfigButton = useSelector((state: GlobalState) => state.configuration.customWalletConfiguration.confirm_button);
  const hasManageOpen = useSelector((state: GlobalState) => state.payment.managePaymentsShow);
  const isAdding = useSelector((state: GlobalState) => state.payment.isAdding);

  const zipLabel = useTranslation("ZIP_HINT");
  const checkboxLabel = useTranslation("CHECKBOX_ADD_CARD");
  const cardNumberLabel = useTranslation("CARD_NUMBER") || "1234-5678-9012-3456";
  const cardNumberTooltip = useTranslation("CARD_NUMBER_TOOLTIP");
  const cardExpLabel = useTranslation("EXP_HINT") || "mm/yy";
  const cardCVVLabel = useTranslation("CVV_HINT") || "123";
  const cardCVVTooltip = useTranslation("CARD_CVV_TOOLTIP");
  const buttonVerifyText = useTranslation("TEXT_BUTTON_VERIFY");
  const buttonAddCardText = useTranslation("ADD_CARD");
  const verifyCardText = useTranslation("VERIFY_CARD_TEXT");

  const cardInputRef = React.useRef<HTMLInputElement>(null);
  const monthYearRef = React.useRef<HTMLInputElement>(null);
  const cvvRef = React.useRef<HTMLInputElement>(null);
  const postalRef = React.useRef<HTMLInputElement>(null);

  const recaptchaConfigs = getRecaptchaConfigs();
  const isRecaptchaV2Active = recaptchaConfigs.isRecaptchaEnabled && recaptchaConfigs.isRecaptchaV2;

  const isSubmitButtonEnabled = isFormValid() && (isRecaptchaV2Active ? isRecaptchaV2TokenGenerated : true);

  const { executeRecaptcha } = useRecaptchaV3({
    siteKey: Configuration?.recaptcha_v3_key_enterprise, 
    windowObjAppId: RECAPTCHA_WINDOW_OBJ_APP_ID, 
    useEnterprise: recaptchaConfigs?.isEnterprise, 
    disabled: isRecaptchaV2Active
  });

  const handleReCaptchav3Verify = React.useCallback(async () => {
    if (!executeRecaptcha || !recaptchaConfigs.isRecaptchaEnabled || !recaptchaConfigs.isRecaptchaV3) {
      return;
    }
  
    const token = await executeRecaptcha('card_add');
    onRecaptchaChange(token);
  }, [executeRecaptcha, recaptchaConfigs.isRecaptchaEnabled]);

  React.useEffect(() => {
      handleReCaptchav3Verify();
  }, [handleReCaptchav3Verify]);

  const showCardStore =
    !isManagementModal &&
    customConfig.view === View.CHECKOUT &&
    isStoreCardEnabled &&
    !hasManageOpen && !isAdding &&
    !isGuest;

  const showConfirmButton =
    customConfigButton ||
    confirmButton ||
    confirmButton === undefined ||
    isManagementModal ||
    (hasManageOpen && isAdding) ||
    customConfig.view === View.MANAGE;
  
  const selectButtonText = (): string => {
    if (
      customConfig.view === View.CHECKOUT
      && customConfigButton
      && customConfigButton.text
      && !isManagementModal
      && !hasManageOpen
    ) {
      return customConfigButton.text;
    }
    if (isManagementModal || hasManageOpen) {
      return buttonAddCardText;
    }
    return buttonVerifyText;
  }

  const isCvvActive = !cvvActive && cvvActive !== undefined && cvvActive !== null ? false : true;
  const onChangeNumberInputCompleted = () => monthYearRef.current!.focus();

  const onChangeMonthYearInput = (evt: React.ChangeEvent<HTMLInputElement>) => onMonthYearChange(evt.target.value);
  const onChangeMonthYearInputCompleted = () => {
    if(!isCvvActive && isZipEnabled) {
      postalRef.current!.focus();
      return;
    }
    cvvRef.current!.focus();
  }

  const onChangeCvv = (evt: React.ChangeEvent<HTMLInputElement>) => onCvvChange(evt.target.value);
  const onChangeCvvInputCompleted = () => {
    if (isZipEnabled) {
      postalRef.current!.focus();
    }
  };

  const onChangePostal = (evt: React.ChangeEvent<HTMLInputElement>) => onPostalChange(evt.target.value);

  const onSave = async () => {
    setInProgress(true);
    if(recaptchaConfigs.isRecaptchaV3) {
      await handleReCaptchav3Verify();
    }
    Tracking.trackEvent(Tracking.EventAction.CreditCard, "button click save");
    MyCheckWalletService.events.paymentMethodSelected('creditcard', 'traditional');
    dispatch(setPaymentMethodSelected('creditcard', 'traditional'));

    await getCardToken();
    if(recaptchaConfigs.isRecaptchaV2) {
      setIsRecaptchaV2TokenGenerated(false);
    }
  };

  React.useEffect(() => {
    if (!customConfig) {
      return;
    }

    if (customConfig && customConfig.addCardAutofocus && (cardNumber.length < 16)) {
      setTimeout(() => {
        cardInputRef.current!.focus();
      }, 1000)
    }

    // TODO: check if buttons can be turned off?
    Tracking.trackEvent(Tracking.EventAction.CreditCard, "buttons impression");

  }, [customConfig]);

  const storeCardStyle = classnames(styles.storeCard, styles.bottomRowWrapper);
  const buttonWrapper = classnames(styles.buttonWrapper, styles.bottomRowWrapper);
  const rowClasses = classnames(styles.row, { [styles.rowRtl]: isRTL });
  const rowBottomClasses = classnames(styles.bottomRow, { [styles.rowRtl]: isRTL });

  let cardIcon: "grey_card" | keyof typeof acceptedCardsName | keyof typeof cardTypes = "grey_card";
  if (cardType) {
    cardIcon = cardType as keyof typeof acceptedCardsName;
  }

  if (cardType === "visa") {
    cardIcon = cardType as keyof typeof cardTypes;
  }

  if (!customConfig) {
    return <></>
  }

  const handleSetRecaptchaV2Token = (recaptchaToken: string) => {
    onRecaptchaChange(recaptchaToken);
    setIsRecaptchaV2TokenGenerated(!!recaptchaToken)
  }

  return (
      <form
        id="mywallet-sdk-form"
        className={styles.form}
        data-test-id="mywallet-sdk-form"
      >
        <div>
          <MyInput
            autoFocus={customConfig && customConfig.addCardAutofocus}
            ref={cardInputRef}
            value={cardNumber}
            hasError={numberError}
            autoComplete="cc-number"
            id={CardFieldName.cardNumber}
            placeholder={cardNumberLabel}
            accessibilityLabel="Credit card number field"
            placeholderFloating={cardNumberLabel}
            mask={cardNumberMask}
            onChange={onChangeNumber}
            onKeyDown={onKeyDownNumber}
            onBlur={validateFieldOnBlur("card")}
            onCompleted={onChangeNumberInputCompleted}
            isAfterIconStatic={true}
            afterIcon={(
              <MyTooltip text={cardNumberTooltip}>
                <MyIcon type="lock" />
              </MyTooltip>
            )}
            beforeIcon={(
              <MyIcon
                type={cardIcon}
                style={cardType ? { maxHeight: 16 } : { maxHeight: 30, objectPosition: '-3px' }}
              />
            )}
            tooltip={<MyTooltip text={cardNumberTooltip} />}
            inputmode
          />
        </div>
        <div className={rowClasses}>
          <MyInput
            value={monthYear}
            ref={monthYearRef}
            placeholder={cardExpLabel}
            className={isCvvActive ? styles.input : styles.inputCVVfalse}
            hasError={monthYearError}
            id={CardFieldName.expDate}
            accessibilityLabel="Expiration date field"
            placeholderFloating={cardExpLabel}
            mask={monthYearMask}
            onChange={onChangeMonthYearInput}
            onBlur={validateFieldOnBlur("date")}
            onCompleted={onChangeMonthYearInputCompleted}
            inputmode
          />
          {isCvvActive && (
            <MyInput
              ref={cvvRef}
              onChange={onChangeCvv}
              onBlur={validateFieldOnBlur("cvv")}
              onCompleted={onChangeCvvInputCompleted}
              value={cvv}
              mask={cardType === "amex" ? [/\d/, /\d/, /\d/, /\d/] : [/\d/, /\d/, /\d/]}
              guide={false}
              className={`${styles.middleInput} ${styles.input}`}
              id={CardFieldName.cvvNumber}
              placeholder={cardCVVLabel}
              placeholderFloating={cardCVVLabel}
              hasError={cvvError}
              isAfterIconStatic={true}
              afterIcon={(
                <MyTooltip text={cardCVVTooltip}>
                  <MyIcon type="info" />
                </MyTooltip>
              )}
              tooltip={(
                <MyTooltip text={cardCVVTooltip} />
              )}
              accessibilityLabel="CVV field"
              inputmode
            />
          )}
          {isZipEnabled && (
            <MyInput
              ref={postalRef}
              onBlur={validateFieldOnBlur("zip")}
              onChange={onChangePostal} value={postal}
              mask={[/\w/, /\w/, /\w/, /\w/, /\w/, /\w/, /\w/, /\w/, /\w/, /\w/, /\w/, /\w/, /\w/]}
              guide={false}
              id={CardFieldName.zipCode}
              className={`${isCvvActive ? styles.input : styles.inputCVVfalse} ${styles.microLabel}`}
              placeholder={zipLabel || "12345"}
              placeholderFloating={zipLabel}
              hasError={postalError}
              placeholderChar=" "
              accessibilityLabel="ZIP or Postal code field"
            />
          )}
        </div>

        {(errorMessageLabel || errorMessage) && <CardAddError errorMessage={errorMessageLabel || errorMessage} />}

        <div className={rowBottomClasses}>
          <div className={styles.buttons}>
            {showCardStore && (
              <div className={storeCardStyle}>
                <MyCheckbox isChecked={storeCard} onClick={onStoreCardChange} label={checkboxLabel} testId="card-store" />
              </div>
            )}
            {recaptchaConfigs.isRecaptchaEnabled && (
              <div className={styles.recaptcha}>
                {recaptchaConfigs.isRecaptchaV2 && !recaptchaConfigs.isRecaptchaV3 && (
                  <ReCaptchaV2 
                    windowObjAppId={RECAPTCHA_WINDOW_OBJ_APP_ID}
                    siteKey={Configuration.recaptcha_v2_key} 
                    callback={handleSetRecaptchaV2Token} 
                    expiredCallback={() => setIsRecaptchaV2TokenGenerated(false)}/>
                )}
              </div>
            )}
            {showConfirmButton && (
              <div className={buttonWrapper}>
                <MyText type={"four"} className={styles.verifyCardText}>
                  {verifyCardText}
                </MyText>
                <MyButton
                  disabled={!isSubmitButtonEnabled}
                  type="primary"
                  onClick={onSave}
                  className={styles.button}
                  label={selectButtonText()}
                  id={'saveButton'}
                />
              </div>
            )}
          </div>
        </div>

        {loader && (
          <div className={styles.loaderRoot}>
            <Loader />
          </div>
        )}
      </form>
  );
};