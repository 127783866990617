import React from "react";
import { Modal } from "../../components/Modal/Modal";
import { MyText } from "../../components/MyText/MyText";
import { MyButton } from "../../components/MyButton/MyButton";

import styles from "./ApplePayService.module.scss";
import { useTranslation } from "../../hooks/useTranslation";
import { useSelector } from "react-redux";

type ApplePayModalType = {
  isModalOpen: boolean;
  closePopup: () => void;
  confirmPayment: () => void;
};

export const ApplePayModal: React.FunctionComponent<ApplePayModalType> = (props) => {
  const { isModalOpen, closePopup, confirmPayment } = props;

  const redirect_text = useTranslation("APPLE_PAY_REDIRECT");
  const generalColor = useSelector((state: GlobalState) => state.configuration.walletConfiguration.general.button.color.primary);

  if(!isModalOpen) {
    return null;
  }

  return (
    <Modal
      isOpen={isModalOpen}
      onRequestClose={closePopup}
      contentLabel=""
      className={styles.applePayModal}
    >
      <MyText type="three" className={styles.applePayModalText}>
        {redirect_text}
      </MyText>

      <div style={{borderTop: `1px solid ${generalColor}`}}/>

      <MyButton type="yes" label="OK" onClick={confirmPayment}/>
    </Modal>
  );
};
