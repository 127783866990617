import { Configuration } from "../configs/Configuration";
import { ApiClient, ApiUrls } from "./ApiClient";

type PaymentMethodData = {
  tokenId: string,
  isSingleCardUse: number,
  merchantId?: string,
  zipcode?: string
  recaptchaToken?: string | null,
}

export const initSPS = (spsCallback: any) => {
  const script = document.createElement("script");
  script.src = Configuration.sps_domain;
  script.onload = spsCallback;
  document.head.appendChild(script);
}

export const getTokenId = (
  clientKey: string,
  card: string,
  expiry: string,
  accessToken: any
) => {
  const env = Configuration.env === "production" ? "prodIntl" : "testAws";
  //@ts-ignore
  return window.shijiStsGetTokenId(env, clientKey, card, expiry, accessToken);
}

export const getCVVId = (
  clientKey: string,
  cvv: string,
  accessToken: any
) => {
  const env = Configuration.env === "production" ? "prodIntl" : "testAws";
  //@ts-ignore
  window.shijiStsGetCVVId(env, clientKey, cvv, accessToken);
}

export const getSPSPaymentMethod = async (data: PaymentMethodData) => {
  try {
    const paymentBody: any = {
      source: "SPS_ECOMMERCE",
      isSingleUse: data.isSingleCardUse,
      extraParams: {
        tokenId: data.tokenId,
      }
    }

    if (data.merchantId) {
      paymentBody.merchantId = data.merchantId;
    }
    if (data.zipcode) {
      paymentBody.zipcode = data.zipcode;
    }
    if (data.recaptchaToken) {
      paymentBody.recaptchaToken = data.recaptchaToken;
    }

    await ApiClient(ApiUrls.addCreditCard, {
      domain: Configuration.api_domain,
      method: "POST",
      body: paymentBody,
    });
  } catch (err) {
    throw err;
  }
};

export const setSPSCallback = (callback: Function) => {
    //@ts-ignore
  window.registerCallback(callback);
}
