import { useEffect } from "react";
import { useSelector } from "react-redux";
import { DataDomeOptionsScriptId, DataDomeTagsJsScriptId, initDataDomeOptions, initDateDomeTagsJs } from "../services/DataDomeService";


export const useDataDome = () => {
  const isReady = useSelector((state: GlobalState) => state.configuration.appIsReady);
  const config = useSelector((state: GlobalState) => state.configuration.walletConfiguration);
  const isOptionsAppended = !!document.getElementById(DataDomeOptionsScriptId);
  const isTagsAppended = !!document.getElementById(DataDomeTagsJsScriptId);

  useEffect(() => {
    if(isReady && config?.sections?.dataDome.enabled) {
      if(!isOptionsAppended) {
        initDataDomeOptions();
      }
      if(!isTagsAppended) {
        initDateDomeTagsJs();
      }
    }
  }, [isReady, config?.sections?.dataDome.enabled, isOptionsAppended, isTagsAppended]);
}