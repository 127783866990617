class MyCheckWalletError extends Error {
  constructor(...args: any) {
    super(...args);
    this.name = "MyCheckWalletError";
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, MyCheckWalletError);
    }
  }
}

export const MyCheckWalletInitError = new MyCheckWalletError("MyCheckWallet was incorrectly initialized");
