import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { maskArray } from "react-text-mask";
import classNames from "classnames";

import { MyText } from "../../components/MyText/MyText";
import { MyInput } from "../../components/MyInput/MyInput";
import { onSetAmountToApply } from "../../store/giftCard/GiftCardActions";

import { useIsRTL } from "../../hooks/useIsRTL";
import { useTranslation } from "../../hooks/useTranslation";

import styles from "./GiftCardsAmountToApply.module.scss";
import { MyCheckWalletService } from "../../services/MyCheckWalletService/WalletManager";

type GiftCardsAmountToApplyType = {}

export const GiftCardsAmountToApply: React.FunctionComponent<GiftCardsAmountToApplyType> = () => {
  const dispatch = useDispatch();
  const isRTL = useIsRTL();

  const value = useSelector((state: GlobalState) => state.giftCard.amountToApply);
  const available = useSelector((state: GlobalState) => state.giftCard.balance);
  const isEnabled = useSelector((state: GlobalState) => state.giftCard.isApplyBucks);

  const setValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;

    if (value) {
      MyCheckWalletService.events.giftCardSelected({
        isSelected: true,
        balance: available,
        used: value,
        currency: MyCheckWalletService.getPaymentInfo().currency,
      });

      if (value === "") {
        dispatch(onSetAmountToApply(0));
      }

      dispatch(onSetAmountToApply(parseInt(event.target.value)));
    }
  };

  const resetValue = (event: React.FocusEvent<HTMLInputElement>) => {
    if(!event.target.value) {
      dispatch(onSetAmountToApply(0));
    }
  }

  const numberMask = (rawValue: string): maskArray => {
    // TODO: Error message on amount field
    if (available < parseInt(rawValue)) {
      return [`${available}`];
    }

    if (rawValue.length === 1) {
      return [/[0-9]/];
    }

    return [/[1-9]/, ...rawValue.split("").map(value => /[0-9]/)];
  };

  const rootClasses = classNames(styles.root, { [styles.rootRtl]: isRTL });
  return (
    <div className={rootClasses}>
      <MyText type="four" className={styles.label}>{useTranslation("AMOUNT_APPLY_GIFTCARD")}</MyText>
      <MyInput
        value={value}
        guide={false}
        mask={numberMask}
        isFloatingDisabled
        onChange={setValue}
        onBlur={resetValue}
        withCursorCorrection
        disabled={!isEnabled}
        className={styles.input}
        id="gift-cards-amount"
      />
    </div>
  );
};
