/** @jsx jsx */

import * as React from "react";
import { useSelector } from "react-redux";
import { jsx } from "@emotion/core";

import { composeTextStyle, FontGroups } from "../../helpers/CreateStyleFromConfig";

type MyTextType = {
  className?: string,
  style?: object,
  type: FontGroups,
}

export const MyText: React.FunctionComponent<MyTextType> = ({ children, type, className, style }) => {
  const { general } = useSelector((state: GlobalState) => state.configuration.walletConfiguration);
  const textStyles = composeTextStyle(general, type);

  return (
    <div css={{ ...textStyles, ...style } as any} className={className!}>
      {children}
    </div>
  );
};
