import {
  APP_IS_READY,
  ConfigurationActionTypes,
  FETCH_CONFIGURATION,
  FETCH_TRANSLATION,
  SET_CREDIT_CARDS,
  SET_CUSTOM_CONFIGURATION,
  SET_IFRAME_SIZE,
  SET_MERCHANT_CONFIGURATION,
  SET_PUBLISHABLE_KEY,
  SET_BUSINESS_DETAILS,
  SET_PAYMENT_DETAILS,
  SET_REMOVED_ALTERNATIVE_METHOD
} from "./ConfigurationTypes";
import { Configuration } from "../../configs/Configuration";
import { mergeDeep, prepareConfiguration } from "../../configs/PrepareConfig";
import { MyCheckWalletAuth } from "../../services/MyCheckWalletService/WalletManager";

const initialState: ConfigurationState = {
  publishableKey: "",
  env: "test",
  walletConfiguration: null,
  businessDetails: null,
  customWalletConfiguration: null,
  paymentDetails: null,
  themeConfiguration: null,
  isRTL: false,
  translation: {},
  domain: Configuration.api_domain,
  appIsReady: false,
  iframeSize: 200,
  removedAlternativeMethods: [],
};

export function configurationReducer(state = initialState, action: ConfigurationActionTypes): ConfigurationState {
  switch (action.type) {
    case FETCH_CONFIGURATION:
      return {
        ...state,
        walletConfiguration: prepareConfiguration(action.configuration, state.customWalletConfiguration?.locale),
        publishableKey: MyCheckWalletAuth.getInitialParams()?.publishableKey || "",
      };

    case SET_PUBLISHABLE_KEY:
      return {
        ...state,
        publishableKey: action.publishableKey
      }

    case SET_MERCHANT_CONFIGURATION:
      return {
        ...state,
        walletConfiguration: {
          ...state.walletConfiguration,
          merchantConfigurations: action.configurations,
        }
      }

    case FETCH_TRANSLATION:
      return {
        ...state,
        isRTL: action.isRTL,
        translation: action.translation
      };

    case SET_CREDIT_CARDS:
      return {
        ...state,
        walletConfiguration: {
          ...state.walletConfiguration,
          ...mergeDeep({}, state.walletConfiguration, action.creditCards)
        }
      };

    case APP_IS_READY:
      return {
        ...state,
        appIsReady: true
      };

    case SET_IFRAME_SIZE:
      return {
        ...state,
        iframeSize: action.size
      };

    case SET_CUSTOM_CONFIGURATION:
      return {
        ...state,
        customWalletConfiguration: action.config
      }
    
    case SET_PAYMENT_DETAILS:
      return {
        ...state,
        paymentDetails: action.details,
      }

    case SET_BUSINESS_DETAILS:
      return {
        ...state,
        businessDetails: action.details
      }

    case SET_REMOVED_ALTERNATIVE_METHOD:
      return {
        ...state,
        removedAlternativeMethods: [ ...state.removedAlternativeMethods, action.method ],
      }
  
    default:
      return state;
  }
}
