type Params = {
  height: number,
  borderRadius: number,
  borderWidth: number
}

export const calculatePaddingHorizontal = ({
  height = 0,
  borderWidth = 0,
  borderRadius = 0,
}: Params) => {
  height = Number(height.toString().replace('px', ''));
  borderWidth = Number(borderWidth.toString().replace('px', ''));
  borderRadius = Number(borderRadius.toString().replace('px', ''));

  let paddingHorizontal = borderRadius;

  if (borderRadius < 8) {
    paddingHorizontal = 8;
  }

  if (borderRadius * 2 > height) {
    paddingHorizontal = height / 2 - borderWidth * 2;
  }

  return { paddingHorizontal: `${paddingHorizontal}px` };
};
