export const APP_IS_READY = "APP_IS_READY";
export const FETCH_CONFIGURATION = "FETCH_CONFIGURATION";
export const FETCH_TRANSLATION = "FETCH_TRANSLATION";
export const SET_CREDIT_CARDS = "SET_CREDIT_CARDS";
export const SET_IFRAME_SIZE = "SET_IFRAME_SIZE";
export const SET_MERCHANT_CONFIGURATION = "SET_MERCHANT_CONFIGURATION";
export const SET_PUBLISHABLE_KEY = "SET_PUBLISHABLE_KEY";
export const SET_CUSTOM_CONFIGURATION = "SET_CUSTOM_CONFIGURATION";
export const SET_BUSINESS_DETAILS = "SET_BUSINESS_DETAILS";
export const SET_PAYMENT_DETAILS = "SET_PAYMENT_DETAILS";
export const SET_REMOVED_ALTERNATIVE_METHOD = "SET_REMOVED_ALTERNATIVE_METHOD";

type AppIsReadyAction = { type: typeof APP_IS_READY }
type FetchConfigurationAction = { type: typeof FETCH_CONFIGURATION, configuration: any }
type FetchTranslationAction = { type: typeof FETCH_TRANSLATION, isRTL: boolean, translation: any }
type SetCreditCardsAction = { type: typeof SET_CREDIT_CARDS, creditCards: object }
type SetIframeSizeAction = { type: typeof SET_IFRAME_SIZE, size: number }
type SetMerchantConfigurationAction = { type: typeof SET_MERCHANT_CONFIGURATION, configurations: any }
type SetPublishableKeyAction = { type: typeof SET_PUBLISHABLE_KEY, publishableKey: string }
type SetCustomConfiguration = { type: typeof SET_CUSTOM_CONFIGURATION, config: object }
type SetCustomPaymentDetails = { type: typeof SET_PAYMENT_DETAILS, details: object }
type SetBusinessDetails = { type: typeof SET_BUSINESS_DETAILS, details: any }
type SetRemovedAlternativeMethod = { type: typeof SET_REMOVED_ALTERNATIVE_METHOD, method: string }

export type ConfigurationActionTypes =
  AppIsReadyAction
  | FetchConfigurationAction
  | FetchTranslationAction
  | SetCreditCardsAction
  | SetMerchantConfigurationAction
  | SetIframeSizeAction
  | SetPublishableKeyAction
  | SetCustomConfiguration
  | SetBusinessDetails
  | SetCustomPaymentDetails
  | SetRemovedAlternativeMethod
