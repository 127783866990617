import * as React from "react";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";

import { acceptedCardsName } from "../AcceptedCards/AcceptedCardNames";

import { MyRadio } from "../../components/MyRadio/MyRadio";
import { MyDisplayCardInput } from "../../components/MyDisplayCardInput/MyDisplayCardInput";

import {
  setCurrentPaymentOption,
  setDefaultPayment,
  setMemorizedCard,
  setPaymentMethodSelected
} from "../../store/payment/PaymentActions";
import { useIsRTL } from "../../hooks/useIsRTL";

import styles from "../CreditCards/CreditCards.module.scss";
import { getPaymentMethodNameType } from "../../helpers/GetPaymentMethodNameType";
import { View } from "../../services/MyCheckWalletService/WalletServiceTypes";
import { MyCheckWalletService } from "../../services/MyCheckWalletService/WalletManager";

type ManageCreditCardRowType = {
  paymentMethod: PaymentMethod
}

export const ManageCreditCardRow: React.FunctionComponent<ManageCreditCardRowType> = ({ paymentMethod }) => {
  const dispatch = useDispatch();
  const isRTL = useIsRTL();

  const value = useSelector(
    (state: GlobalState) =>
      state.payment.cards.find((e) => e.isDefault)! ||
      state.payment.currentPayment
  );
  const currentView = useSelector((state: GlobalState) => state.configuration.customWalletConfiguration.view);
  const isManagementModal = useSelector((state: GlobalState) => state.payment.managementModal);
  const memorizedCard = useSelector((state: GlobalState) => state.payment.memorizedCard);
  const managePaymentsShow = useSelector((state: GlobalState) => state.payment.managePaymentsShow);

  const memorizeCardMode = (isManagementModal || managePaymentsShow) && currentView === View.CHECKOUT;

  const onClickHandler = async () => {
    const { name, type } = getPaymentMethodNameType(paymentMethod)
    MyCheckWalletService.events.paymentMethodSelected(name, type)
    dispatch(setPaymentMethodSelected(name, type));
    dispatch(setCurrentPaymentOption('credit-card'));
    if (currentView === View.MANAGE) {
      await dispatch(setDefaultPayment(paymentMethod.id, paymentMethod.ccToken))
    }
    if (memorizeCardMode) {
      await dispatch(setMemorizedCard(paymentMethod));
    }
    MyCheckWalletService.events.cardTokenReceived(paymentMethod.ccToken)
  };

  const rowClasses = classNames(styles.row, { [styles.rowRtl]: isRTL });

  let paymentMethodValue = paymentMethod.last4Digits;
  if (paymentMethod.issuerFull === 'paypal' && paymentMethod.externalId) {
    paymentMethodValue = paymentMethod.externalId;
  }

  if (paymentMethod.source === 'APPLE_PAY') {
    paymentMethodValue = "";
  }

  return (
    <div className={rowClasses} data-test-id={`${paymentMethod.issuerFull}-card-row`}>
      <MyRadio
        id={`${paymentMethod.issuerFull}-card-row-radio`}
        value={memorizeCardMode && memorizedCard ? memorizedCard.id : value.ccToken}
        onClick={onClickHandler}
        option={{ value: memorizeCardMode && memorizedCard ? paymentMethod.id : paymentMethod.ccToken, label: "" }}
      />
      <MyDisplayCardInput
        className={styles.displayInput}
        id={paymentMethod.id}
        value={paymentMethodValue}
        isRemoveAvailable
        issuer={paymentMethod.issuerFull as keyof typeof acceptedCardsName}
        isSingleUse={paymentMethod.isSingleUse}
        source={paymentMethod.source}
        data-test-id={'displayCardInput'}
      />
    </div>
  );
};
