import {
  fetchPayments,
  onAuthorize3DS,
  onCancelAdd,
  setCurrentPayment,
  setPaymentMethodSelected,
  setCurrentPaymentOption,
  setErrorMessage,
  setLoader,
  setSuccessCardPopup,
  setMemorizedCard,
  setFailureAddingCardId,
} from "../../store/payment/PaymentActions";
import { store } from "../../store";
import { getCVVId, getSPSPaymentMethod, initSPS, setSPSCallback } from "../../services/SPSService";
import { CardValidationError } from "../../hooks/useCardAdd";
import { EncryptedCVVManagerHelper } from "../../helpers/EncryptedCVVManager";
import {
  getMerchantConfig,
  getPaymentName,
  getPaymentType,
  MerchantsList
} from "../../helpers/helpers";
import { parseWalletMSError } from "../../helpers/ParseError";
import {Fingerprint} from "../../services/MyCheckWalletService/WalletServiceTypes";
import { MyCheckWalletService } from "../../services/MyCheckWalletService/WalletManager";
import { create_UUID } from "../../helpers/CreateUUID";
import { getRecaptchaConfigs } from "../../helpers/getRecaptchaConfigs";
import { resetRecaptchaV2 } from "../../helpers/resetRecaptchaV2";

export interface ToCardResponse {
  cc_token: string,
  encrypted_cvv: string | null,
  type: string,
  name: string,
  fingerprint: Fingerprint | null,
  creditType: string,
  creditTypeFull: string,
  expMonth: string | number,
  expYear4: number | null,
  last4Digits: string | number,
  first6Digits: string | number,
  source: string,
  external_token: string | number,
}

let _tokenResolver: any = null;
let _tokenRejecter: any = null;

const setNewToken = () => new Promise((resolve, reject) => {
  _tokenResolver = resolve;
  _tokenRejecter = reject;
}).catch(err => MyCheckWalletService.events.error('Set new token error', err));

let _token = setNewToken();

const toCardResponse = async (value: any): Promise<ToCardResponse> => ({
  cc_token: value.ccToken,
  encrypted_cvv: value.encrypted_cvv || null,
  external_token: value.externalToken,
  type: getPaymentType(value),
  name: getPaymentName(value),
  fingerprint: await window.mycheck.wallet.get3DSecureSignature() || null,
  creditType: value.issuerShort,
  creditTypeFull: value.issuerFull,
  expMonth: value.expMonth,
  expYear4: value.expYear4 ? parseInt(value.expYear4) : null,
  last4Digits: value.last4Digits,
  first6Digits: value.first6Digits,
  source: value.source,
});

export const addCard = async () => {
  const response = await _token;
  return await toCardResponse(response);
}

export const getCard = async () => {
  const currentActiveCard = store.getState().payment.currentPayment
  return await toCardResponse(currentActiveCard);
};

class CreditCardManagerProvider {
  dispatch: any = null;
  cvvConfig: any = null
  isCardSingleUse: number = 0;
  zipcode: string = "";
  recaptchaToken: string | null = null;

  init(dispatch: any, triggerSPS?: boolean) {
    this.dispatch = dispatch;
    if (triggerSPS) {
      initSPS(() => setSPSCallback(this.getCVVAndTokenID));
    }
  }

  getCVVAndTokenID = (e: any) => {
    if (e.tokenId) {
      this._setTokenId(e.tokenId);
    } else if (e.cvvId) {
      this._setCVVID(e.cvvId);
    }
  }

  private _setTokenId = async (tokenId: string) => {
    const recaptchaConfigs = getRecaptchaConfigs();
    try {
      const merchantConfigs = store.getState().configuration.walletConfiguration.merchantConfigurations;
      const merchantConfig = getMerchantConfig(MerchantsList.SPS_ECOMMERCE, merchantConfigs)
      const merchantId = store.getState().configuration.customWalletConfiguration.merchant_id || merchantConfig?.id;

      await getSPSPaymentMethod({ 
        tokenId,
        isSingleCardUse: this.isCardSingleUse,
        merchantId,
        zipcode: this.zipcode,
        recaptchaToken: recaptchaConfigs.isRecaptchaEnabled ? this.recaptchaToken : null,
      });
      await this.dispatch(fetchPayments());

      const currentPayment: PaymentMethod = store.getState().payment.cards.find(e => e.isDefault)!;

      this.dispatch(setCurrentPaymentOption("credit-card"));
      this.dispatch(setCurrentPayment(currentPayment));
      this.dispatch(onAuthorize3DS(currentPayment.token));

      MyCheckWalletService.events.cardTokenReceived(currentPayment.ccToken);

      _tokenResolver(currentPayment.ccToken);
      _token = setNewToken();

      this.dispatch(onCancelAdd());

      if (this.cvvConfig) {
        getCVVId(this.cvvConfig.clientKey, this.cvvConfig.cvv, this.cvvConfig.accessToken)
      }
    } catch (err) {
      _tokenRejecter(CardValidationError);
      _token = setNewToken();
      // @ts-ignore
      if (err.errorId) {
        // @ts-ignore
        this.dispatch(setErrorMessage(parseWalletMSError(err.errorId)));
        this.dispatch(setLoader(false));
        MyCheckWalletService.cardAddFailureEvent(err);
        if (recaptchaConfigs.isRecaptchaEnabled) {
          this.dispatch(setFailureAddingCardId(create_UUID()));
        }
        resetRecaptchaV2();
      }
    }
  }

  private _setCVVID = async (cvvId: string) => {
    const cvvActive = store.getState().configuration.walletConfiguration.sections.credit_cards.cvv?.active;
    const isCvvActive = !cvvActive && cvvActive !== undefined && cvvActive !== null ? false : true;

    const currentActiveCard = { ...store.getState().payment.currentPayment, encrypted_cvv: isCvvActive ? cvvId: null };
    this.dispatch(setCurrentPayment(currentActiveCard))
    this.dispatch(setMemorizedCard(currentActiveCard));

    if(isCvvActive) {
      MyCheckWalletService.events.encryptedCvvReceived(cvvId);
    }

    if (!store.getState().payment.managePaymentsShow) {
      const { ccToken } = store.getState().payment.currentPayment;
      await MyCheckWalletService.checkIsCheckoutReady({
        cc_token: ccToken,
        encrypted_cvv: isCvvActive ? cvvId : null,
      });
    }
    if (cvvId && isCvvActive) {
      EncryptedCVVManagerHelper.addRecord({
        cardID: currentActiveCard.id,
        encryptedCVV: cvvId,
        encryptionStartTimeStamp: new Date(),
      });
    }
    this.dispatch(setLoader(false));
    this.dispatch(setSuccessCardPopup(true));
  }

  onGetCardCancel = () => {
    this.dispatch(onCancelAdd());
  }

  onGetCardSuccess = async (data: PaymentMethod) => {
    await this.dispatch(fetchPayments());
    const currentPayment: PaymentMethod = store.getState().payment.cards.find(card => card.id === data.id)!;
    this.dispatch(setCurrentPayment(currentPayment));
    this.dispatch(setMemorizedCard(currentPayment));

    MyCheckWalletService.events.cardTokenReceived(currentPayment.ccToken);
    this.dispatch(onAuthorize3DS(currentPayment.token));
    await MyCheckWalletService.checkIsCheckoutReady({
      cc_token: currentPayment.ccToken,
    });

    _tokenResolver(data.ccToken);
    _token = setNewToken();

    this.dispatch(onCancelAdd());
    this.dispatch(setSuccessCardPopup(true));
  }

  onGetCardFailure = (err: any) => {
    _tokenRejecter(err);
    _token = setNewToken();
  }

  onFormValid = (isReady: any) => {
    MyCheckWalletService.events.addCardFormReady(isReady);
    MyCheckWalletService.events.paymentMethodSelected('creditcard', 'traditional')
    this.dispatch(setPaymentMethodSelected('creditcard', 'traditional'));
  }

  onTyping = () => {
    this.dispatch(setCurrentPaymentOption("credit-card"));
  }

  setEncryptedCvv = (data: any) => {
    this.cvvConfig = data;
  }

  setIsSingleUse = (isSingleUse: number) => {
    this.isCardSingleUse = isSingleUse;
  }

  setZip = (zipcode: string) => {
    this.zipcode = zipcode;
  }
  setRecaptchaToken = (recaptchaToken: string | null) => {
    this.recaptchaToken = recaptchaToken;
  }
};

export const CreditCardManager = new CreditCardManagerProvider();
