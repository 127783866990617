import * as React from "react";
import { Button, ExpansionPanelDetails, ExpansionPanelSummary, ExpansionPanel, Typography } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import styles from './Functions.module.scss'

type FunctionsType = {
  handleEvent: (event: string, value: any) => void,
  isReady: boolean
  expanded: string
  setExpanded: (value: string) => void
  setIsNotReady: () => void
}

export const Functions: React.FunctionComponent<FunctionsType> = ({
                                                                    handleEvent,
                                                                    isReady,
                                                                    expanded,
                                                                    setExpanded,
                                                                    setIsNotReady
                                                                  }) => {
  const onAccessToken = async () => {
    const result = await window.mycheck.auth.getAccessToken();
    handleEvent("getAccessToken", result);
  };

  const onDestroy = async () => {
    setIsNotReady();
    const result = await window.mycheck.wallet.destroy();
    handleEvent("destroy", result);
  };

  const onPaymentMethod = async () => {
    const result = await window.mycheck.wallet.getPaymentMethod();
    handleEvent("getPaymentMethod", JSON.stringify(result));
  };

  const onGetUserInformation = async () => {
    const result = await window.mycheck.wallet.getUserInformation();
    handleEvent("getUserInformation", JSON.stringify(result));
  };

  const onMakeInstantPayment = async () => {
    const result = await window.mycheck.wallet.makeInstantPayment();
    handleEvent("makeInstantPayment", JSON.stringify(result));
  };

  // @ts-ignore
  const onChange = () => setExpanded(v => v === "functions" ? "" : "functions")

  return (
    <>
      <ExpansionPanel square variant="outlined" expanded={expanded === "functions"} onChange={onChange}>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">
            Functions
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <div>
            <Button
              disabled={!isReady} color="primary" variant="contained" onClick={onDestroy} fullWidth
              className={styles.button}
            >
              destroy
            </Button>
            <Button
              disabled={!isReady} color="primary" variant="contained" onClick={onAccessToken} fullWidth
              className={styles.button}
            >
              getAccessToken
            </Button>
            <Button
              disabled={!isReady} color="primary" variant="contained" onClick={onPaymentMethod} fullWidth
              className={styles.button}
            >
              getPaymentMethod
            </Button>
            <Button
              disabled={!isReady} color="primary" variant="contained" onClick={onGetUserInformation} fullWidth
              className={styles.button}
            >
              getUserInformation
            </Button>
            <Button
              disabled={!isReady} color="primary" variant="contained" onClick={onMakeInstantPayment} fullWidth
              className={styles.button}
            >
              makeInstantPayment
            </Button>
          </div>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </>
  );
};
