import * as React from "react";
import { attachPayPal, attachPayPalCollector } from "../../helpers/FetchScript";
import { Configuration } from "../../configs/Configuration";
import { ApiClient, ApiUrls } from "../ApiClient";
import { useDispatch, useSelector } from "react-redux";
import {
  checkCardPayment,
  fetchPayments,
  onCancelAdd,
  setCurrentPayment,
  setCurrentPaymentOption,
  setErrorMessage,
  setLoader,
  setMemorizedCard,
  setPaymentMethodSelected,
  setSuccessCardPopup
} from "../../store/payment/PaymentActions";
import { AlternativeWalletWrapper } from "../../containers/AlternativeWallets/AlternativeWalletWrapper";
import { Tracking } from "../AnalyticsService/GoogleAnalyticsService";
import { useRevertPaymentSelection } from "../../hooks/useRevertPaymentSelection";
import { parseAlternativeError, parseWalletMSError } from "../../helpers/ParseError";
import { useTranslation } from "../../hooks/useTranslation";
import styles from "./BraintreeService.module.scss";
import { MerchantsList } from "../../helpers/helpers";
import { MyCheckWalletService } from "../MyCheckWalletService/WalletManager";

const BRAINTREE_TYPE: string = "Braintree";
type BraintreeServiceType = {
};

let _token = "";
let _deviceData: any = null;
let _nonce: any = null;

const buttonId = "BraintreeCheckout";

export const BraintreeService: React.FunctionComponent<BraintreeServiceType> = () => {
  const dispatch = useDispatch();
  const useRevertPayment = useRevertPaymentSelection()

  const accessToken = useSelector((state: GlobalState) => state.account.accessToken);
  const currentPayment = useSelector((state: GlobalState) => state.payment.cards.find(e => e.isDefault)!);
  const errorLabelFilterCard = useTranslation("ERROR_FILTER_CARD");

  const buttonIdElement = document.getElementById(buttonId);

  const loadPayPal = async () => {
    if (accessToken) {
      await attachPayPal();
      await getToken();
      await initPayPalService();
    }
  };

  React.useEffect(() => {
    loadPayPal();
  }, [buttonId, accessToken]);

  const getToken = async () => {
    const { token } = await ApiClient(ApiUrls.getPayPalToken(accessToken!), {
      domain: Configuration.api_domain
    });

    _token = token;
  };

  const getDeviceData = async () => {
    await attachPayPalCollector();

    const clientInstance = await window.braintree.client.create({ authorization: _token });
    const dataCollectorInstance = await window.braintree.dataCollector.create({ client: clientInstance, paypal: true });
    _deviceData = dataCollectorInstance.deviceData;
  };

  const initPayPalService = async () => {
    const clientInstance = await window.braintree.client.create({ authorization: _token });
    const paypalCheckoutInstance = await window.braintree.paypalCheckout.create({ client: clientInstance });
    
    if (buttonIdElement?.hasChildNodes()) {
      buttonIdElement.innerHTML = "";
    }

    return window.paypal.Button.render({
      env: Configuration.env === 'test' || Configuration.env === 'sandbox' ? 'sandbox' : 'production',
      payment: function () {
        return paypalCheckoutInstance.createPayment({ flow: "vault" });
      },
      onAuthorize: async (data: any) => {
        Tracking.trackEvent(Tracking.EventAction.AlternativePMClick, BRAINTREE_TYPE);

        const payload = await paypalCheckoutInstance.tokenizePayment(data);
        _nonce = payload.nonce;

        MyCheckWalletService.events.addUserInformation(payload.details)

        await paypalSendToServer();
        await dispatch(fetchPayments());
      },
      onClick: () => {
        useRevertPayment.seal();
        MyCheckWalletService.events.paymentMethodSelected('braintree', 'alternative');
        dispatch(setPaymentMethodSelected('braintree', 'alternative', true));
        dispatch(setCurrentPaymentOption('credit-card'));
        MyCheckWalletService.checkIsCheckoutReady({});
        dispatch(setLoader(true));
      },
      onCancel: () => {
        dispatch(checkCardPayment());
        dispatch(setLoader(false));
        return useRevertPayment.revert();
      },
      onError: () => {
        useRevertPayment.revert();
        dispatch(setLoader(false));
      },
      style: {
        shape: "rect",
        size: "large",
        color: "black",
        label: "paypal",
        tagline: false,
        height: 45,
        disableMaxWidth: true
      }
    }, buttonId);
  };

  const paypalSendToServer = async () => {
    await getDeviceData();
    try {
      const response = await ApiClient(ApiUrls.addCreditCard, {
        domain: Configuration.api_domain,
        method: "POST",
        body: {
          source: "BRAINTREE",
          accessToken: accessToken,
          extraParams: {
            deviceData: _deviceData,
            nonce: _nonce,
          },
        },
      });

      await dispatch(fetchPayments());
      await dispatch(setCurrentPayment(currentPayment));
      await dispatch(fetchPayments());
      await dispatch(setMemorizedCard(response));
      MyCheckWalletService.checkIsCheckoutReady({
        cc_token: currentPayment.ccToken,
      });
      dispatch(setCurrentPaymentOption('credit-card'))
      MyCheckWalletService.events.cardTokenReceived(currentPayment.ccToken);
      dispatch(setLoader(false));

      dispatch(onCancelAdd());
      dispatch(setSuccessCardPopup(true));
    } catch(error) {
      // @ts-ignore
      if (error.errorId) {
        // @ts-ignore
        dispatch(setErrorMessage(parseWalletMSError(error.errorId)));
      } else {
        dispatch(setErrorMessage(parseAlternativeError(error) || errorLabelFilterCard));
      }
      MyCheckWalletService.cardAddFailureEvent(error, MerchantsList.BRAINTREE);
      dispatch(setLoader(false));
    }
  };

  return (
    <div id={buttonId} aria-label="PayPal button" role="button" className={styles.paypalButton}/>
  )
};
