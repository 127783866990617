type TimestampType = {
  created_at: string,
  updated_at: string,
}

export const parseTimestamp6 = () => {
  //YYYY-MM-DDbHH:MI:SS.ssssss
  const date = new Date()
  const day = date.getUTCDate() < 10 ? `0${date.getUTCDate()}` : date.getUTCDate()
  const year = date.getUTCFullYear()
  const month = date.getUTCMonth() < 10 ? `0${date.getUTCMonth()+1}` : date.getUTCMonth()+1
  const hours = date.getUTCHours() < 10 ? `0${date.getUTCHours()}` : date.getUTCHours()
  const minutes = date.getUTCMinutes() < 10 ? `0${date.getUTCMinutes()}` : date.getUTCMinutes()
  const seconds = date.getUTCSeconds() < 10 ? `0${date.getUTCSeconds()}` : date.getUTCSeconds()
  const ms = date.getUTCMilliseconds() < 10 ? `0${date.getUTCMilliseconds()}` : date.getUTCMilliseconds()

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}:${ms}`
}

export const createTimestampValues = (firstTimestamp: string | null, lastTimestamp: string) => {
  let timestampValues: TimestampType = {
    created_at: "",
    updated_at: "",
  };

  if (!firstTimestamp) {
    timestampValues = {
      created_at: lastTimestamp,
      updated_at: lastTimestamp,
    }
  }
  if (firstTimestamp) {
    timestampValues = {
      created_at: firstTimestamp,
      updated_at: lastTimestamp,
    }
  }
  return timestampValues;
}