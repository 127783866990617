export default {
  env: "production",
  iframe: "https://wallet-sdk-v3.mycheckapp.com",
  recaptcha_v2_key: "6LfMBVQpAAAAAP6CCcs0YOIxpDXiWizX4_adULUX",
  recaptcha_v3_key_enterprise: "6LeQ3YopAAAAALtr5GzLCeKwbAQhgc1uDmocuFQR",
  data_dome_key: "7160F1660D94CEA29EF2EBFDCB7E04",
  data_dome_tags_js: "https://js.datadome.co/tags.js",
  assets: "https://wallet-sdk-v3.mycheckapp.com",
  sps_domain: "https://cdn.shijipayment.com/sts/sts.proxy.v2.1.js",
  sps_alternatives_domain: "https://cdn.shijipayment.com/libraries/shijipayments.latest.min.js",
  api_domain: "https://the.mycheckapp.com",
  masterpass: "https://masterpass.com/integration/merchant.js",
  google_pay: "https://pay.google.com/gp/p/js/pay.js",
  google_analytics: "https://www.google-analytics.com/analytics.js",
  paypal: "https://www.paypalobjects.com/api/checkout.js",
  paypal_client: "https://js.braintreegateway.com/web/3.44.2/js/client.min.js",
  paypal_checkout: "https://js.braintreegateway.com/web/3.44.2/js/paypal-checkout.min.js",
  paypal_collector: "https://js.braintreegateway.com/web/3.44.2/js/data-collector.min.js",
  firebaseSettings: {
    apiKey: "AIzaSyCrSqs4fIWxedEhjJJ-G0vWgzwfpaebNN4",
    authDomain: "spherical-jetty-233112.firebaseapp.com",
    databaseURL: "https://spherical-jetty-233112.firebaseio.com",
    projectId: "spherical-jetty-233112",
    storageBucket: "spherical-jetty-233112.appspot.com",
    messagingSenderId: "1019064257562",
    appId: "1:1019064257562:web:b7b5346dbf141d7d"
  },
  sentry: {
    dsn: "https://73a21eb1f02adf02cb99892fc7add451@o210774.ingest.us.sentry.io/4508177283350528",
    env: "production"
  }
};
