import { RECAPTCHA_URL } from '@internal/constants/recaptchaConstants';
import useRecaptchaScript from '@internal/hooks/useRecaptchaScript';
import { useCallback, useEffect } from 'react';

export interface UseRecaptchaV3Props {
  siteKey: string;
  windowObjAppId: string;
  useEnterprise?: boolean;
  disabled?: boolean;
}

const useRecaptchaV3 = ({
  siteKey,
  windowObjAppId,
  useEnterprise = true,
  disabled = false,
}: UseRecaptchaV3Props) => {
  const SCRIPT_ID = `${windowObjAppId}RecaptchaV3${
    useEnterprise ? 'Enterprise' : ''
  }`;

  const {
    appendRecaptchaScript,
    clearRecaptcha,
    isRecaptchaLoaded,
    setIsRecaptchaLoaded,
    removeRecaptchaScriptsIfExist,
  } = useRecaptchaScript({
    scriptId: SCRIPT_ID,
    windowObjAppId,
  });

  const onRecaptchaLoadCallback = useCallback(() => {
    window[windowObjAppId] = {};

    const grecaptcha = useEnterprise
      ? window.grecaptcha.enterprise
      : window.grecaptcha;

    window[windowObjAppId].grecaptchaV3 = { ...grecaptcha };
    window[windowObjAppId].grecaptchaV3.ready(() => {
      setIsRecaptchaLoaded(true);
    });
  }, [useEnterprise, windowObjAppId, setIsRecaptchaLoaded]);

  const appendRecaptchaV3Script = useCallback(() => {
    const scriptSource = useEnterprise ? 'enterprise.js' : 'api.js';
    const scriptSrc = `${RECAPTCHA_URL}/${scriptSource}?render=${siteKey}&onload=onRecaptchaLoad`;
    appendRecaptchaScript(scriptSrc, onRecaptchaLoadCallback);
  }, [appendRecaptchaScript, onRecaptchaLoadCallback, siteKey, useEnterprise]);

  useEffect(() => {
    if (disabled) {
      return;
    }
    removeRecaptchaScriptsIfExist();

    if (!window[windowObjAppId]?.grecaptchaV3) {
      appendRecaptchaV3Script();
    } else {
      setIsRecaptchaLoaded(true);
    }

    return () => {
      clearRecaptcha();
    };
  }, [
    appendRecaptchaV3Script,
    clearRecaptcha,
    disabled,
    removeRecaptchaScriptsIfExist,
    setIsRecaptchaLoaded,
    windowObjAppId,
  ]);

  const executeRecaptcha = useCallback(
    async (action) => {
      if (isRecaptchaLoaded && window[windowObjAppId]?.grecaptchaV3) {
        const recaptchaToken = await window[
          windowObjAppId
        ].grecaptchaV3.execute(siteKey, {
          action,
        });
        return recaptchaToken;
      }
      return null;
    },
    [isRecaptchaLoaded, siteKey, windowObjAppId],
  );

  return { executeRecaptcha };
};

export default useRecaptchaV3;
