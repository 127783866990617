import { useCallback, useState } from 'react';

interface UseRecaptchaScriptProps {
  scriptId: string;
  windowObjAppId: string;
  useEnterprise?: boolean;
}

const useRecaptchaScript = ({
  scriptId,
  windowObjAppId,
}: UseRecaptchaScriptProps) => {
  const [isRecaptchaLoaded, setIsRecaptchaLoaded] = useState(false);

  const removeRecaptchaScriptsIfExist = useCallback(() => {
    const recaptchaV2Script = document.getElementById(scriptId);
    if (recaptchaV2Script) {
      recaptchaV2Script.remove();
    }
    const gstaticScript = document.querySelector(
      `script[src^="https://www.gstatic.com/recaptcha/releases"]`,
    );

    if (gstaticScript) {
      gstaticScript.remove();
    }
  }, [scriptId]);

  const clearRecaptcha = useCallback(() => {
    removeRecaptchaScriptsIfExist();
    window[windowObjAppId] = undefined;
    window.onRecaptchaLoad = null;
    setIsRecaptchaLoaded(false);
  }, [removeRecaptchaScriptsIfExist, windowObjAppId]);

  const appendRecaptchaScript = useCallback(
    (scriptSrc: string, onRecaptchaLoadCallback: () => void) => {
      window.onRecaptchaLoad = onRecaptchaLoadCallback;
      const script = document.createElement('script');
      script.src = scriptSrc;
      script.async = true;
      script.id = scriptId;
      document.head.appendChild(script);
    },
    [scriptId],
  );

  return {
    removeRecaptchaScriptsIfExist,
    clearRecaptcha,
    appendRecaptchaScript,
    isRecaptchaLoaded,
    setIsRecaptchaLoaded,
  };
};

export default useRecaptchaScript;
