import { store } from '../store'
import { resetCVVForCard } from '../store/payment/PaymentActions'
import { DebugLogger } from '../services/DebugLogger'

import * as storage from "../helpers/Storage";
import { MyCheckWalletService } from '../services/MyCheckWalletService/WalletManager';

const CVV_RECORD_STORAGE_IDENTIFIER: string = 'CVV_RECORDS'
const DEFAULT_EXPIRATION_TIME: number = 10

const CVV_EXPIRATION_TIMEOUT = 600000;
const CVV_CHECK_INTERVAL = 1000;

export type CVVRecord = {
  cardID: string,
  encryptedCVV: string,
  encryptionStartTimeStamp: Date | string,
}

export interface IEncryptedCVVManager {
  addRecord: (record: CVVRecord) => void;
  calculateExpirationFor: (cardID: string) => void;
  getRecordBy: (cardID: string) => CVVRecord | null;
  removeRecord: (card: CVVRecord) => void;
}

const printLogForCVV = (record: CVVRecord | null, message: string) => {
  if (!record) {
    DebugLogger.logStart('No CVV record found in LocalStorage')
    return
  }

  DebugLogger.logStart(message)
  DebugLogger.log({
    cardID: record.cardID,
    encryptionStart: new Date(record.encryptionStartTimeStamp).toLocaleTimeString(),
    encryptionEnd: new Date(
      new Date(record.encryptionStartTimeStamp).getTime()
      + DEFAULT_EXPIRATION_TIME * 60000).toLocaleTimeString(),
    encryptedCVV: record.encryptedCVV,
  })
  DebugLogger.logEnd()
}

class EncryptedCVVManager implements IEncryptedCVVManager {
  private _interval: NodeJS.Timer | null = null;

  public addRecord(record: CVVRecord): void {
    try {
      const CVVRecords: CVVRecord[] = EncryptedCVVManager._getRecords()
      const isRecordExist = this.getRecordBy(record.cardID)

      if(!isRecordExist) {
        EncryptedCVVManager._updateRecords([...CVVRecords, record])
        printLogForCVV(record, 'Added CVV record to LocalStorage')
        this.calculateExpirationFor(record.cardID)
      }
    } catch (err) {
      DebugLogger.log(err)
    }
  }

  public removeRecord(CVVRecord: CVVRecord | null): void {
    try {
      printLogForCVV(CVVRecord, 'Removed CVV record from LocalStorage')
      if (!CVVRecord) {
        return
      }

      const CVVRecords: CVVRecord[] = EncryptedCVVManager._getRecords()

      EncryptedCVVManager._updateRecords(CVVRecords.filter(record => record.cardID !== CVVRecord.cardID))
    } catch (err) {
      console.log(err)
    }
  }

  public getRecordBy(cardID?: string): CVVRecord | null {
    try {
      if (!cardID) {
        return null
      }

      const CVVRecords: CVVRecord[] = EncryptedCVVManager._getRecords()
      return CVVRecords.find(record => record.cardID === cardID)!
    } catch (err) {
      return null
    }
  };

  public calculateExpirationFor(cardID: string): void {
    const CVVRecord = this.getRecordBy(cardID)

    printLogForCVV(CVVRecord, 'Trigger check of CVV expiration for record')
    if (CVVRecord) {
			const { encryptionStartTimeStamp } = CVVRecord;
			if (this._interval) {
				clearInterval(this._interval);
				this._interval = null;
			}

			this._interval = setInterval(() => {
				// @ts-ignore
				const CVVIntervalTimePassed = new Date() - new Date(encryptionStartTimeStamp);
				if (CVVIntervalTimePassed >= CVV_EXPIRATION_TIMEOUT) {
					clearInterval(<NodeJS.Timeout>this._interval);
					this._interval = null;
					this._onCVVExpired(cardID);
					return true;
				}
			}, CVV_CHECK_INTERVAL);
		}
  }

  private _onCVVExpired = (cardID: string): void => {
    const CVVRecord: CVVRecord | null = this.getRecordBy(cardID)

    printLogForCVV(CVVRecord, 'CVV expired!')

    MyCheckWalletService.events.error({
      massage: 'CVV of current card expired',
      reason: 'CVV_EXPIRED',
    })

    MyCheckWalletService.events.encryptedCvvReceived(null)
    this.removeRecord(CVVRecord!)
    store.dispatch(resetCVVForCard())
  }

  private static _getRecords(): CVVRecord[] {
    try {
      const CVVRecords = storage.local.getItem(CVV_RECORD_STORAGE_IDENTIFIER)

      if (!CVVRecords) {
        return []
      }
      return JSON.parse(CVVRecords) as CVVRecord[]
    } catch (err) {
      DebugLogger.log(err)
      return []
    }
  }

  private static _updateRecords(CVVRecords: CVVRecord[]): void {
    try {
      storage.local.setItem(CVV_RECORD_STORAGE_IDENTIFIER, JSON.stringify(CVVRecords))
    } catch (err) {
      DebugLogger.log(err)
    }
  }
}

export const EncryptedCVVManagerHelper = new EncryptedCVVManager()
