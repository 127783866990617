import { BorderColorType, BorderSideType } from "../components/MyInput/constants";
export const ERROR_BORDER_COLOR: string = "#b0021b";

export const BootstrapBtn = {
  success: {
    color: '#28a745',
    borderColor: "transparent",
    backgroundColor: "transparent",
    borderWidth: 0,
  },
  error: {
    color: '#c82333',
    borderColor: "transparent",
    backgroundColor: "transparent",
    borderWidth: 0,
  },
}

type BorderConfigStyleType = {
  color: string,
  width: string,
  radius: string
}

type TextConfigStyleType = {
  color: string,
  font: string,
  size: string,
  weight: string
}

type BtnThemeData = {
  borderColor: string,
  backgroundColor: string,
  fontSize: string | number,
  color: string,
  borderRadius: string | number,
  borderWidth: string | number,
  fontWeight?: string | number,
  textTransform?: string,
  textDecoration?: string,
  fontFamily?: string
}

export type ButtonColorType = "default"
  | "yes"
  | "no"
  | "primary"
  | "secondary"
  | "remove"
  | "balance"
  | "unstyled";

export type ThemeOption = 1 | 2 | 3;

const getBorderStyle = (border: 1 | 2 | 3 | 'Rectangle' | 'Soft' | 'Circle') => {
  switch (border) {
    case 1:
    case 'Rectangle':
      return 0

    case 2:
    case 'Soft':
      return 5

    case 3:
    case 'Circle':
      return 20

    default:
      return 0
  }
}

export const getTextStyle = (text: TextConfigStyleType) => {
  if (!text) {
    return {};
  }

  const color = text.color || "initial";
  const fontSize = text.size || "initial";
  const fontWeight = text.weight || "initial";

  return { color, fontSize, fontWeight };
};

// Buttons
const composeBtnUnstyled = (theme: any): BtnThemeData => {
  const { border_radius } = theme;
  return {
    ...composeTextStyle(theme, "five"),
    borderColor: "none",
    backgroundColor: "transparent",
    borderRadius: getBorderStyle(border_radius),
    borderWidth: 0,
    textTransform: 'capitalize',
    textDecoration: 'underline',
    color: theme.button.color.primary,
  }
}

const composeBtnBootstrap = (theme: any, isPositiveAction: boolean): BtnThemeData => {
  const { border_radius } = theme;
  if (isPositiveAction) {
    return {
      ...composeTextStyle(theme, "six"),
      ...BootstrapBtn.success,
      borderRadius: getBorderStyle(border_radius),
      textTransform: 'capitalize',
    }
  }
  return {
    ...composeTextStyle(theme, "six"),
    ...BootstrapBtn.error,
    borderRadius: border_radius,
    textTransform: 'capitalize',
  }
}

const composeBthTheme1 = (theme: any, isPositiveAction: boolean): BtnThemeData => {
  const { button, text, border_radius } = theme;

  if (!isPositiveAction) {
    return {
      borderColor: button.color.primary,
      backgroundColor: 'transparent',
      fontSize: text.size.medium,
      color: button.color.primary,
      borderRadius: getBorderStyle(border_radius),
      borderWidth: 2,
      fontWeight: 'bold',
      textTransform: 'capitalize',
      fontFamily: theme.text.font_family,
    }
  }

  return {
    borderColor: button.color.primary,
    backgroundColor: button.color.primary,
    fontSize: text.size.medium,
    color: button.color.secondary,
    borderRadius: getBorderStyle(border_radius),
    borderWidth: 2,
    fontWeight: 'bold',
    textTransform: 'capitalize',
    fontFamily: theme.text.font_family,
  }
};

const composeBthTheme2 = (theme: any, isPositiveAction: boolean): BtnThemeData => {
  const { button, text, border_radius } = theme;
  if (!isPositiveAction) {
    return {
      borderColor: button.color.secondary,
      backgroundColor: button.color.secondary,
      fontSize: text.size.medium,
      color: 'black',
      borderRadius: getBorderStyle(border_radius),
      borderWidth: 2,
      fontWeight: 'normal',
      textTransform: 'capitalize',
      fontFamily: theme.text.font_family,
    }
  }

  return {
    borderColor: button.color.primary,
    backgroundColor: button.color.primary,
    fontSize: text.size.medium,
    color: 'black',
    borderRadius: getBorderStyle(border_radius),
    borderWidth: 2,
    fontWeight: 'normal',
    textTransform: 'capitalize',
    fontFamily: theme.text.font_family,
  }
};

const composeBthTheme3 = (theme: any, isPositiveAction: boolean): BtnThemeData => {
  const { button, text, border_radius } = theme;
  if (!isPositiveAction) {
    return {
      borderColor: button.color.secondary,
      backgroundColor: button.color.secondary,
      fontSize: text.size.medium,
      color: 'white',
      borderRadius: getBorderStyle(border_radius),
      borderWidth: 2,
      fontWeight: 'bold',
      textTransform: 'capitalize',
      fontFamily: theme.text.font_family,
    }
  }

  return {
    borderColor: button.color.primary,
    backgroundColor: button.color.primary,
    fontSize: text.size.medium,
    color: 'white',
    borderRadius: getBorderStyle(border_radius),
    borderWidth: 2,
    fontWeight: 'bold',
    textTransform: 'capitalize',
    fontFamily: theme.text.font_family,
  }
};

export const composeBtnStyles = (
  theme: any,
  themeOption: ThemeOption,
  type: ButtonColorType,
  useBootstrap: boolean,
): BtnThemeData => {
  const isPositiveAction = type !== "no";
  if (type === "unstyled") {
    return composeBtnUnstyled(theme);
  }

  if (useBootstrap) {
    return composeBtnBootstrap(theme, isPositiveAction);
  }

  switch (themeOption) {
    case 1:
      return composeBthTheme1(theme, isPositiveAction);
    case 2:
      return composeBthTheme2(theme, isPositiveAction);
    case 3:
      return composeBthTheme3(theme, isPositiveAction);
  }
}

// Inputs
type InputThemeData = {
  backgroundColor: string,
  boxShadow: string,
  borderColor: string,
  borderWidth: number,
  borderBottomWidth?: number,
  height: number,
  borderRadius: number,
  borderStyle: string,
}
export const INPUT_SIZE_MULTIPLIER: number = 2.1;
export const FLOATING_INPUT_SIZE_MULTIPLIER: number = 2.5;
export const composeInputStyles = (
  theme: any,
  isFloating?: boolean,
): InputThemeData => {
  const floatingHeight: number = isFloating
    ? parseInt(theme.text.size.medium, 10) * FLOATING_INPUT_SIZE_MULTIPLIER
    : parseInt(theme.text.size.medium, 10) * INPUT_SIZE_MULTIPLIER;
  let themeData: InputThemeData = {
    backgroundColor: theme.input.background || "white",
    boxShadow: "none",
    borderWidth: + theme.input.border.display,
    height: floatingHeight,
    borderRadius: getBorderStyle(theme.border_radius) || 0,
    borderColor: "#d7d7d7",
    borderStyle: "solid",
  }

  if (theme.input.border.side === BorderSideType.bottom) {
    themeData.borderBottomWidth = 1;
  }

  if (theme.input.shadow) {
    themeData.boxShadow = '0 2px 8px 4px #e8e8e8';
  }

  if (
    theme.input.border.color &&
    theme.input.border.color.toLowerCase() === BorderColorType.inherit
  ) {
    themeData.borderColor = theme.button.color.primary;
  }

  return themeData;
}
// Checkboxes
type CheckboxThemeData = {
  backgroundColor: string,
  borderRadius: string | number,
  borderWidth: string | number,
  height: string | number,
  width: string | number,
}
const CHECKBOX_BTN_SIZE_MULTIPLIER: number = 1.5;
export const composeCheckboxStyles = (theme: any): CheckboxThemeData => {
  return {
    backgroundColor: theme.button.color.primary,
    borderRadius: theme.border_radius,
    borderWidth: 1,
    height: parseInt(theme.text.size.medium, 10) * CHECKBOX_BTN_SIZE_MULTIPLIER,
    width: parseInt(theme.text.size.medium, 10) * CHECKBOX_BTN_SIZE_MULTIPLIER,
  }
};
// Radiobuttons
type RadioBtnThemeData = {
  backgroundColor: string,
  color?: string,
  borderWidth: string | number,
  height: string | number,
  width: string | number,
  minWidth?: string | number,
}

const WHITE_COLOR: string = "rgb(255, 255, 255)";
const RADIO_BTN_SIZE_MULTIPLIER: number = 1.5;

export const composeRadioBtnStyles = (theme: any): RadioBtnThemeData => ({
  backgroundColor: WHITE_COLOR,
  borderWidth: 1,
  height: parseInt(theme.text.size.medium, 10) * RADIO_BTN_SIZE_MULTIPLIER,
  width: parseInt(theme.text.size.medium, 10) * RADIO_BTN_SIZE_MULTIPLIER,
  minWidth: parseInt(theme.text.size.medium, 10) * RADIO_BTN_SIZE_MULTIPLIER,
})
// Text
type TextThemeData = {
  fontFamily: string,
  color: string,
  fontSize: string | number,
}

export type FontGroups = "one" | "two" | "three" | "four" | "five" | "six";

export const composeTextStyle = (theme: any, group: FontGroups): TextThemeData => {
  switch (group) {
    case "one":
      return {
        fontFamily: theme.text.font_family,
        color: theme.text.color.main,
        fontSize: theme.text.size.large,
      }
    case "two":
      return {
        fontFamily: theme.text.font_family,
        color: theme.text.color.secondary,
        fontSize: theme.text.size.medium,
      }
    case "three":
      return {
        fontFamily: theme.text.font_family,
        color: theme.text.color.main,
        fontSize: theme.text.size.medium,
      }
    case "four":
      return {
        fontFamily: theme.text.font_family,
        color: theme.text.color.main,
        fontSize: theme.text.size.small,
      }
    case "five":
      return {
        fontFamily: theme.text.font_family,
        color: theme.button.color.main,
        fontSize: theme.text.size.small,
      }
    case "six":
      return {
        fontFamily: theme.text.font_family,
        color: theme.button.color.main,
        fontSize: theme.text.size.small,
      }
    default:
      return {
        fontFamily: theme.text.font_family,
        color: theme.text.color.main,
        fontSize: theme.text.size.small,
      }
  }
}
// Tooltip
type TooltipThemeData = {
  borderColor: string,
  color: string,
  backgroundColor: string,
  borderWidth: number,
  fontSize: number,
  borderRadius: string | number,
}
export type ToolTipVariant = "one" | "two";
export const composeTooltipStyle = (theme: any): TooltipThemeData => {
  const tooltipVariant = theme.tooltip_theme;
  switch (tooltipVariant) {
    case 1:
      return {
        borderColor: 'black',
        borderWidth: 1,
        backgroundColor: 'black',
        color: 'white',
        fontSize: theme.text.size.small,
        borderRadius: getBorderStyle(theme.border_radius),
      }
    case 2:
      return {
        borderColor: 'black',
        borderWidth: 1,
        backgroundColor: 'white',
        color: 'black',
        fontSize: theme.text.size.small,
        borderRadius: getBorderStyle(theme.border_radius),
      }
    default:
      return {
        borderColor: 'black',
        borderWidth: 1,
        backgroundColor: 'black',
        color: 'white',
        fontSize: theme.text.size.small,
        borderRadius: getBorderStyle(theme.border_radius),
      }
  }
}
// Divider
type DividerThemeData = {
  width: string | number,
  backgroundColor: string,
}
export const composeDividerStyles = (theme: any): DividerThemeData => {
  if (theme.divider === 'inherit') {
    return {
      width: 1,
      backgroundColor: theme.button.color.primary,
    }
  }

  return {
    width: 1,
    backgroundColor: "#C5C5C5",
  }
}
// Container
type ContainerThemeData = {
  borderRadius: number,
  backgroundColor: string,
  borderWidth: number,
}
export const composeContainerStyles = (theme: any): ContainerThemeData => ({
  borderRadius: 8,
  backgroundColor: theme.background.section,
  borderWidth: 1,
})
export const composeBodyStyles = (theme: any): void => {
  const mainWrapper = document.getElementById("mycheck-wallet-v3");
  mainWrapper!.style.backgroundColor = theme.background.primary;
}
