import React, { FC, useEffect, useRef } from "react";
import styles from "./Iframe3DSecurity.module.css";
import { TransactionUrlType } from "./3DSServiceTypes";

export const Iframe3DSecurityForm: FC<TransactionUrlType> = ({ url, parameters: { MD, PaReq, TermUrl } }) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const iframeRef = useRef<HTMLIFrameElement>(null);

  const onLoad = () => iframeRef.current!.contentWindow!.focus();

  useEffect(() => {
    inputRef.current!.click();
  }, []);

  return (
    <React.Fragment>
      <form
        data-test-id="3security-form"
        name="3dSecureName"
        target="3dSecure"
        action={url}
        method="POST"
      >
        <input type="text" hidden name="PaReq" value={PaReq} readOnly />
        <input type="text" hidden name="MD" value={MD} readOnly />
        <input type="text" hidden name="TermUrl" value={TermUrl} readOnly />
        <input type="submit" value="" hidden ref={inputRef} />
      </form>
      <iframe
        className={styles.iframe}
        title="3dSecure"
        name="3dSecure"
        ref={iframeRef}
        onLoad={onLoad}
      />
    </React.Fragment>
  );
};
