import { Dispatch, } from "redux";

import { Configuration } from "../../configs/Configuration";
import { ApiClient, ApiUrls } from "../../services/ApiClient";

import {
  FETCH_PAYMENTS,
  ON_3DS_AUTHORIZE,
  ON_ADD_CARD_PRESS,
  ON_CANCEL_ADD,
  ON_PAYCONIQ,
  ON_PAYCONIQ_COMPLETED,
  ON_SPS_3DS_AUTHORIZE,
  ON_WE_CHAT,
  ON_WE_CHAT_COMPLETED,
  SET_CURRENT_CVV,
  SET_CURRENT_PAYMENT,
  SET_CURRENT_PAYMENT_OPTION,
  SET_DEFAULT_PAYMENT,
  SET_MANAGE_PAYMENTS,
  SET_PAYMENT_METHOD_SELECTED,
  SET_PENDING_ALTERNATIVE_PAYMENT,
  PaymentActionTypes,
  SET_LOADER,
  SET_ERROR_MESSAGE,
  SET_USER_ID,
  HANDLE_MANAGEMENT_MODAL,
  SET_MEMORIZED_CARD,
  SET_SUCCESS_CARD_POPUP,
  SPS_TRANSACTION_COMPLETE,
  SET_SPS_APM_ERROR,
  SET_READY_SPS_APM,
  SET_ADD_CARD_FIELDS,
  SET_FAILURE_ADDING_CARD_ID,
  SET_DISPLAY_ALTERNATIVE_DIVIDER
} from "./PaymentTypes";
import { onWeChatComplete } from "../../services/WeChatAliPayService";
import {
  add3DsTokenListener,
  cancel3DsSecurity,
  checkIfTransactionCompleted,
} from "../../services/3DSService/3DSService";
import { firebaseService } from "../../services/FirebaseService";
import { onPayconiqCompleteListener } from "../../services/Payconiq/PayconiqService";
import { CVVRecord, EncryptedCVVManagerHelper } from "../../helpers/EncryptedCVVManager";
import { MerchantsList } from "../../helpers/helpers";
import { getPaymentMethodNameType } from "../../helpers/GetPaymentMethodNameType";
import { MyCheckWalletService } from "../../services/MyCheckWalletService/WalletManager";

const DEFAULT_CARD: PaymentMethod = {
  expMonth: "",
  expYear2: "",
  type: "",
  token: "",
  ccToken: "",
  id: "",
  issuerFull: "",
  isSingleUse: 0,
  name: "",
  source: "",
  value: "",
  last4Digits: "",
  isDefault: false,
  encrypted_cvv: null,
}

let promiseComplete3DS: any;

const setUserCards = (cards: any[]): PaymentActionTypes => ({ type: FETCH_PAYMENTS, cards });

export const checkCardPayment = () => async (dispatch: Dispatch, getState: () => GlobalState) => {
  const state = getState();
  const { cards } = state.payment;
  const paymentMethod = state.payment.cards.find(e => e.isDefault);
  const CVVRecordData: CVVRecord | null = EncryptedCVVManagerHelper.getRecordBy(paymentMethod?.id);

  const { name, type } = getPaymentMethodNameType(paymentMethod!)
  MyCheckWalletService.events.paymentMethodSelected(name, type)
  dispatch(setPaymentMethodSelected(name, type));
  dispatch(setCurrentPaymentOption("credit-card"));

  if (!cards.length) {
    await MyCheckWalletService.checkIsCheckoutReady({});
  }

  if (paymentMethod) {
    await MyCheckWalletService.checkIsCheckoutReady({ cc_token: paymentMethod.ccToken, encrypted_cvv: CVVRecordData?.encryptedCVV });
    await dispatch(onAuthorize3DS(paymentMethod.token) as any);
  }
}

export const setPaymentMethodSelected = (paymentMethodName: string, type: SelectedPaymentMethod, resetCurrentPayment?: boolean): PaymentActionTypes => ({
  type: SET_PAYMENT_METHOD_SELECTED,
  selectedPaymentType: {
    paymentName: paymentMethodName,
    type,
  },
  resetCurrentPayment
})

export const setCheckoutReady = async (getState: () => GlobalState, defaultCard?: PaymentMethod | null) => {
  if (getState().payment.managePaymentsShow) {
    return;
  }

  const { ccToken, id } = getState().payment.currentPayment;
  const { name } = getState().configuration.walletConfiguration.general.creditcard_provider;
  const CVVRecordData: CVVRecord | null = name === 'sps' ? EncryptedCVVManagerHelper.getRecordBy(id) : null;

  await MyCheckWalletService.checkIsCheckoutReady({
    cc_token: defaultCard ? defaultCard.ccToken : ccToken,
    encrypted_cvv: CVVRecordData ? CVVRecordData.encryptedCVV : null,
  });
}

export const fetchPayments = (isInitial?: boolean) => async (dispatch: Dispatch, getState: () => GlobalState) => {
  const giftcards = true;
  const provider = getState().configuration.walletConfiguration.general.creditcard_provider.name;

  const response = await ApiClient(ApiUrls.getPayments(giftcards, provider), { domain: Configuration.api_domain });
  const paymentMethods = response.paymentMethods;
  dispatch(setUserCards(paymentMethods));
  if (response.giftcards) {
    dispatch(setUserId(response.giftcards[0].user_id));
  }
  dispatch(setErrorMessage(""));

  const defaultCard = paymentMethods.find((paymentMethod: PaymentMethod) => paymentMethod.isDefault) || paymentMethods[0];

  if (isInitial && defaultCard) {
    const { name, type } = getPaymentMethodNameType(defaultCard)
    dispatch(setPaymentMethodSelected(name, type))
    dispatch(onSelectPayment(defaultCard));
    // @ts-ignore
    dispatch(setDefaultPayment(defaultCard.id, defaultCard.ccToken))
  } else if (defaultCard) {
    dispatch(setCurrentPayment(defaultCard));
  } else if (!defaultCard) {
    dispatch(setCurrentPayment(DEFAULT_CARD));
  }

  if (isInitial) {
    setCheckoutReady(getState, defaultCard);
  }
  dispatch(setLoader(false));
};

export const deletePayment = (id: string) => async (dispatch: Dispatch, getState: () => GlobalState) => {
  const payment = getState().payment.cards.find(element => element.id === id);
  const memorizedCard = getState().payment.memorizedCard;
  const card = getState().payment.cards.find((card) => !card.isDefault)

  if (payment && payment.source === MerchantsList.SPS_ECOMMERCE) {
    const CVVRecord = EncryptedCVVManagerHelper.getRecordBy(id);
    EncryptedCVVManagerHelper.removeRecord(CVVRecord);
  }

  await ApiClient(ApiUrls.deletePayment(id), {
    method: "DELETE",
    domain: `${Configuration.api_domain}`,
  });

  if (card && memorizedCard && memorizedCard.id === id) {
    await dispatch(setMemorizedCard(card));
  }

  MyCheckWalletService.events.paymentMethodRemoved(payment);
  // @ts-ignore
  await dispatch(fetchPayments());
  await setCheckoutReady(getState);
  if (payment) {
    await dispatch(onAuthorize3DS(payment.token) as any);
  }
};

export const setDefaultPayment = (id: string, token: string) => async (dispatch: Dispatch, getState: () => GlobalState) => {
  const userId = getState().payment.userId;

  await ApiClient(ApiUrls.setDefaultPayment(id), {
    method: "PATCH",
    domain: `${Configuration.api_domain}`,
    body: {
      isDefault: true,
      userId
    }
  });

  dispatch({ type: SET_DEFAULT_PAYMENT, id });
  dispatch(setCurrentPayment(getState().payment.cards.find((pm: PaymentMethod) => pm.id === id)!));
  dispatch(onAuthorize3DS(getState().payment.cards.find((pm: PaymentMethod) => pm.id === id)!.token) as any);
};

export const resetCVVForCard = () => async (dispatch: Dispatch, getState: () => GlobalState) => {
  await setCheckoutReady(getState);
  dispatch(setCurrentCvv(null));
}

export const setCurrentPayment = (card: PaymentMethod): PaymentActionTypes => {
  const CVVRecord = EncryptedCVVManagerHelper.getRecordBy(card.id);
  if (CVVRecord) {
    return {
      type: SET_CURRENT_PAYMENT,
      card: {
        ...card,
        encrypted_cvv: CVVRecord.encryptedCVV,
      }
    }
  }

  return { type: SET_CURRENT_PAYMENT, card }
}

export const setCurrentCvv = (cvv: string | null): PaymentActionTypes => ({ type: SET_CURRENT_CVV, cvv })

export const setCurrentPaymentOption = (option: string): PaymentActionTypes => ({
  type: SET_CURRENT_PAYMENT_OPTION,
  option
});
export const setManagePayments = (value: boolean): PaymentActionTypes => ({ type: SET_MANAGE_PAYMENTS, value });

// @ts-ignore
export const onSelectPayment = (card: PaymentMethod): PaymentActionTypes => (dispatch: Dispatch, getState: () => GlobalState) => {
  // @ts-ignore
  dispatch(onAuthorize3DS(card.token));
  dispatch(setCurrentPayment(card));
};

export const onWeChatAliPayCompleted = () => ({ type: ON_WE_CHAT_COMPLETED });
export const onWeChatAliPay = (response: any) => async (dispatch: Dispatch) => {
  const source = response.response_data.qr || response.response_data.url
  dispatch({ type: ON_WE_CHAT, source });

  await onWeChatComplete(response.transaction_id);

  dispatch(onWeChatAliPayCompleted());
};

export const onPayconiqCompleted = () => ({ type: ON_PAYCONIQ_COMPLETED });
export const onPayconiq = (response: any) => async (dispatch: Dispatch) => {
  const source = response.qr || response.url
  dispatch({ type: ON_PAYCONIQ, source });

  await onPayconiqCompleteListener(response);

  dispatch(onPayconiqCompleted());
};

export const onAuthorize3DS = (token: string) => async (dispatch: Dispatch, getState: () => GlobalState) => {
  const defaultPayment = getState().payment.cards.find(card => card.isDefault)!;
  const walletSdkSessionId = MyCheckWalletService.getWalletId();
  const internalToken = promiseComplete3DS ? defaultPayment.token : token;

  if (firebaseService.getFirebaseAvailable()) {
    const value = await add3DsTokenListener(internalToken);
    const cardProviderName = getState().configuration.walletConfiguration?.general.creditcard_provider.name;
    if (cardProviderName === "sps") {
      dispatch({ type: ON_SPS_3DS_AUTHORIZE, url: `${value.url}&walletSdkSessionId=${walletSdkSessionId}` });
    } else {
      dispatch({ type: ON_3DS_AUTHORIZE, url: value.url, parameters: value?.parameters });
    }
    try {
      promiseComplete3DS = checkIfTransactionCompleted();
      await promiseComplete3DS;
      // @ts-ignore
      dispatch(fetchPayments());
      setTimeout(() => dispatch(onAuthorize3DS(internalToken) as any), 3000);
    } catch {
      setTimeout(() => dispatch(onAuthorize3DS(internalToken) as any), 3000)
    }
  }
};

export const reject3DSecurity = async () => {
  await cancel3DsSecurity();
  await fetchPayments();
};

export const setPendingAlternativePayment = (pending: boolean): PaymentActionTypes => ({
  type: SET_PENDING_ALTERNATIVE_PAYMENT,
  pending
});

export const onAddCardPress = (): PaymentActionTypes => ({ type: ON_ADD_CARD_PRESS });
export const onCancelAdd = (): PaymentActionTypes => ({ type: ON_CANCEL_ADD });

export const setLoader = (loader: boolean): PaymentActionTypes => {
  return ({
    type: SET_LOADER,
    loader
  })
};

export const setErrorMessage = (message: string): PaymentActionTypes => ({
  type: SET_ERROR_MESSAGE,
  message
});

export const setUserId = (id:string): PaymentActionTypes => ({
  type: SET_USER_ID,
  id
})

export const handleManagementModal = (state: boolean): PaymentActionTypes => ({
  type: HANDLE_MANAGEMENT_MODAL,
  state
})
export const setMemorizedCard = (card: PaymentMethod | null): PaymentActionTypes => ({
  type: SET_MEMORIZED_CARD,
  card
})
export const setSuccessCardPopup = (state: boolean): PaymentActionTypes => ({
  type: SET_SUCCESS_CARD_POPUP,
  state
})

export const setSPSTransactionComplete = (state: boolean): PaymentActionTypes => ({
  type: SPS_TRANSACTION_COMPLETE,
  state
})

export const setSpsApmError = (error: boolean): PaymentActionTypes => ({
  type: SET_SPS_APM_ERROR,
  error
})

export const setReadySpsApm = (isReady: boolean): PaymentActionTypes => ({
  type: SET_READY_SPS_APM,
  isReady
})

export const setAddCardFields = (addCardFields: object): PaymentActionTypes => ({
  type: SET_ADD_CARD_FIELDS,
  addCardFields
})

export const setFailureAddingCardId = (id: string): PaymentActionTypes => ({
  type: SET_FAILURE_ADDING_CARD_ID,
  id,
})

export const setDisplayAlternativeDivider = (displayAlternativeDivider: boolean): PaymentActionTypes => ({
  type: SET_DISPLAY_ALTERNATIVE_DIVIDER,
  displayAlternativeDivider,
})
