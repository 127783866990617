export const getMonthYear = (value = "") => {
  if (!value) {
    return {
      month: "",
      year: ""
    };
  }

  const splittedValue = value.split("/");
  if (splittedValue.length === 2) {
    return {
      month: splittedValue[0],
      year: splittedValue[1]
    };
  }

  return {
    month: splittedValue[0],
    year: ""
  };
};
