import * as React from "react";
import { Divider, ExpansionPanelDetails, Typography, ExpansionPanelSummary, ExpansionPanel } from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import styles from "./Logger.module.scss";

type LoggerType = {
  handleEvent: (event: string) => (value: any) => void,
  events: LoggerEvent[]
  expanded: string
  setExpanded: (value: string) => void
}

export const Logger: React.FunctionComponent<LoggerType> = ({ handleEvent, events, expanded, setExpanded }) => {
  const attachEvents = () => {
    window.mycheck.wallet.on("cardTokenReceived", handleEvent("cardTokenReceived"));
    window.mycheck.wallet.on("error", handleEvent("error"));
    window.mycheck.wallet.on("addCardFormReady", handleEvent("addCardFormReady"));
    window.mycheck.wallet.on("giftCardSelected", handleEvent("giftCardSelected"));
    window.mycheck.wallet.on("init", handleEvent("init"));
    window.mycheck.wallet.on("paymentCompleted", handleEvent("paymentCompleted"));
    window.mycheck.wallet.on("paymentMethodSelected", handleEvent("paymentMethodSelected"));
    window.mycheck.wallet.on("checkoutReady", handleEvent("checkoutReady"));
    window.mycheck.wallet.on("paymentMethodRemoved", handleEvent("paymentMethodRemoved"));
    window.mycheck.wallet.on("ready", handleEvent("ready"));
    window.mycheck.wallet.on("getUserInformation", handleEvent("getUserInformation"));
    window.mycheck.wallet.on("encryptedCvvReceived", handleEvent("encryptedCvvReceived"));
    window.mycheck.wallet.on("onAddCardFailure", handleEvent("onAddCardFailure"));
  };

  React.useEffect(() => {
    attachEvents();
  }, []);

  return (
  // @ts-ignore
    <ExpansionPanel square variant="outlined" expanded={expanded === 'logger'} onChange={() => setExpanded(v => v === "logger" ? "" : "logger")}>
      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="h6">
          Logger
        </Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails style={{ overflow: 'scroll', maxHeight: 400 }}>
        <div>
          {events.map((element, index) => (
            <div key={index}>
              <div className={styles.element}>
                <div className={styles.row}>
                  <Typography variant="caption" className={styles.label}>
                    {element.type === "event" ? `Event` : `Function`}:
                  </Typography>
                  <Typography variant="body2">{element.name}</Typography>
                </div>
                {element.value && (
                  <div className={styles.row}>
                    <Typography variant="caption" className={styles.label}>
                      {element.type === "event" ? `Parameter` : `Value`}:
                    </Typography>
                    <Typography variant="caption" className={styles.text}>
                      {JSON.stringify(element.value)}
                    </Typography>
                  </div>
                )}
              </div>
              {index !== events.length - 1 && <Divider />}
            </div>
          ))}
        </div>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};
