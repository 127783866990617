import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { AlternativeWalletWrapper } from '../../containers/AlternativeWallets/AlternativeWalletWrapper'
import { MyTouchable } from '../../components/MyTouchable/MyTouchable'
import { ApiClient, ApiUrls } from '../ApiClient'
import { Base64 } from './Base64'
import { Configuration } from '../../configs/Configuration'

import styles from './ApplePayService.module.scss'
import { Tracking } from '../AnalyticsService/GoogleAnalyticsService'
import {
  checkCardPayment,
  fetchPayments,
  setCurrentPayment,
  setCurrentPaymentOption,
  setErrorMessage,
  setLoader,
  setPaymentMethodSelected
} from '../../store/payment/PaymentActions'
import { getMerchantConfig, isWebView, MerchantsList } from '../../helpers/helpers'
import { useRevertPaymentSelection } from '../../hooks/useRevertPaymentSelection'
import { ApplePayModal } from './ApplePayModal'
import { parseAlternativeError, parseWalletMSError } from '../../helpers/ParseError'
import { useTranslation } from '../../hooks/useTranslation'
import { MyCheckWalletService } from '../MyCheckWalletService/WalletManager'
import classNames from 'classnames'

const APPLE_PAY_TYPE: string = 'Apple Pay'
type ApplePayServiceType = {}
const APPLE_PAY_VERSION: number = 10

enum FilteredCardName {
  DINERS = "diners",
  UNIONPAY = "unionpay",
  TR = "TR",
}

const WebViewWalletMessenger = {
  postMessage: (data: any) => {
    //@ts-ignore
    window.webkit.messageHandlers.walletMessage.postMessage({
      "message": JSON.stringify(data),
    })
  },
};

const onWebViewApplePay = (data: any, applePayCallback: any) => {
  //@ts-ignore
  window.onAppleResponse = applePayCallback;
  WebViewWalletMessenger.postMessage(data);
}

const getApplePaySession = async (url: string) => {
  const response = await ApiClient(ApiUrls.getApplePaySession(url))
  return response.response
}

export const ApplePayService: React.FunctionComponent<ApplePayServiceType> = () => {
  const dispatch = useDispatch();

  const useRevertPayment = useRevertPaymentSelection();

  const supportedCardTypes = useSelector((state: GlobalState) =>
    state.configuration.walletConfiguration.general.supported_card_types?.filter(
      (payment: FilteredCardName) =>
        !Object.values(FilteredCardName).includes(payment)
    )
  );
  const acceptedCreditcards = useSelector((state: GlobalState) =>
    state.configuration.customWalletConfiguration.acceptedCreditcards?.filter(
      (payment: FilteredCardName) =>
        !Object.values(FilteredCardName).includes(payment)
    )
  );
  const merchantConfigurations = useSelector((state: GlobalState) => state.configuration.walletConfiguration.merchantConfigurations);
  const applePay = getMerchantConfig(MerchantsList.APPLE_PAY, merchantConfigurations) || {};
  const accessToken = useSelector((state: GlobalState) => state.account.accessToken);
  const currentPaymentOption = useSelector((state: GlobalState) => state.payment.paymentOption);
  const currentPayment = useSelector((state: GlobalState) => state.payment.cards.find(e => e.isDefault)!);

  const isBrowserSupporting = (window.ApplePaySession && window.ApplePaySession.canMakePayments()) || isWebView();

  const [isModalOpen, setModalOpen] = React.useState(false);

  const errorLabelFilterCard = useTranslation("ERROR_FILTER_CARD");

  const closePopup = () => {
    setModalOpen(false);
    dispatch(setLoader(false));
    dispatch(checkCardPayment());
  };

  const onPaymentAuthorized = async (token: any) => {
    try {
      dispatch(setLoader(true));
      const paymentResponse = token;
      const newToken = Base64.encode(JSON.stringify(paymentResponse.paymentData));
      const card_type = paymentResponse.paymentMethod.network.toLowerCase();

      await ApiClient(ApiUrls.addCreditCard, {
        domain: Configuration.api_domain,
        method: 'POST',
        accessToken: accessToken!,
        body: {
          accessToken: accessToken,
          source: 'APPLEPAY',
          externalToken: newToken,
          extraParams: {
            cardType: card_type,
          }
        }
      });
      await dispatch(fetchPayments());
      await dispatch(setCurrentPayment(currentPayment));
      MyCheckWalletService.events.cardTokenReceived(currentPayment.ccToken);
      closePopup();
    } catch (error) {
      // @ts-ignore
      if (error.errorId) {
        // @ts-ignore
        dispatch(setErrorMessage(parseWalletMSError(error.errorId)));
      } else {
        dispatch(setErrorMessage(parseAlternativeError(error) || errorLabelFilterCard));
      }
      MyCheckWalletService.cardAddFailureEvent(error, MerchantsList.APPLE_PAY);
      closePopup();
    }
  }

  const onPaymentCancel = () => {
    closePopup();
    dispatch(fetchPayments());
    return useRevertPayment.revert();
  }

  const onApplePaymentWebViewCallback = (response: any) => {
    if (response && response.status === "success") {
      onPaymentAuthorized(response.token);
    } else if (response && response.status === "canceled") {
      onPaymentCancel();
    }
  }

  const createApplePayment = React.useCallback(() => {
    useRevertPayment.seal();
    setModalOpen(false);

    Tracking.trackEvent(Tracking.EventAction.InstantPM, APPLE_PAY_TYPE);
    dispatch(setCurrentPaymentOption('credit-card'));

    const request = {
      countryCode: applePay.settings.country_code,
      currencyCode: applePay.settings.currency_code,
      supportedNetworks: acceptedCreditcards || supportedCardTypes,
      merchantCapabilities: ['supports3DS'],
      total: {
        label: applePay.settings.pre_auth_text,
        amount: MyCheckWalletService.getPaymentInfo().amount,
        type: 'final'
      },
    };

    if (isWebView()) {
      onWebViewApplePay(request, onApplePaymentWebViewCallback);
      return;
    }

    const session = new window.ApplePaySession(APPLE_PAY_VERSION, request);

    session.onvalidatemerchant = async (event: any) => {
      const response = await getApplePaySession(event.validationURL);
      session.completeMerchantValidation(response);
    };
    session.onpaymentauthorized = async ({ payment: { token } }: any) => {
      await onPaymentAuthorized(token);
      session.completePayment(window.ApplePaySession.STATUS_SUCCESS);
    }
    session.oncancel = () => {
      return onPaymentCancel();
    }
    session.abort = () => {
      return onPaymentCancel();
    }

    session.begin();
  }, [
    accessToken,
    applePay.settings?.pre_auth_text,
    applePay.settings?.country_code,
    applePay.settings?.currency_code,
    acceptedCreditcards || supportedCardTypes,
  ]);

  const onApplePayClick = React.useCallback(
    () => {
      MyCheckWalletService.checkIsCheckoutReady({});

      MyCheckWalletService.events.paymentMethodSelected('applepay', 'alternative');
      dispatch(setCurrentPaymentOption('credit-card'));
      dispatch(setPaymentMethodSelected('applepay', 'alternative', true));

      if (currentPaymentOption === APPLE_PAY_TYPE) {
        return null
      };

      setModalOpen(true);
    },
    [currentPaymentOption],
  )

  if (!isBrowserSupporting) {
    return null
  }

  if (currentPaymentOption === APPLE_PAY_TYPE) {
    return (
      <AlternativeWalletWrapper>
        <div style={{
          opacity: 0.5,
          cursor: 'default',
        }}>
          <MyTouchable ariaLabel="ApplePay button" className={styles.root} onClick={onApplePayClick} id="apple-pay" />
        </div>
      </AlternativeWalletWrapper>
    )
  }

  return (
    <div className={styles.applepayWrapper} onClick={onApplePayClick}>
      <div className={styles.applepayOverlay}/>
      <div className={classNames(styles.root, styles.applepayButton)} id="apple-pay"/>
      <ApplePayModal isModalOpen={isModalOpen} closePopup={closePopup} confirmPayment={createApplePayment} />
    </div>
  )
}
