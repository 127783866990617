import { useSelector } from "react-redux";
import { EncryptedCVVManagerHelper } from "../helpers/EncryptedCVVManager";
import { MerchantsList } from "../helpers/helpers";
import { View } from "../services/MyCheckWalletService/WalletServiceTypes";

export const useCVVConfirmationShow = () => {
    const hasCards = useSelector((state: GlobalState) => state.payment.cards.length);
    const isAdding = useSelector((state: GlobalState) => state.payment.isAdding);
    const isManagePaymentsShow = useSelector((state: GlobalState) => state.payment.managePaymentsShow);
    const isManagementModalShow = useSelector((state: GlobalState) => state.payment.managementModal);

    const currentView = useSelector((state: GlobalState) => state.configuration.customWalletConfiguration.view);
    const currentPayment = useSelector((state: GlobalState) => state.payment.currentPayment);
    const defaultPayment = useSelector((state: GlobalState) => state.payment.cards.find(e => e.isDefault));
    const currentMethod = useSelector((state: GlobalState) => state.payment.paymentMethodSelected);
    const cvvActive = useSelector((state: GlobalState) => state.configuration.walletConfiguration.sections.credit_cards.cvv?.active);

    const encryptForCreditCard = !!EncryptedCVVManagerHelper.getRecordBy(currentPayment.id);
    const isCvvValidPaymentSource = currentPayment.source === MerchantsList.SPS_ECOMMERCE || defaultPayment?.source === MerchantsList.SPS_ECOMMERCE;
    const isTraditional = currentMethod.type === "traditional";
    const isCvvActive = !cvvActive && cvvActive !== undefined && cvvActive !== null ? false : true;

    const isCvvConfirmationShown = currentView === View.CHECKOUT && !!hasCards &&
    (!isAdding || isManagementModalShow) && !currentPayment.encrypted_cvv && !encryptForCreditCard && !isManagePaymentsShow &&
    isTraditional && isCvvValidPaymentSource && isCvvActive

    return isCvvConfirmationShown;
}
