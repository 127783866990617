import * as React from 'react'
import { useSelector } from 'react-redux'
import {
  Button,
  TextField,
  Theme,
  Typography,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import { ContextObject } from '../hooks/DemoContext'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { DemoRadioButton } from '../components/DemoRadioButton'
import { DemoCheckbox } from '../components/DemoCheckbox'

import classes from './MainSettings.module.scss'
import { ApiClient } from '../../src/services/ApiClient'
import { Configuration } from '../../src/configs/Configuration'
import { View } from '../../src/services/MyCheckWalletService/WalletServiceTypes'

type MainSettingsType = {
  setIsNotReady: () => void
  expanded: string
  setExpanded: (value: string) => void
}

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {},
  row: {
    marginTop: theme.spacing(),
  },
}))


export const MainSettings: React.FunctionComponent<MainSettingsType> = ({ setIsNotReady, expanded, setExpanded }) => {
  const styles = useStyles()
  const [appViewMode, setAppViewMode] = React.useState(View.CHECKOUT)

  const [locale, setLocale] = React.useState('en')
  const [merchantId, setMerchantId] = React.useState()
  const [acceptGiftcards, setAcceptGiftcards] = React.useState(false)
  const [addCardAutofocus, setAddCardAutofocus] = React.useState(false)
  const [walletId, setWalletId] = React.useState()
  const [businessId, setBusinessId] = React.useState()
  const [debug, setDebug] = React.useState(true)

  const [currency, setCurrency] = React.useState('EUR')
  const [amount, setAmount] = React.useState<string | number>('150')
  const [returnUrl, setReturnUrl] = React.useState('')
  const [callbackUrl, setCallbackUrl] = React.useState('')
  const { publishableKey, refreshToken, accessToken, methods } = React.useContext(ContextObject)

  const appPbKey = useSelector((state: GlobalState) => state.configuration.publishableKey || 'pk_PNC9Uru27bScswQfBCVQU84dRecKe')
  const appRefreshToken = useSelector((state: GlobalState) => state.account.refreshToken || 'eyJpdiI6IlNHQkJrczY1TlhYZlpCWitGRTJkOVE9PSIsInZhbHVlIjoiRmZvRnFObUo5Qmg1Y1IzTnFwWDdnSlJURUxSQjViZ0k2dFhmaUc2d01Kek5LTHczbVFta2JYM2RJUVBROWhVckZjMXVSQ2Y2dGZpMWt3NXZXR2QxdkE9PSIsIm1hYyI6IjgyYWM4NzY4Y2EwZmU1OGYyZWM4MGM4M2RiYTRmNmM4NGY0Mzk2YjQ0NjdlZjQxOWZiMDdlNDBiMGI0ZDk1MDIifQ==')

  React.useEffect(() => {
    methods.setPublishableKeyRefreshToken(appPbKey, appRefreshToken!)
  }, [appPbKey, appRefreshToken])

  const onChangePbKey = (e: React.ChangeEvent<HTMLInputElement>) => methods.setPublishableKeyRefreshToken(e.target.value, refreshToken!)
  const onChangeRefreshToken = (e: React.ChangeEvent<HTMLInputElement>) => methods.setPublishableKeyRefreshToken(publishableKey, e.target.value)
  const onChangeAccessToken = (e: React.ChangeEvent<HTMLInputElement>) => methods.setAccessToken(e.target.value)
  const onChangeAppView = (e: React.ChangeEvent<HTMLInputElement>, value: string) => setAppViewMode(value as View)

  const onChangeValue = (callback: (value: string) => any) => (e: React.ChangeEvent<HTMLInputElement>) => callback(e.target.value)

  const updateApp = async (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    setIsNotReady()
    window.mycheck.wallet.destroy()

    let refreshTokenToSend = refreshToken
    if (!refreshToken) {
      const response = await ApiClient('/users/api/v1/refresh_token', {
        domain: Configuration.api_domain,
        method: 'POST',
        body: { publishableKey }
      })
      methods.setPublishableKeyRefreshToken(publishableKey, response.refreshToken)
      refreshTokenToSend = response.refreshToken
    }

    window.mycheck.auth.connect({ publishableKey, refreshToken: refreshTokenToSend, accessToken })
    window.mycheck.wallet.init({
      settings: {
        locale, walletId, businessId, acceptGiftcards, addCardAutofocus, debug,
        view: appViewMode,
        merchant_id: merchantId,
      },
      payment_details: { currency, amount, return_url: returnUrl, callback_url: callbackUrl }
    })
  }

  return (
    <div className={styles.root}>
      <ExpansionPanel square variant="outlined" expanded={expanded === 'general'}
        // @ts-ignore
                      onChange={() => setExpanded(v => v === 'general' ? '' : 'general')}>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <div className={classes.summaryTitle}>
            <Typography variant="h6">General settings</Typography>
            <Button color="primary" variant="contained" onClick={updateApp} fullWidth disabled={!publishableKey}
                    className={styles.row}>Update</Button>
          </div>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <div>
            <TextField fullWidth onChange={onChangePbKey} value={publishableKey} label="Publishable Key" />
            <TextField
              fullWidth onChange={onChangeRefreshToken} value={refreshToken} label="Refresh Token"
              className={styles.row}
            />
            <TextField
              fullWidth onChange={onChangeAccessToken} value={accessToken} label="Access Token"
              className={styles.row}
            />
            <DemoRadioButton
              value={appViewMode} handleChange={onChangeAppView}
              options={[{ label: View.CHECKOUT, value: View.CHECKOUT }, { label: View.MANAGE, value: View.MANAGE }]}
            />
          </div>
        </ExpansionPanelDetails>
      </ExpansionPanel>

      <ExpansionPanel square variant="outlined" expanded={expanded === 'settings'}
        // @ts-ignore
                      onChange={() => setExpanded(v => v === 'settings' ? '' : 'settings')}>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">
            Settings
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <div>
            <TextField fullWidth onChange={onChangeValue(setLocale)} value={locale} label="Locale" />
            <TextField
              fullWidth onChange={onChangeValue(setMerchantId)} value={merchantId} label="Merchant Id"
              className={styles.row}
            />
            <DemoCheckbox className={styles.row} isChecked={acceptGiftcards}
                          onChange={() => setAcceptGiftcards(!acceptGiftcards)} label="Accepted Giftcards" />
            <DemoCheckbox className={styles.row} isChecked={addCardAutofocus}
                          onChange={() => setAddCardAutofocus(!addCardAutofocus)}
                          label="Add Card autofocus" />
            <TextField fullWidth onChange={onChangeValue(setWalletId)} value={walletId} label="Wallet Id" />
            <TextField fullWidth onChange={onChangeValue(setBusinessId)} value={businessId} label="Business Id" />

            <DemoCheckbox className={styles.row} isChecked={debug} onChange={() => setDebug(!debug)} label="Debug" />
          </div>
        </ExpansionPanelDetails>
      </ExpansionPanel>

      <ExpansionPanel square variant="outlined" expanded={expanded === 'payment-details'}
        // @ts-ignore
                      onChange={() => setExpanded(v => v === 'payment-details' ? '' : 'payment-details')}>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">
            Payment Details
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <div>
            <TextField fullWidth onChange={onChangeValue(setCurrency)} value={currency} label="Currency"
                       className={styles.row} />
            <TextField fullWidth onChange={onChangeValue(setAmount)} value={amount} label="Amount"
                       className={styles.row} />
            <TextField fullWidth onChange={onChangeValue(setReturnUrl)} value={returnUrl} label="Return URL"
                       className={styles.row} />
            <TextField fullWidth onChange={onChangeValue(setCallbackUrl)} value={callbackUrl} label="Callback URL"
                       className={styles.row} />
          </div>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </div>
  )
}
