import * as React from "react";
import { useDispatch, useSelector } from "react-redux";

import { useTranslation } from "../../hooks/useTranslation";
import { onWeChatAliPayCompleted, setPendingAlternativePayment } from "../../store/payment/PaymentActions";

import { Modal } from "../../components/Modal/Modal";
import { MyText } from "../../components/MyText/MyText";
import { MyButton } from "../../components/MyButton/MyButton";

import styles from "./InstantPaymentModal.module.scss";

type InstantPaymentModalType = {}

export const InstantPaymentModal: React.FunctionComponent<InstantPaymentModalType> = (props) => {
  const dispatch = useDispatch();

  const weChatUrl = useSelector((state: GlobalState) => state.payment.weChatUrl);
  const weChatUrlIsQr = useSelector((state: GlobalState) => state.payment.weChatUrlIsQr);

  const walletRedirectTitle = useTranslation("WALLET_REDIRECT_TITLE");
  const walletRedirectContent = useTranslation("WALLET_REDIRECT_CONTENT");
  const walletRedirectDecline = useTranslation("WALLET_REDIRECT_DECLINE");
  const walletRedirectAccept = useTranslation("WALLET_REDIRECT_ACCEPT");

  const onRequestClose = () => {
    dispatch(setPendingAlternativePayment(false));
    dispatch(onWeChatAliPayCompleted());
  };

  const getWeCharWrapper = () => {
    if (weChatUrlIsQr) {
      return weChatUrl.includes("payconiq") ? styles.payconiqWrapper : styles.weChatWrapper;
    }

    return styles.iframeWrapper;
  };

  if(!weChatUrl) {
    return null;
  }

  return (
    <Modal
      isOpen={!!weChatUrl} onRequestClose={onRequestClose} contentLabel="we-chat-ali-pay"
      className={getWeCharWrapper()}
    >
      {weChatUrlIsQr ? (
        <iframe
          src={weChatUrl} title="wechat-qr"
          className={weChatUrl.includes("payconiq") ? styles.iframePayconiq : styles.iframeWeChat}
        />
      ) : (
        <React.Fragment>
          <div className={styles.row}>
            <MyText type="two">{walletRedirectTitle}</MyText>
          </div>
          <div className={styles.row}>
            <MyText type="three">{walletRedirectContent}</MyText>
          </div>
          <div className={styles.buttons}>
            <MyButton
              onClick={onRequestClose} type="secondary" label={walletRedirectDecline} className={styles.cancel}
            />
            <a href={weChatUrl} target="_blank" rel="noopener noreferrer">
              <MyButton type="primary" label={walletRedirectAccept} className={styles.accept}/>
            </a>
          </div>
        </React.Fragment>
      )}
    </Modal>
  );
};
