import { IWallet, WalletEvent, Wallet } from "./Wallet";
import { IWalletAuth, WalletAuth } from "./WalletAuth";
import { Environment } from "./WalletServiceTypes";
import { Tracking } from "../AnalyticsService/GoogleAnalyticsService";

interface IWalletManager {
  init: () => void;
}

export class WalletManager implements IWalletManager {
  public authInstance: IWalletAuth;
  public walletInstance: IWallet;

  constructor() {
    this.authInstance = new WalletAuth();
    this.walletInstance = new Wallet();
    this.init();
    window.addEventListener(WalletEvent.Destroy, this.destroy);
    window.addEventListener(WalletEvent.Init, this.onWalletInit);
  }

  public init = (): void => {
    this.setupEnv();
    this.authInstance && this.setupAuth();
    this.setupWallet();
    this.setupDeprecatedFunctions();
  };

  private onWalletInit = async (): Promise<void> => {
    await Tracking.initGoogleAnalytics();

    this.walletInstance.events.init(); // Notify wrapping client that wallet has been initialized
  };

  private destroy = (): void => {
    window.mycheck = {};
    this.init();
  };

  private setupEnv = (): void => {
    window.mycheck = {
      Environment,
      setEnvironment: (env: Environment) => null,
    };
  };

  private setupAuth = (): void => {
    window.mycheck.auth = this.authInstance;
  };

  private setupWallet = (): void => {
    window.mycheck.wallet = {
      Wallet: Wallet,
      wallet: this.walletInstance,
    };
  };

  private setupDeprecatedFunctions = (): void => {
    window.mycheck.wallet = {
      ...window.mycheck.wallet,
      init: window.mycheck.wallet.wallet.init,
      debug: window.mycheck.wallet.wallet.debug,
      on: window.mycheck.wallet.wallet.on,
      getPaymentMethod: window.mycheck.wallet.wallet.getPaymentMethod,
      addPaymentMethod: window.mycheck.wallet.wallet.addPaymentMethod,
      makeInstantPayment: window.mycheck.wallet.wallet.makeInstantPayment,
      setPaymentDetails: window.mycheck.wallet.wallet.setPaymentDetails,
      alternativesAtId: null,
      destroy: window.mycheck.wallet.wallet.destroy,
      setAcceptedCreditCards:
        window.mycheck.wallet.wallet.setAcceptedCreditCards,
      setLocale: window.mycheck.wallet.wallet.setLocale,
      getUserInformation: this.authInstance.getUserInformation,
      setView: window.mycheck.wallet.wallet.setView,
      getCustomConfig: window.mycheck.wallet.wallet.getCustomConfig,
      get3DSecureSignature: window.mycheck.wallet.wallet.get3DSecureSignature,
      getPaymentMethodSelected:
        window.mycheck.wallet.wallet.getPaymentMethodSelected,
      getInitParams: window.mycheck.wallet.wallet.getInitParams,
      submitCardForm: window.mycheck.wallet.wallet.submitCardForm,

      events: window.mycheck.wallet.wallet.events,
      views: window.mycheck.wallet.wallet.views,
    };
  };
}

export const WalletManagerInstance = new WalletManager();
export const MyCheckWalletService = WalletManagerInstance.walletInstance;
export const MyCheckWalletAuth = WalletManagerInstance.authInstance;
