import React from 'react'
import { useSelector } from 'react-redux';
import { composeRadioBtnStyles } from '../../helpers/CreateStyleFromConfig';
import { useTranslation } from '../../hooks/useTranslation';
import { MyText } from '../MyText/MyText'

import styles from "./SuccessPopup.module.scss";
import classNames from 'classnames';

type SuccessPopupProps = {
  isModal?: boolean;
}

export const SuccessPopup: React.FunctionComponent<SuccessPopupProps> = ({isModal}) => {
  const { general } = useSelector((state: GlobalState) => state.configuration.walletConfiguration);

  const cardVerifiedText = useTranslation("CARD_HAS_BEEN_VERIFY_AND_STORED");

  const checkboxStyle = composeRadioBtnStyles(general);

  return (
    <div className={classNames(styles.successPopup, {
      [styles.modalMode]: isModal,
      [styles.nonModalMode]: !isModal,
    })}>
      <div
        className={styles.checkbox}
        style={{ ...checkboxStyle, background: "none" }}
      >
        <svg width="100%" height="100%" viewBox={`0 0 ${16} ${18}`}>
          <path
            d="M6.70597638,14.231662 L2.19757886,9.72390157 C1.93414038,9.46110018 1.93414038,9.03679541 2.19757886,8.77431257 C2.45846896,8.51151119 2.88532211,8.51151119 3.1474864,8.77431257 L6.63589601,12.257944 L13.5713041,4.23374179 C13.8149927,3.95087194 14.2383418,3.92124705 14.5212117,4.16397997 C14.8012146,4.40607579 14.8324322,4.8319733 14.591292,5.11293187 L6.70597638,14.231662 Z"
            fill="green"
          />
        </svg>
      </div>
      <MyText type="two" className={styles.cardVerifiedText}>
        {cardVerifiedText}
      </MyText>
    </div>
  )
}
