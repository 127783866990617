import React from 'react'
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "../hooks/useTranslation";
import { AlternativeWalletWrapper } from "../containers/AlternativeWallets/AlternativeWalletWrapper";
import { Configuration } from "../configs/Configuration";
import { ApiClient, ApiUrls } from "./ApiClient";
import { store } from "../store";
import { attachMasterpass } from "../helpers/FetchScript";
import {
  fetchPayments,
  setCurrentPayment,
  setCurrentPaymentOption,
  setErrorMessage,
  setLoader,
  setPaymentMethodSelected
} from "../store/payment/PaymentActions";

import * as storage from "../helpers/Storage";
import { Tracking } from './AnalyticsService/GoogleAnalyticsService';
import { MyTouchable } from '../components/MyTouchable/MyTouchable';
import styles from "../containers/AlternativeWallets/AlternativeWalletWrapper.module.scss";
import { alternativeMethodsName } from '../containers/AlternativeWallets/AlternativeMethodsName';
import { parseAlternativeError, parseWalletMSError } from '../helpers/ParseError';
import { MerchantsList } from '../helpers/helpers';
import { MyCheckWalletService } from './MyCheckWalletService/WalletManager';

const CHECKOUT_ID = "mycheck.checkout.id";
const CART_ID = "mycheck.cart.id";
const MASTERPASS_TYPE = "masterpass";

export const getMasterpassToken = async () => {
  const url = new URL(window.location.href);
  const searchParams = url.searchParams;
  const mpstatus = searchParams.get("mpstatus");

  const currentPayment: PaymentMethod = store.getState().payment.cards.find(e => e.isDefault)!;

  if (mpstatus === "success") {
    store.dispatch(setLoader(true));
    const cartId = storage.local.getItem(CART_ID);
    const checkoutId = storage.local.getItem(CHECKOUT_ID);

    const token = store.getState().account.accessToken;
    const checkout_resource_url = searchParams.get("checkout_resource_url");
    const oauth_verifier = searchParams.get("oauth_verifier");
    const oauth_token = searchParams.get("oauth_token");

    try {
      await ApiClient(ApiUrls.addCreditCard, {
        domain: Configuration.api_domain,
        method: "POST",
        body: {
          accessToken: token,
          isSingleUse: 0,
          extraParams: {
            masterPassPayload: {
              mpstatus,
              checkout_resource_url,
              oauthVerifier: oauth_verifier,
              oauthToken: oauth_token,
              cartId,
              checkoutId,
            },
          },
          source: "SRC"
        }
      });
      store.dispatch(fetchPayments());
      store.dispatch(setCurrentPayment(currentPayment));
      store.dispatch(setCurrentPaymentOption('credit-card'));
      MyCheckWalletService.events.cardTokenReceived(currentPayment.ccToken);
      await MyCheckWalletService.checkIsCheckoutReady({
        cc_token: currentPayment.ccToken,
      });
      // MyCheckWalletService.events.addUserInformation(currentPayment);

      storage.local.removeItem(CART_ID);
      storage.local.removeItem(CHECKOUT_ID);

      store.dispatch(setLoader(false));
    } catch (error) {
      // @ts-ignore
      if (error.errorId) {
        // @ts-ignore
        store.dispatch(setErrorMessage(parseWalletMSError(error.errorId)));
      }
      MyCheckWalletService.cardAddFailureEvent(error, MerchantsList.MASTERPASS);
      store.dispatch(setLoader(false));
    }
  }
};

export const MasterpassService:React.FunctionComponent = () => {
  const dispatch = useDispatch();
  const errorLabelFilterCard = useTranslation("ERROR_FILTER_CARD");
  const token = useSelector((state: GlobalState) => state.account.accessToken);
  const acceptedCreditCards = useSelector((state: GlobalState) => state.configuration.customWalletConfiguration.acceptedCreditcards);
  const supportedCardTypes = useSelector((state: GlobalState) => state.configuration.walletConfiguration.general.supported_card_types);

  const initMasterpassService = async () => {
    const { configuration } = await ApiClient(ApiUrls.masterpassConfiguration(token!));
    const { checkoutId, currency, cartId } = configuration;
    let allowedCardTypes = acceptedCreditCards || supportedCardTypes;
    allowedCardTypes = allowedCardTypes.map((cardType: string) => cardType === 'mastercard' ? 'master' : cardType)
    await attachMasterpass(checkoutId);

    storage.local.setItem(CART_ID, cartId);
    storage.local.setItem(CHECKOUT_ID, checkoutId);
    window.masterpass.checkout({
      "checkoutId": checkoutId,
      "currency": currency,
      "allowedCardTypes": allowedCardTypes,
      "amount": "0.00",
      "cartId": cartId,
    });
    await MyCheckWalletService.checkIsCheckoutReady({});
  };

  const onPress = (element: string) => async () => {
    if (element === "masterpass") {
      Tracking.trackEvent(Tracking.EventAction.AlternativePMClick, MASTERPASS_TYPE);
      MyCheckWalletService.events.paymentMethodSelected('masterpass', 'alternative');
      dispatch(setCurrentPaymentOption('credit-card'));
      dispatch(setPaymentMethodSelected('masterpass', 'alternative', true));

      try {
        await initMasterpassService();
      } catch (error) {
      dispatch(setErrorMessage(parseAlternativeError(error) || errorLabelFilterCard));
      dispatch(setLoader(false));
      }
    }
  };

  return (
    <div className={styles.masterpassWrapper} onClick={onPress(MASTERPASS_TYPE)} id="masterpass">
      <div className={styles.masterpassOverlay}></div>
      <div className={styles.masterpassButton}>
        <img
          src={`${Configuration.assets}/static/media/${alternativeMethodsName[MASTERPASS_TYPE]}`}
          alt={MASTERPASS_TYPE}
          className={styles.image}
        />
      </div>
    </div>
  )
}

