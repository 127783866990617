import * as React from "react";
import { useDispatch, useSelector } from "react-redux";

import { ApiClient } from "./ApiClient";
import { mobileAndTabletCheck } from "./IsMobile";
import { Tracking } from "./AnalyticsService/GoogleAnalyticsService";
import { Configuration } from "../configs/Configuration";

import { store } from "../store";
import { onWeChatAliPay, setCurrentPaymentOption, setPaymentMethodSelected } from "../store/payment/PaymentActions";

import { MyTouchable } from "../components/MyTouchable/MyTouchable";
import { MyText } from "../components/MyText/MyText";
import { MyRadio } from "../components/MyRadio/MyRadio";
import { Container } from "../components/Container/Container";
import { InstantPaymentsName } from "../containers/InstantPayments/InstantPaymentsName";

import styles from "../containers/InstantPayments/InstantPaymentsDisplay.module.scss";
import { getMerchantConfig, MerchantsList } from "../helpers/helpers";
import { parseTimestamp6 } from "../helpers/parseTimestamp6";
import { MyCheckWalletService } from "./MyCheckWalletService/WalletManager";
import { firebaseService } from "./FirebaseService";

export type WeChatAliPay = "AliPay" | "WeChat";
type WeChatAliPayService = {
  type: WeChatAliPay
}

export const attachWeChatAliPay = async (type: WeChatAliPay) => {
  const return_url = MyCheckWalletService.getPaymentInfo().return_url;
  const callback_url = MyCheckWalletService.getPaymentInfo().callback_url;
  const metadata = MyCheckWalletService.getPaymentInfo().metadata;
  const merchantConfigurations = store.getState().configuration.walletConfiguration.merchantConfigurations

  let id
  if(type === 'AliPay') {
    id = getMerchantConfig(MerchantsList.ALIPAY, merchantConfigurations)?.id;
  } else {
    id = getMerchantConfig(MerchantsList.WECHAT, merchantConfigurations)?.id;
  }

  return await ApiClient("/wallet/api/v1/billing/alternative/pay", {
    domain: Configuration.api_domain,
    method: "POST",
    body: {
      type: mobileAndTabletCheck() ? "url" : "qr",
      merchant_id: id || '',
      ...callback_url ? { callback_url } : {},
      ...metadata ? { metadata } : {},
      ...return_url ? { return_url } : {},
      accessToken: store.getState().account.accessToken,
      amount: MyCheckWalletService.getPaymentInfo().amount,
      currency: MyCheckWalletService.getPaymentInfo().currency,
    }
  });
};


export const onWeChatComplete = (transactionId: string) => new Promise(async resolve => {
  const defaultDatabase = firebaseService.getDatabase();
  const refTransaction = defaultDatabase.ref(`Transactions/${transactionId}`);
  const refLogs = defaultDatabase.ref(`Transactions/${transactionId}/logs`);

  refTransaction.on("value", function (snapshot: any) {
    const value = snapshot.val();
    if (value) {
      const object = Object.values(value)[0] as FirebaseDatabaseResponse;

      if (object.status === "SUCCESS") {
        MyCheckWalletService.events.paymentCompleted(object);
        refLogs.push(`fired event of paymentCompleted at: ${parseTimestamp6()}`)
        resolve(null);
      }

      if (object.status === "FAILURE") {
        MyCheckWalletService.events.paymentCompleted(object);
        refLogs.push(`fired event of paymentCompleted at: ${parseTimestamp6()}`)
        resolve(null);
      }
    }
  });
});

export const WeChatAliPayService: React.FunctionComponent<WeChatAliPayService> = ({ type }) => {
  const dispatch = useDispatch();
  const currentPaymentOption = useSelector((state: GlobalState) => state.payment.paymentOption);
  const name = type.toLowerCase() as "wechat" | "alipay";

  const onPress = () => {
    Tracking.trackEvent(Tracking.EventAction.InstantPM, type);
    dispatch(setCurrentPaymentOption(type));
    MyCheckWalletService.events.paymentMethodSelected(name, 'instant')
    dispatch(setPaymentMethodSelected(name, 'instant', true));

    MyCheckWalletService.checkIsCheckoutReady({});
  };

  return (
    <MyTouchable onClick={onPress} ariaLabel={`${name} button`} className={styles.root} id={`${name}`}>
      <Container className={styles.row} smallPadding>
        <MyRadio value={currentPaymentOption} option={{ label: "", value: type }} tabIndex={-1} id={type} />
        <img src={`${Configuration.assets}/static/media/${InstantPaymentsName[name]}`} alt={type}
             className={styles.image} />
        <MyText type="three" className={styles.label}>{name === "wechat" ? "WeChat Pay" : "AliPay"}</MyText>
      </Container>
    </MyTouchable>
  );
};

