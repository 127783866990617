import { useSelector } from "react-redux";
import styles from "./useFadeOutWhen.module.scss";

type Props = 'card-add'

export const useFadeOutWhen = (when: Props) => {
  const currentOption = useSelector((state: GlobalState) => state.payment.paymentOption)
  const managePaymentsShow = useSelector((state: GlobalState) => state.payment.managePaymentsShow);
  const cards = useSelector((state: GlobalState) => state.payment.cards)

  const classHandler = () => {
    switch (when) {
      case "card-add":
        return (!!cards.length && managePaymentsShow && currentOption === 'credit-card')
          ? styles.opacity
          : ''

      default:
        return '';
    }
  }


  return classHandler()
}
